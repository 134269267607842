import { useEffect } from "react";

import { useUserOrganizationLogo } from "src/api/queries";
import { logger } from "src/utils/logger";
import { useWorkspaceAndOrg } from "src/utils/useCurrentWorkspace";

const defaultFavicons = [
  { rel: "icon", href: "/favicon.ico" },
  { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon-32x32.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon-16x16.png",
  },
];

const iconSelectors = [
  "link[rel='icon']",
  "link[rel='shortcut icon']",
  "link[rel='apple-touch-icon']",
  "link[rel='icon'][sizes='16x16']",
  "link[rel='icon'][sizes='32x32']",
];

const setDefaultFavicons = () => {
  defaultFavicons.forEach(({ rel, href, sizes, type }) => {
    const link = document.createElement("link");
    link.rel = rel;
    link.href = href;
    if (sizes) link.setAttribute("sizes", sizes);
    if (type) link.setAttribute("type", type);
    document.head.appendChild(link);
  });
};

const setFavicon = (iconUrl: string) => {
  // Add whitelabeled favicon links
  iconSelectors.forEach((selector) => {
    const link = document.createElement("link");
    link.rel = selector.match(/rel='([^']*)'/)![1];
    if (selector.includes("sizes")) {
      link.setAttribute("sizes", selector.match(/sizes='([^']*)'/)![1]);
    }
    link.href = iconUrl;
    document.head.appendChild(link);
  });
};

export const OrgFavicon: React.FC = () => {
  const { org, isLoading } = useWorkspaceAndOrg();
  const { data: iconData, isInitialLoading } = useUserOrganizationLogo(
    org?.id ?? null,
  );
  useEffect(() => {
    try {
      // Remove all existing favicon-related links
      iconSelectors.forEach((selector) => {
        const links = document.querySelectorAll(selector);
        links.forEach((existingLink) => {
          existingLink.remove();
        });
      });
      const iconUrl: string | undefined = undefined;

      if (org?.whitelabel && iconData) {
        // Create URL from the blob data for whitelabeled org
        // @ts-expect-error
        const iconUrl = URL.createObjectURL(iconData);
        setFavicon(iconUrl);
      } else if (!isLoading && !isInitialLoading) {
        setDefaultFavicons();
      }
      return () => {
        try {
          if (iconUrl) {
            URL.revokeObjectURL(iconUrl);
          }
          setDefaultFavicons();
        } catch (error) {
          logger.error("Error setting default favicon", { error });
        }
      };
    } catch (error) {
      logger.error("Error setting dynamic favicon", { error });
    }
  }, [org?.whitelabel, iconData, isLoading, isInitialLoading]);

  return null;
};
