import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { workspacesApi } from "src/api/endpoints";

// Import your API methods if they're not globally available

const fetchRemoteIcon = async (mediaKey: string | null, wsId: string) => {
  if (!mediaKey) return null;
  const res =
    await workspacesApi.getMediaUrlApiV1WorkspacesWorkspaceIdMediaFilePathGet(
      mediaKey.replace("media/", ""),
      wsId,
    );

  const { data: blob } = await axios.get(res.data.url, {
    responseType: "blob",
  });
  return URL.createObjectURL(blob);
};

export const useRemoteIcon = (mediaKey: string | null, wsId: string) => {
  return useQuery({
    // unique key for this query
    queryKey: ["remoteIcon", mediaKey, wsId],
    queryFn: () => fetchRemoteIcon(mediaKey, wsId),
  });
};
