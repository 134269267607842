import ExperianIcon from "src/assets/ExperianIcon.svg?react";
import {
  getDefaultExperianColombiaChamberOfCommerce,
  getDefaultExperianColombiaCreditHistoryForLegalEntities,
} from "src/connections/connectionConfigs/experianColombia/experianColombiaResources";
import { ExperianColombiaTemplate } from "src/connections/connectionConfigs/experianColombia/experianColombiaTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const EXPERIAN_COLOMBIA_CONFIG: ConnectionConfig<"experian_colombia"> = {
  icon: ExperianIcon,
  description:
    "Provider of consumer credit, and business information services in Colombia",
  template: ExperianColombiaTemplate,
  name: "DataCrédito (Colombia)",
  resources: {
    experian_colombia_credit_history_for_legal_entities: {
      externalLink:
        "https://www.notion.so/taktile/Credit-History-for-Legal-Entities-Historia-de-Cr-dito-PJ-eb9578b2783442c49337c1510416ab33?pvs=4",
      integrationResource:
        getDefaultExperianColombiaCreditHistoryForLegalEntities,
    },
    experian_colombia_chamber_of_commerce: {
      externalLink:
        "https://www.notion.so/taktile/Chamber-of-Commerce-C-mara-de-Comercio-5414a99e4f0042e39c1f453accec5f7a?pvs=4",
      integrationResource: getDefaultExperianColombiaChamberOfCommerce,
    },
  },
};
