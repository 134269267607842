import { search } from "@codemirror/search";
import {
  Decoration,
  DecorationSet,
  EditorView,
  MatchDecorator,
  ViewPlugin,
  ViewUpdate,
} from "@codemirror/view";
import ReactCodeMirror, {
  Extension,
  ReactCodeMirrorProps,
  ReactCodeMirrorRef,
} from "@uiw/react-codemirror";
import React, { useCallback } from "react";
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { AiNodeForm } from "src/aiNode/types";
import { ErrorBaseInfo } from "src/api/types";
import { showUpdatedSuggestionsPlugin } from "src/base-components/CodeInput/Autocomplete/useAutocompleteExtension";
import { useFocusHandlers } from "src/base-components/CodeInput/CodeInputFocusHandlers";
import {
  CodeEditorTheme,
  CursorInvisibleTheme,
} from "src/base-components/CodeInput/Themes";
import "src/base-components/CodeInput/sqlSyntaxColors.css";
import { useDispatchCreateEvent } from "src/base-components/CodeInput/useOnCreateEvent";
import { useSetDataLoc } from "src/base-components/CodeInput/useSetDataLoc";
import { FormItem } from "src/base-components/FormItem";
import { AiNodeDataT } from "src/constants/NodeDataTypes";
import {
  NodeEditorBaseProps,
  NodeEditorCompletionContext,
} from "src/nodeEditor/NodeEditor";

const VariableDecorator = new MatchDecorator({
  regexp: /\$(data|params)\.[^\s]+/g,
  decoration: Decoration.mark({ attributes: { style: "color: #5647dd" } }),
});

class VariableHighlightingClass {
  decorations: DecorationSet;
  constructor(view: EditorView) {
    this.decorations = VariableDecorator.createDeco(view);
  }
  update(update: ViewUpdate) {
    this.decorations = VariableDecorator.updateDeco(update, this.decorations);
  }
}

const VariableHighlightingPlugin = ViewPlugin.fromClass(
  VariableHighlightingClass,
  {
    decorations: (v) => v.decorations,
  },
);

type PromptEditorTextboxProps = Pick<
  ReactCodeMirrorProps,
  "value" | "onChange" | "placeholder" | "autoFocus" | "onBlur" | "readOnly"
> & {
  disabled?: boolean;
  dataLoc?: string;
  completionExtension?: Extension;
  highlightOnFocus?: boolean;
};

const PromptEditorTextbox = forwardRef<
  ReactCodeMirrorRef,
  PromptEditorTextboxProps
>(
  (
    {
      disabled = false,
      dataLoc,
      completionExtension,
      onBlur,
      highlightOnFocus = false,
      ...codeMirroProps
    },
    editorRef,
  ) => {
    const editor = useRef<ReactCodeMirrorRef>(null);
    useImperativeHandle(editorRef, () => editor.current as ReactCodeMirrorRef);
    const { onCreateEditor } = useSetDataLoc(dataLoc, editor.current);
    const { dispatchCreateEvent } = useDispatchCreateEvent();

    const theme = useMemo(
      () =>
        EditorView.theme({
          ...CodeEditorTheme,
          ...(disabled && CursorInvisibleTheme),
          ".cm-content": { "font-family": "inter" },
        }),
      [disabled],
    );

    const extensions = useMemo(
      () =>
        [
          search(),
          completionExtension,
          showUpdatedSuggestionsPlugin,
          EditorView.lineWrapping,
          VariableHighlightingPlugin,
        ].filter((e) => e) as (
          | ViewPlugin<VariableHighlightingClass>
          | Extension
        )[],
      [completionExtension],
    );

    const [focused, setFocused] = useState(false);

    const _handleFocus = useCallback(() => {
      highlightOnFocus && setFocused(true);
    }, [highlightOnFocus]);

    const _handleBlur = useCallback(
      (e: React.FocusEvent<HTMLDivElement>) => {
        onBlur?.(e);
        highlightOnFocus && setFocused(false);
      },
      [onBlur, highlightOnFocus],
    );

    const { handleFocus, handleBlur } = useFocusHandlers({
      editor,
      disabled,
      toggleWrappingOnFocus: false,
      onBlur: _handleBlur,
      onFocus: _handleFocus,
    });

    const basicSetup = {
      lineNumbers: false,
      highlightActiveLine: false,
    };

    return (
      <ReactCodeMirror
        ref={editor}
        basicSetup={basicSetup}
        className={twJoin(
          "decideScrollbar h-full w-full overflow-auto rounded-lg border p-1 [&_.cm-scroller]:font-code-13",
          disabled && "cursor-not-allowed bg-gray-100",
          !disabled && "bg-gray-50",
          focused && "border-indigo-500",
        )}
        extensions={extensions}
        readOnly={disabled}
        theme={theme}
        onBlur={handleBlur}
        onCreateEditor={(view) => {
          onCreateEditor(view);
          dispatchCreateEvent();
        }}
        onFocus={handleFocus}
        {...codeMirroProps}
      />
    );
  },
);

type PromptEditorTextboxContextPropsT = {
  value: string;
  placeholder?: string;
  onChange: (value: string | undefined) => void;
} & Omit<NodeEditorBaseProps<AiNodeDataT>, "onUpdate" | "displayedError">;

const PromptEditorTextboxContext: React.FC<
  PromptEditorTextboxContextPropsT
> = ({ value, onChange, immutable, isReactive, placeholder }) => {
  const onValueChange = useCallback(
    (newValue: string | undefined) => {
      if (!isReactive && value !== newValue) {
        onChange(newValue ?? "");
      }
    },
    [isReactive, value, onChange],
  );

  return (
    <NodeEditorCompletionContext.Consumer>
      {({ defaultCompletion }) => {
        return (
          <PromptEditorTextbox
            completionExtension={defaultCompletion}
            dataLoc="prompt-editor-textbox"
            disabled={immutable}
            placeholder={placeholder}
            value={value}
            onChange={onValueChange}
          />
        );
      }}
    </NodeEditorCompletionContext.Consumer>
  );
};

type PromptEditorPropsT = {
  immutable: boolean;
  isReactive: boolean;
  displayedError?: ErrorBaseInfo;
};

export const PromptEditor: React.FC<PromptEditorPropsT> = ({
  immutable,
  isReactive,
}) => {
  const { setValue, watch } = useFormContext<AiNodeForm>();
  const systemPrompt = watch("prompts.system_prompt.expression");
  const userPrompt = watch("prompts.messages.0.content.0.text.expression");

  return (
    <>
      <div className="mt-3 h-110">
        <FormItem
          description="Fine-tune how the AI approaches the task by providing context, constraints, or specific instructions. This augments the base system prompt."
          gap="sm"
          label="System prompt"
        >
          <PromptEditorTextboxContext
            immutable={immutable}
            isReactive={isReactive}
            value={systemPrompt}
            onChange={(value) => {
              setValue("prompts.system_prompt.expression", value || "");
            }}
          />
        </FormItem>
        <FormItem
          className="mt-3 h-60"
          description="Enter your prompt, using dollar sign notation to incorporate dynamic data from your Decision Flow"
          gap="sm"
          label="User prompt"
          isRequired
        >
          <PromptEditorTextboxContext
            immutable={immutable}
            isReactive={isReactive}
            value={userPrompt}
            onChange={(value) => {
              setValue(
                "prompts.messages.0.content.0.text.expression",
                value || "",
              );
            }}
          />
        </FormItem>
      </div>
    </>
  );
};
