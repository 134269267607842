import { faInfoCircle, faSync } from "@fortawesome/pro-regular-svg-icons";
import { isEmpty, keyBy } from "lodash";
import React, { useMemo } from "react";

import { FlowVersionT } from "src/api/flowTypes";
import { Dataset, DesiredType } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { TitleEditor } from "src/base-components/TitleEditor";
import { DatasetControlButtons } from "src/datasets/DatasetControlButtons";
import {
  DatasetEditTable,
  type DatasetEditTableRef,
} from "src/datasets/DatasetTable/DatasetEditTable";
import { MissingColumnsCallout } from "src/datasets/DatasetTable/MissingColumnsCallout";
import { MissingMockDataColumnsCallout } from "src/datasets/DatasetTable/MissingMockDataColumnsCallout";
import { useAvailableIntegrationNodes } from "src/datasets/DatasetTable/hooks";
import {
  DatasetContext,
  VersionSchemas,
} from "src/datasets/DatasetTable/types";
import {
  SUB_COLUMN_SEPARATOR,
  getOutcomeDatasetColumns,
  isLoopIntegrationNode,
  isParentIntegrationNode,
} from "src/datasets/DatasetTable/utils";
import {
  PutColumnPayload,
  usePatchDataset,
  usePutColumns,
} from "src/datasets/api/queries";
import { DEFAULT_DATASET_NAME } from "src/datasets/constants";
import {
  DatasetIssue,
  findCompatibilityIssues,
  isDatasetEmpty,
  isIntegrationNodeWithLiveConnection,
  separateFilenameExtension,
} from "src/datasets/utils";
import { Callout } from "src/design-system/Callout";
import { Modal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { useOutcomeTypes } from "src/outcomes/queries";
import { useFlowContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";
import { formatNumber } from "src/utils/numbers";

type Props = {
  dataset?: Dataset;
  open: boolean;
  onClose: () => void;
  schemas: VersionSchemas;
  version?: FlowVersionT;
  context?: DatasetContext;
  onOpenConnectVersionModal?: () => void;
  onNameUpdate?: ({ name }: { name: string }) => Promise<void>;
};

export const EditModal: React.FC<Props> = ({
  open,
  onClose,
  dataset,
  schemas,
  version,
  context = "authoring",
  onOpenConnectVersionModal,
  onNameUpdate,
}) => {
  const { workspace, flow } = useFlowContext();
  const isAuthoringDatasetContext = context === "authoring";
  const putColumns = usePutColumns(
    dataset ? dataset.id : "",
    flow.id,
    workspace?.base_url,
  );
  const editTableRef = React.useRef<DatasetEditTableRef>(null);
  const patchDataset = usePatchDataset(flow.id, workspace?.base_url);

  const datasetNameAndExtension = dataset
    ? separateFilenameExtension(dataset.name)
    : undefined;

  const updateDatasetName = async (name: string) => {
    if (!dataset) {
      logger.log("No dataset to update");
      return;
    }

    const completeName = isEmpty(name)
      ? DEFAULT_DATASET_NAME
      : datasetNameAndExtension?.extension
        ? `${name}.${datasetNameAndExtension?.extension}`
        : name;

    try {
      await patchDataset.mutateAsync({
        id: dataset.id,
        patch: { name: completeName },
      });
      await onNameUpdate?.({ name: completeName });
    } catch (e) {
      toastActions.failure({
        title: "Failed to update dataset name",
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  const issues = useMemo(
    () => (dataset ? findCompatibilityIssues(dataset, schemas.input) : {}),
    [dataset, schemas.input],
  );

  const integrationNodes = useAvailableIntegrationNodes(version);
  const integratinNodesByName = keyBy(integrationNodes, "name");

  const missingMockDataColumns = useMemo(() => {
    if (!dataset) return [];
    const columnsByName = keyBy(dataset.mock_columns, "name");

    const allNodes = integrationNodes.flatMap((node) => [
      node,
      ...(columnsByName[node.name]?.use_subflow_mocks
        ? (node.mockableChildNodes?.map((childNode) => ({
            ...childNode,
            name: [node.name, childNode.name].join(SUB_COLUMN_SEPARATOR),
          })) ?? [])
        : []),
    ]);

    const nodeNames = allNodes
      .filter(
        (node) =>
          !isParentIntegrationNode(node) &&
          !isIntegrationNodeWithLiveConnection(node),
      )
      .map((node) => node.name);
    const datasetColumns = dataset.mock_columns.map((column) => column.name);
    return nodeNames.filter((nodeName) => !datasetColumns.includes(nodeName));
  }, [dataset, integrationNodes]);

  const [missingOutcomeColumns, putMissingOutcomeColumnsHandler] =
    useMissingOutcomeColumns(dataset);

  const missingColumns = Object.entries(issues).filter(
    (
      datasetIssue,
    ): datasetIssue is [
      string,
      Extract<DatasetIssue, { issueType: "missing-column" }>,
    ] => datasetIssue?.[1]?.issueType === "missing-column",
  );

  const addMissingColumnsHandler = async () => {
    if (missingColumns.length === 0) return;

    const columns: PutColumnPayload[] = missingColumns.map(([name, issue]) => ({
      name,
      group: "input_columns",
      desiredType: issue.schemaType,
      hasSubflowMocks: false,
    }));

    try {
      await putColumns.mutateAsync(columns);
    } catch (e) {
      toastActions.failure({
        title: `Failed to add columns ${columns
          .map((column) => `"${column.name}"`)
          .join(", ")}`,
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  const handleAddMissingMockDataColumns = async () => {
    if (missingMockDataColumns.length < 1) return;

    const columns: PutColumnPayload[] = missingMockDataColumns.map((name) => {
      const [parentName] = name.split(SUB_COLUMN_SEPARATOR);
      const integrationNode = integratinNodesByName[parentName];
      return {
        name,
        group: "mock_columns",
        desiredType: isLoopIntegrationNode(integrationNode) ? "any" : "object",
        hasSubflowMocks: false,
      };
    });

    try {
      await putColumns.mutateAsync(columns);
    } catch (e) {
      toastActions.failure({
        title: `Failed to add columns ${columns
          .map((column) => `"${column.name}"`)
          .join(", ")}`,
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  const hasName = datasetNameAndExtension?.name !== DEFAULT_DATASET_NAME;

  const title = (
    <div className="flex w-full items-center gap-x-2">
      <h2 className="line-clamp-1 max-w-[25%] text-gray-800 font-inter-semibold-14px">
        <TitleEditor
          autofocus={!hasName}
          dataLoc="dataset-name-editor"
          placeholder={DEFAULT_DATASET_NAME}
          value={hasName ? datasetNameAndExtension?.name : ""}
          onSubmit={updateDatasetName}
        />
      </h2>
      {typeof dataset?.row_count === "number" ? (
        <Pill variant="indigo-light">
          <Pill.Text>
            {formatNumber(dataset.row_count)} test{" "}
            {dataset.row_count === 1 ? "case" : "cases"}
          </Pill.Text>
        </Pill>
      ) : null}
      <div className="ml-auto flex gap-x-2">
        {isAuthoringDatasetContext && (
          <>
            <Pill variant="gray">
              <Pill.Icon icon={faSync} />
              <Pill.Text>
                Changes to this dataset are synced across all versions
              </Pill.Text>
            </Pill>
            <a
              href="https://docs.taktile.com/testing-and-mocking/datasets-and-testing-1"
              rel="noreferrer"
              target="_blank"
            >
              <Icon color="text-gray-500" icon={faInfoCircle} size="xs" />
            </a>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Modal autoFocus={false} open={open} size="xl" onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content noScroll>
        <div className="flex h-[75vh] flex-col">
          {missingColumns.length > 0 && (
            <MissingColumnsCallout
              columns={missingColumns.map((issue) => issue[0])}
              context={context}
              onAddMissingColumns={addMissingColumnsHandler}
            />
          )}
          {isAuthoringDatasetContext && missingMockDataColumns.length > 0 && (
            <MissingMockDataColumnsCallout
              columns={missingMockDataColumns}
              onAddMissingColumns={handleAddMissingMockDataColumns}
            />
          )}
          {!isAuthoringDatasetContext &&
            !version &&
            onOpenConnectVersionModal && (
              <MissingVersionCallout
                onOpenConnectVersionModal={onOpenConnectVersionModal}
              />
            )}
          {isAuthoringDatasetContext && missingOutcomeColumns.length > 0 && (
            <MissingOutcomeColumnsCallout
              zeroOutcomeColumns={dataset?.outcome_columns.length === 0}
              onAddMissingOutcomeColumns={putMissingOutcomeColumnsHandler}
            />
          )}
          <div className="relative min-h-0 flex-1">
            {dataset && (
              <DatasetEditTable
                ref={editTableRef}
                context={context}
                dataset={dataset}
                issues={issues}
                schemas={schemas}
                version={version}
              />
            )}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer primaryButton="Done" secondaryButton={false}>
        {dataset && !isDatasetEmpty(dataset) && (
          <DatasetControlButtons
            context={context}
            dataset={dataset}
            editTableRef={editTableRef}
            schemas={schemas}
            version={version}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

const MissingVersionCallout: React.FC<{
  onOpenConnectVersionModal: () => void;
}> = ({ onOpenConnectVersionModal }) => {
  return (
    <Callout
      action={{
        text: "Add Decision Flow Version",
        onClick: onOpenConnectVersionModal,
      }}
      type="warning"
      fullWidth
    >
      Connect decision flow version with the job to check your dataset columns
      against the decision flow input schema.
    </Callout>
  );
};

const MissingOutcomeColumnsCallout: React.FC<{
  zeroOutcomeColumns: boolean;
  onAddMissingOutcomeColumns: () => void;
}> = ({ zeroOutcomeColumns, onAddMissingOutcomeColumns }) => {
  return (
    <Callout
      action={{
        text: "Add Outcome Columns",
        onClick: onAddMissingOutcomeColumns,
      }}
      icon={faInfoCircle}
      type="neutral"
      fullWidth
    >
      {zeroOutcomeColumns
        ? "Add outcome columns to your dataset if you want to have outcome examples for charting and analysis."
        : "Not all flow Outcome types are present as columns in your dataset. Include additional Outcome types if you want to use them for analytics."}
    </Callout>
  );
};

const useMissingOutcomeColumns = (dataset?: Dataset) => {
  const { workspace, flow } = useFlowContext();
  const { data: outcomeTypes } = useOutcomeTypes({
    flowId: flow.id,
  });
  const putColumns = usePutColumns(
    dataset ? dataset.id : "",
    flow.id,
    workspace.base_url,
  );

  const outcomeColumnTypes = useMemo(
    () => getOutcomeDatasetColumns(outcomeTypes ?? []),
    [outcomeTypes],
  );

  const missingColumns = useMemo(() => {
    if (!dataset) return [];

    const datasetColumns = dataset.outcome_columns.map((column) => column.name);

    return outcomeColumnTypes.filter(
      (column) => !datasetColumns.includes(column.columnName),
    );
  }, [outcomeColumnTypes, dataset]);

  const putMissingOutcomeColumnsHandler = async () => {
    if (isEmpty(missingColumns)) return;

    const columns: PutColumnPayload[] = missingColumns.map((column) => ({
      name: column.columnName,
      group: "outcome_columns",
      desiredType: column.property.type[0] as DesiredType,
      hasSubflowMocks: false,
    }));

    try {
      await putColumns.mutateAsync(columns);
    } catch (e) {
      toastActions.failure({
        title: `Failed to add outcome columns ${columns
          .map((column) => `"${column.name}"`)
          .join(", ")}`,
        description: TAKTILE_TEAM_NOTIFIED,
      });
      logger.error(e);
    }
  };

  return [missingColumns, putMissingOutcomeColumnsHandler] as const;
};
