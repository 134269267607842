import { faEye } from "@fortawesome/pro-light-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { DecisionEnvironment } from "src/api/types";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { Icon } from "src/base-components/Icon";
import { Cell, TableComp } from "src/base-components/Table";
import { EntitySchemaEditorSidebar } from "src/entities/EntitySchemaEditorSidebar";
import {
  TypeIcon,
  renderValue,
} from "src/entities/entityView/EntityDetailsSidePane";
import {
  EntityResource,
  EntitySchemaResource,
  useEntities,
} from "src/entities/queries";
import { WhitePane } from "src/jobs/common/WhitePane";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getUrlToEntityDetailPage } from "src/router/urls";

type EntitiesTableProps = {
  baseUrl: string;
  entitySchema: EntitySchemaResource;
  isEditing: boolean;
  onSidebarClose: () => void;
};

export const EntitiesTable: React.FC<EntitiesTableProps> = ({
  baseUrl,
  entitySchema,
  isEditing,
  onSidebarClose,
}) => {
  const { data, isLoading } = useEntities({
    schema: entitySchema._id,
    env: DecisionEnvironment.SANDBOX,
    baseUrl,
  });
  const { orgId, workspace } = useWorkspaceContext();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<EntityResource>();

    const entityColumns = Object.entries(entitySchema.properties).map(
      ([key, value]) => {
        return columnHelper.accessor(`properties.${key}`, {
          header: () => (
            <div className="flex items-center gap-1">
              <TypeIcon cardinality={value._cardinality} type={value._type} />
              <p className="whitespace-nowrap pr-2">{value._display_name}</p>
            </div>
          ),
          cell: (info) => (
            <Cell info={info}>
              {renderValue(
                value._type,
                info.row.original.properties[key],
                value._rel_schema,
                true,
              )}
            </Cell>
          ),
        });
      },
    );

    const detailsColumn = columnHelper.display({
      id: "fields.options",
      header: () => null,
      size: 54,
      cell: (info) => (
        <Cell className="items-center justify-end" info={info}>
          <Icon
            color="text-gray-500 opacity-0 group-hover:opacity-100"
            icon={faEye}
            size="xs"
          />
        </Cell>
      ),
    });

    return [...entityColumns, detailsColumn];
  }, [entitySchema]);

  return (
    <FloatingWindowsProvider>
      <div className="flex">
        <div className="m-5 flex-grow">
          <WhitePane>
            <TableComp
              columns={columns}
              data={data?.entities ?? []}
              frameClassName="w-full p-4"
              isLoading={isLoading}
              rowClassName="hover:bg-gray-50 cursor-pointer"
              rowPropsGetter={(row) => ({
                onClick: () =>
                  navigate(
                    getUrlToEntityDetailPage(
                      orgId,
                      workspace.id,
                      row.original._schema,
                      row.original._id,
                    ),
                  ),
              })}
              variant="compact"
            />
          </WhitePane>
        </div>
        {isEditing && (
          <>
            <div className="w-150" />
            <div className="fixed right-0 z-10 h-[calc(100vh-100px)]">
              <EntitySchemaEditorSidebar
                schema={entitySchema}
                onClose={onSidebarClose}
              />
            </div>
          </>
        )}
      </div>
    </FloatingWindowsProvider>
  );
};
