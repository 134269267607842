import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import React, { useState } from "react";

import { Pill } from "src/base-components/Pill";
import {
  FloatingWindow,
  FloatingWindowProps,
} from "src/datasets/DatasetTable/FloatingWindow";
import { EntityDetailsSidePane } from "src/entities/entityView/EntityDetailsSidePane";
import { LinkedEntityPill } from "src/entities/entityView/LinkedEntityPill";

type Props = {
  name: string;
  id: string;
  schemaId: string;
  entityIcon: IconProp;
  fullWidth?: boolean;
};

type EntityDetailsFloatingWindowProps = Props &
  Omit<FloatingWindowProps, "title" | "children">;

export const EntityDetailsFloatingWindow: React.FC<
  EntityDetailsFloatingWindowProps
> = ({ id, schemaId, entityIcon, name, ...floatingWindowProps }) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <FloatingWindow
        draggable={true}
        height={"h-auto" as `h-${number}`}
        maximizable={false}
        placement="auto"
        title={
          <LinkedEntityPill
            entityIcon={entityIcon}
            id={id}
            name={name}
            schemaId={schemaId}
          />
        }
        {...floatingWindowProps}
      >
        <div className="decideScrollbar mt-2 h-full max-h-[560px] overflow-hidden">
          <EntityDetailsSidePane
            entityId={id}
            schema={schemaId}
            showHeader={false}
          />
        </div>
      </FloatingWindow>
    </div>
  );
};

export const EntityDetailsWindowPill: React.FC<Props> = ({
  name,
  id,
  schemaId,
  entityIcon = faQuestionCircle,
}) => {
  const [pillHovered, setPillHovered] = useState<boolean>(false);
  const [windowHovered, setWindowHovered] = useState<boolean>(false);

  const shouldWindowBeOpen = pillHovered || windowHovered;

  return (
    <EntityDetailsFloatingWindow
      button={
        <div
          className="cursor-default"
          onMouseLeave={() => {
            setTimeout(() => {
              setPillHovered(false);
            }, 100);
          }}
          onMouseOver={() => {
            setPillHovered(true);
          }}
        >
          <Pill hovered={shouldWindowBeOpen} size="sm" variant="gray">
            <Pill.Icon icon={entityIcon} />
            <Pill.Text>{capitalize(name)}</Pill.Text>
          </Pill>
        </div>
      }
      entityIcon={entityIcon}
      id={id}
      isOpen={shouldWindowBeOpen}
      name={name}
      schemaId={schemaId}
      onMouseLeave={() => setWindowHovered(false)}
      onMouseOver={() => setWindowHovered(true)}
    />
  );
};
