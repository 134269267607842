// External imports
import { faCamera, faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useState, useRef } from "react";

// Internal imports
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";

const HIDDEN_INPUT_STYLE = { display: "none" };
const CROSS_ICON_CLASSES =
  "group absolute right-0 top-[-9px] z-10 h-[18px] w-[18px] cursor-pointer rounded-[4px] border border-gray-300 bg-gray-200 p-[3px] text-center text-[10px] font-bold text-gray-800 invisible group-hover:visible no-transition";
const CONTAINER_CLASSES =
  "relative mt-[9px] flex h-[68px] w-[68px] cursor-pointer rounded-lg bg-gray-100 text-gray-500";

interface IconUploadProps {
  onFileSelected?: (file: File) => void;
  onFileRemoved?: () => void;
  errorMessage?: string;
  currentImageSource?: string;
}

export const UploadIcon: React.FC<IconUploadProps> = ({
  onFileSelected,
  onFileRemoved,
  currentImageSource,
}) => {
  // Undefined means no interaction, null means image got deleted
  const [iconMediaKey, setIconMediaKey] = useState<{
    key: string | undefined;
    deleted: boolean;
  }>({
    key: undefined,
    deleted: false,
  });

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || undefined;

    if (file) {
      onFileSelected?.(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        const blob = new Blob([e.target?.result as ArrayBuffer], {
          type: file.type,
        });
        const blobUrl = URL.createObjectURL(blob);
        setIconMediaKey({ key: blobUrl, deleted: false });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const deleteIcon = () => {
    setIconMediaKey({ key: undefined, deleted: true });
    onFileRemoved?.();
    inputFileRef.current?.value && (inputFileRef.current.value = "");
  };

  const showingIcon = Boolean(
    !iconMediaKey.deleted && (iconMediaKey.key || currentImageSource),
  );

  return (
    <div className="group relative h-[77px] w-[77px]">
      <input
        ref={inputFileRef}
        accept=".svg,.jpg,.jpeg,.png,.gif"
        data-loc="icon-upload-input"
        style={HIDDEN_INPUT_STYLE}
        type="file"
        onChange={handleFileChange}
      />

      {showingIcon && (
        <span
          className={CROSS_ICON_CLASSES}
          data-loc="icon-upload-remove"
          onClick={(e) => {
            e.stopPropagation();
            deleteIcon();
          }}
        >
          <Icon icon={faTimes} padding={false} size="2xs" />
        </span>
      )}
      <Tooltip
        body="Use a square image under 100 KB in size. Supported formats are SVG, PNG, and JPG."
        disabled={showingIcon}
        placement="top"
        title="Upload logo"
        asChild
      >
        <div
          className={CONTAINER_CLASSES}
          onClick={() => inputFileRef.current?.click()}
        >
          <label className="flex h-full w-full cursor-pointer items-center justify-center">
            {showingIcon ? (
              <img
                alt="Custom Icon"
                className="h-full w-full object-contain"
                data-loc="custom-icon"
                src={iconMediaKey.key || currentImageSource}
              />
            ) : (
              <Icon dataLoc="upload-preview-icon" icon={faCamera} size="lg" />
            )}
          </label>
        </div>
      </Tooltip>
    </div>
  );
};
