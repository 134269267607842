import { useEffect } from "react";

import { OrgPartner } from "src/clients/taktile-api";
import { useWorkspaceAndOrg } from "src/utils/useCurrentWorkspace";

const PARTNER_LOCAL_STORAGE_KEY = "partner-vendor";

export const PartnerStorageManager: React.FC = () => {
  const { org } = useWorkspaceAndOrg();

  useEffect(() => {
    if (org) {
      // We will add a partner field to orgs that will drive this in the future. But for now the whitelabel flag is enough.
      if (org.partner === OrgPartner.LOANPRO) {
        localStorage.setItem(PARTNER_LOCAL_STORAGE_KEY, OrgPartner.LOANPRO);
      } else {
        localStorage.removeItem(PARTNER_LOCAL_STORAGE_KEY);
      }
    }
  }, [org]);

  return null;
};
