import { noop } from "lodash";
import { useMemo, useState } from "react";

import { GroupFilter } from "src/api/endpoints";
import { useFlows } from "src/api/queries";
import { DecisionEnvironment } from "src/api/types";
import {
  FlowAccordion,
  TopLevelFilters,
} from "src/decisionsOverview/DecisionsOverview";
import { useDecisionsOverview } from "src/decisionsOverview/queries";
import { ExtendedHistoricalDecisionV3 } from "src/flow/decisionHistory/Columns";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { isFlowGroup, isFlowGroupForCount } from "src/utils/predicates";

type Props = {
  selectedDecision: ExtendedHistoricalDecisionV3 | null;
  onSelectDecision: (decision: ExtendedHistoricalDecisionV3 | null) => void;
};

export const DecisionsTabContent: React.FC<Props> = ({
  selectedDecision,
  onSelectDecision,
}) => {
  // const { id } = useParamsDecode<{ id: string }>();
  const id = "2993f799-2630-4f06-93bc-6961d8486303";
  const { workspace } = useWorkspaceContext();
  const [groupFilter] = useState<GroupFilter>({});
  const [topLevelFilters] = useState<TopLevelFilters>({
    decisionEnv: DecisionEnvironment.SANDBOX,
    decisionId: null,
    entityId: id,
    timeWindow: undefined,
  });

  const decisionsOverviewQuery = useDecisionsOverview(workspace.base_url, {
    environments: DecisionEnvironment.SANDBOX,
    groupby: "flow.id",
    aggregate: "count",
    entity_id: id,
    parent_decision_id: undefined,
    timeWindow: undefined,
  });

  const flowsQuery = useFlows({ workspaceId: workspace.id });

  const decisionSummary = decisionsOverviewQuery.data ?? { groups: [] };
  const decisionsOverviewQueryForCount = useDecisionsOverview(
    workspace.base_url,
    {
      environments: DecisionEnvironment.SANDBOX,
      groupby: "flow.id",
      aggregate: "count",
      entity_id: id,
      parent_decision_id: undefined,
      timeWindow: undefined,
      groupfilter: groupFilter,
    },
  );

  const flowGroups = useMemo(
    () =>
      decisionSummary.groups
        .map((group) => ({
          flow: (flowsQuery.data ?? []).find(
            (flow) => flow.id === group.flow?.id,
          ),
        }))
        .filter(isFlowGroup)
        .sort((a, b) => a.flow?.name.localeCompare(b.flow?.name)) ?? [],
    [decisionSummary.groups, flowsQuery.data],
  );

  const flowGroupsForCount = useMemo(() => {
    return decisionsOverviewQueryForCount.data?.groups
      .map((group) => ({
        flowId: group.flow?.id,
        count: group.count,
      }))
      .filter(isFlowGroupForCount);
  }, [decisionsOverviewQueryForCount.data]);

  return (
    <div className="overflow-hidden p-5">
      <FlowAccordion
        flowGroups={flowGroups}
        flowGroupsForCount={flowGroupsForCount}
        groupFilter={groupFilter}
        rowBehavior="expand"
        selectedDecision={selectedDecision}
        singleDecision={null}
        topLevelFilters={topLevelFilters}
        onGroupFilterChange={noop}
        onSelectDecision={onSelectDecision}
      />
    </div>
  );
};
