import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

import { UserSettings } from "./UserSettings";
import { useAllUsers, useAllOrgs } from "./queries";
import { OrganizationsWorkspacesSettings } from "src/adminPanel/OrganizationsWorkspacesSettings";
import { useUserOrganizations, useWorkspaces } from "src/api/queries";
import TaktileLogo from "src/assets/Taktile.svg?react";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { Tabs } from "src/base-components/Tabs";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { OrganizationInDB, User } from "src/clients/taktile-api";

export const AdminPanel: React.FC = () => {
  const workspaceData = useWorkspaces();
  const userOrganizations = useUserOrganizations();
  const allUsers = useAllUsers();
  const allOrgs = useAllOrgs();

  return (
    <div className="flex h-screen w-screen flex-col bg-gray-100">
      <header className="box-border flex min-h-[52px] w-full flex-row items-center gap-x-2.5 bg-white py-2 pl-4 pr-5">
        <Link to="..">
          <TaktileLogo />
        </Link>
        <Icon color="text-gray-500" icon={faChevronRight} size="xs" />
        <p className="text-gray-800 font-inter-semibold-13px">Admin Panel</p>
      </header>
      <Tabs
        containerClassName="mt-2 px-4 pb-4 flex-1 min-h-0 flex flex-col"
        panelClassName="flex-1 min-h-0 decideScrollbar"
        panelsClassName="flex-1 min-h-0 flex"
        tabs={[
          {
            content: (
              <LoadingView
                queryResult={[workspaceData, userOrganizations]}
                renderUpdated={([workspaces, organizations]: [
                  WorkspaceDataplane[],
                  OrganizationInDB[],
                ]) => (
                  <div className="border-t border-gray-200 pt-4">
                    <OrganizationsWorkspacesSettings
                      organizations={organizations}
                      workspaces={workspaces}
                    />
                  </div>
                )}
              />
            ),
            key: "Workspaces",
            label: "Workspaces",
          },
          {
            content: (
              <LoadingView
                queryResult={[allUsers, allOrgs, workspaceData]}
                renderUpdated={([users, orgs, wss]: [
                  User[],
                  OrganizationInDB[],
                  WorkspaceDataplane[],
                ]) => (
                  <div className="border-t border-gray-200 pt-4">
                    <UserSettings orgs={orgs} users={users} wss={wss} />
                  </div>
                )}
              />
            ),

            key: "Users",
            label: "Users",
          },
        ]}
      />
    </div>
  );
};
