import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";

import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { Cell, TableComp } from "src/base-components/Table";
import { PropertyDefinitionOutputTypeEnum } from "src/clients/features-control";
import { parseJsonOrReturnAsIs } from "src/datasets/DatasetTable/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { EntityDetailsWindowPill } from "src/entities/entityView/EntityDetailsWindowPill";
import { entityIcons } from "src/entities/entityView/utils";
import { useEvent, useEventType } from "src/eventsCatalogue/queries";
import { EnumPill, PropertyWithIcon } from "src/eventsCatalogue/tableConfig";
import { getEventIcon } from "src/eventsCatalogue/utils";
import { useEnvironment } from "src/flow/decisionHistory/filterHooks";
import { formatDate } from "src/utils/datetime";
import { formatNumber } from "src/utils/numbers";
import { speakPythonPrimitive } from "src/utils/speakPython";

type EventViewPaneProps = {
  eventType: string;
  eventId: string;
  onClose: () => void;
};

export const EventViewPane: React.FC<EventViewPaneProps> = ({
  eventType,
  eventId,
  onClose,
}) => {
  const [environment] = useEnvironment();
  const { data: event, isLoading: isEventLoading } = useEvent({
    eventType,
    eventId,
    environment,
  });
  const { data: schema, isLoading: isSchemaLoading } = useEventType(eventType);

  const transformEventToRows = () => {
    if (!event?.payload || !schema) return [];

    const eventData = event.payload;
    const rows = [
      {
        label: "ID",
        value: eventData.id,
        type: PropertyDefinitionOutputTypeEnum.STRING,
        render: (value: any) => (
          <CopyTextIcon feedback="inline" value={value} />
        ),
      },
      {
        label: "Timestamp",
        value: eventData.timestamp,
        type: PropertyDefinitionOutputTypeEnum.DATETIME,
        render: (value: any) => formatDate(value, "MMM d, yyyy 'at' h:mmaaa"),
      },
    ];

    Object.entries(schema.properties)
      .filter(([key]) => !["id", "timestamp"].includes(key))
      .forEach(([key, value]: [string, any]) => {
        const cellValue = eventData[key];
        let renderer;

        if (
          value.type === PropertyDefinitionOutputTypeEnum.DATETIME ||
          value.type === PropertyDefinitionOutputTypeEnum.DATE
        ) {
          renderer = (val: any) =>
            formatDate(
              val,
              value.type === PropertyDefinitionOutputTypeEnum.DATETIME
                ? "MMM d, yyyy 'at' h:mmaaa"
                : "MMM d, yyyy",
            );
        } else if (
          value.type === PropertyDefinitionOutputTypeEnum.NUMBER ||
          value.type === PropertyDefinitionOutputTypeEnum.INTEGER
        ) {
          renderer = (val: any) =>
            formatNumber(val, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            });
        } else if (value.type === PropertyDefinitionOutputTypeEnum.ENUM) {
          renderer = (val: any) => {
            const parsedCellValue = parseJsonOrReturnAsIs(val);
            const enumValue = value.enum_schema?.values.find(
              (v: any) => v.value === parsedCellValue,
            );
            if (!enumValue) return;
            return <EnumPill enumValue={enumValue} />;
          };
        } else if (value.type === PropertyDefinitionOutputTypeEnum.RELATION) {
          renderer = (val: any) => (
            <EntityDetailsWindowPill
              entityIcon={
                entityIcons[
                  value.relation_schema?.entity_type as keyof typeof entityIcons
                ]
              }
              fullWidth={false}
              id=""
              name={val}
              schemaId=""
            />
          );
        } else if (value.type === PropertyDefinitionOutputTypeEnum.BOOLEAN) {
          renderer = (val: any) => speakPythonPrimitive(String(val));
        } else {
          renderer = (val: any) => val;
        }

        rows.push({
          label:
            value.display_name ?? value.relation_schema?.display_name ?? key,
          value: cellValue,
          type: value.type,
          render: renderer,
        });
      });

    return rows;
  };

  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor("label", {
      header: "Label",
      cell: (info) => (
        <PropertyWithIcon type={info.row.original.type}>
          {info.getValue()}
        </PropertyWithIcon>
      ),
      maxSize: 200,
    }),
    columnHelper.accessor("value", {
      header: "Value",
      cell: (info) => (
        <Cell info={info}>
          <Tooltip
            disabled={typeof info.getValue() !== "string"}
            placement="top"
            title={info.getValue()}
            asChild
          >
            <div className="truncate">
              {info.row.original.render(info.getValue())}
            </div>
          </Tooltip>
        </Cell>
      ),
      maxSize: 240,
    }),
  ];

  return (
    <div className="flex w-110 shrink-0 flex-col gap-y-4 bg-white p-5">
      <div>
        {!isEventLoading && event && schema && !isSchemaLoading ? (
          <div className="flex items-center justify-between">
            <div className="flex gap-2 text-gray-800 font-inter-semibold-13px">
              <div className="h-5 w-5 rounded border border-gray-200">
                <Icon
                  color="text-gray-600"
                  icon={getEventIcon(schema)}
                  size="2xs"
                />
              </div>
              {formatDate(event.payload.timestamp, "MMMM d, yyyy, hh:mm aa")}
            </div>
            <Icon
              color="text-gray-500"
              icon={faTimes}
              size="xs"
              onClick={onClose}
            />
          </div>
        ) : (
          <SkeletonPlaceholder height="h-5" />
        )}
      </div>
      <Divider />
      {schema && (
        <TableComp
          columns={columns}
          data={transformEventToRows()}
          dataLoc="event-details-table"
          frameClassName="[&_thead_tr]:hidden [&_table]:border-b-0"
          isLoading={isEventLoading || isSchemaLoading}
          variant="compact"
          noScroll
        />
      )}
    </div>
  );
};
