import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { useConnections } from "src/api/connectApi/queries";
import RetoolIcon from "src/assets/RetoolMenuIcon.svg?react";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/design-system/Tooltip";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { UserMenu } from "src/layout/UserMenuV2";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getUrlToRetoolPage } from "src/router/urls";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type BreadcrumbProps = {
  label: string;
  href: string;
  dataLoc?: string;
  children?: never;
  hideIcon?: boolean;
  active?: boolean;
};

type BreadcrumbType = React.FC<BreadcrumbProps>;

type Props =
  | {
      title: React.ReactNode;
      breadcrumbs?: never;
    }
  | {
      title?: never;
      breadcrumbs: { key: string; breadcrumb: ReturnType<BreadcrumbType> }[];
    };

type SubComponents = {
  Breadcrumb: BreadcrumbType;
};

const SideContent = () => {
  const { wsId, orgId } = useParamsDecode<DashboardPageParamsT>();
  const { workspace } = useWorkspaceContext();

  const connections = useConnections(workspace.base_url);
  const retoolConnection = connections.data?.find(
    (c) => c.provider === "retool",
  );
  const location = useLocation();

  const retoolPageUrl = getUrlToRetoolPage(orgId, wsId, retoolConnection?.id);
  const isCurrentPageRetool =
    `${location.pathname}${location.search}` === `${retoolPageUrl}`;

  return (
    <div className="mr-3 flex items-center gap-4">
      {retoolPageUrl && (
        <Tooltip placement="bottom-end" title={retoolConnection?.name} asChild>
          <Link
            className={
              isCurrentPageRetool ? "text-indigo-500" : "text-gray-700"
            }
            to={retoolPageUrl}
          >
            <RetoolIcon />
          </Link>
        </Tooltip>
      )}
      <UserMenu />
    </div>
  );
};

export const PageHeader: React.FC<Props> & SubComponents = ({
  title,
  breadcrumbs,
}) => {
  const [environment] = useEnvironment();

  return (
    <div className="sticky z-10 flex h-13 shrink-0 items-center justify-between gap-x-2 border-b border-l border-gray-200 bg-white">
      {title && <h2 className="pl-4 font-inter-semibold-13px">{title}</h2>}
      {breadcrumbs && breadcrumbs.length > 0 && (
        <ul className="flex items-center gap-x-3 pl-4" data-loc="breadcrumbs">
          {breadcrumbs.map(({ key, breadcrumb }) => (
            <React.Fragment key={key}>{breadcrumb}</React.Fragment>
          ))}
        </ul>
      )}
      {environment !== "live" && (
        <Pill>
          <Pill.Text>{capitalize(environment)}</Pill.Text>
        </Pill>
      )}
      <div className="ml-auto flex h-full items-center">
        <SideContent />
      </div>
    </div>
  );
};

const PageHeaderBreadcrumb: BreadcrumbType = ({
  label,
  href,
  children,
  dataLoc,
  hideIcon,
  active,
}) => (
  <li className="flex items-center gap-x-3">
    {!hideIcon && (
      <Icon color="text-gray-500" icon={faChevronRight} size="xs" />
    )}
    {href ? (
      <Link
        className={twMerge(
          "text-indigo-600 font-inter-semibold-13px",
          active && "text-gray-800",
        )}
        data-loc={dataLoc}
        to={href}
      >
        {label}
      </Link>
    ) : (
      children
    )}
  </li>
);

PageHeader.Breadcrumb = PageHeaderBreadcrumb;
