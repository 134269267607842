import { useNavigate } from "react-router-dom";

import { EventViewPane } from "src/eventsCatalogue/EventViewPane";
import { EventsCatalogueParams, getUrlToEventsPage } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const EventViewSidebar = () => {
  const navigate = useNavigate();
  const {
    event_type: eventType,
    event_id: eventId,
    orgId,
    wsId,
  } = useParamsDecode<EventsCatalogueParams>();

  if (!eventId) {
    return null;
  }

  const onClose = () => {
    navigate(
      getUrlToEventsPage({
        orgId,
        wsId,
        eventType,
      }),
    );
  };

  return (
    <EventViewPane eventId={eventId} eventType={eventType} onClose={onClose} />
  );
};
