import { twJoin } from "tailwind-merge";

type SkeletonPlaceholderProps = {
  width?: `w-${number}` | `w-${number}/${number}` | "w-full";
  height?: `h-${number}` | `h-${number}/${number}` | "h-full";
  rounded?: "rounded-full" | "rounded-lg";
  shrink0?: boolean;
  dataLoc?: string;
};

export const SkeletonPlaceholder: React.FC<SkeletonPlaceholderProps> = ({
  width = "w-full",
  height,
  rounded,
  shrink0,
  dataLoc,
}) => (
  <div
    className={twJoin(
      "relative overflow-hidden rounded bg-gray-100",
      width,
      height,
      rounded,
      shrink0 && "shrink-0",
    )}
    data-loc={dataLoc}
  >
    <div
      className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100"
      style={{ animation: "shimmer 1s infinite" }}
    ></div>
  </div>
);
