import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Suspense } from "react";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { ProtectedRoute } from "./ProtectedRoute";
import { AdminPanel } from "src/adminPanel/AdminPanel";
import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useFlowVersion } from "src/api/flowVersionQueries";
import { baseUrl } from "src/api/hosts";
import {
  useFlow,
  useUserOrganizations,
  useWorkspace,
  useWorkspaces,
} from "src/api/queries";
import { LoadingView } from "src/base-components/LoadingView";
import { Spinner } from "src/base-components/Spinner";
import { useRevision } from "src/changeHistory/queries";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { SimpleRoleType } from "src/clients/taktile-api";
import { DecisionsOverview } from "src/decisionsOverview/DecisionsOverview";
import { Entities } from "src/entities/Entities";
import { EntityView } from "src/entities/entityView/EntityView";
import { EventSchemaEditSidebar } from "src/eventsCatalogue/EventSchemaEditSidebar";
import { EventViewSidebar } from "src/eventsCatalogue/EventViewSidebar";
import { EventsCatalogue } from "src/eventsCatalogue/EventsCatalogue";
import { EventsCatalogueIndex } from "src/eventsCatalogue/EventsCatalogueIndex";
import { FeaturesPage } from "src/featureCatalogue/Features/FeaturesPage";
import { QueriesPage } from "src/featureCatalogue/Queries/QueriesPage";
import { FlowPage } from "src/flow/FlowPage";
import { FlowTrafficContent } from "src/flow/FlowTrafficContent";
import { FlowVersionsContent } from "src/flow/FlowVersionsContent";
import { FlowHistoryContent } from "src/flow/decisionHistory/FlowHistoryContent";
import { NavigateToFlowVersion } from "src/flowContainer/NavigateToFlowVersion";
import { VersionAuthoringWrapper } from "src/flowContainer/VersionAuthoringWrapper";
import { FlowOverview } from "src/flowsOverview/FlowOverview";
import { GitbookAuthPage } from "src/gitbookAuth/GitbookAuthPage";
import { JobContent } from "src/jobs/JobContent";
import { JobsContent } from "src/jobs/JobsContent";
import { MainPageDashboard } from "src/mainPage/MainPage";
import { ReviewCaseContent } from "src/manualReview/ReviewCaseContent";
import { ReviewCasePreviewContent } from "src/manualReview/ReviewCasePreviewContent";
import { ReviewQueueContent } from "src/manualReview/ReviewQueueContent";
import { OmniboxOther } from "src/omnibox/OmniboxOther";
import { OutcomesContent } from "src/outcomes/OutcomesContent";
import { PerformanceContent } from "src/performance/PerformanceContent";
import { DisplayRedirects } from "src/router/DisplayRedirects";
import { OrgFavicon } from "src/router/DynamicFavicon";
import { ErrorPage } from "src/router/ErrorPage";
import { PartnerStorageManager } from "src/router/PartnerStorageManager";
import { PickWorkspace } from "src/router/PickWorkspace";
import { ProtectedPage } from "src/router/ProtectedPage";
import { PageViewTracker } from "src/router/Track";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import {
  adminPanelPath,
  AuthorPageParamsT,
  DashboardPageParamsT,
  getUrlToSettingsPage,
  getUrlToWsDashboard,
  getWelcomeUrl,
  RevisionPageParamsT,
  WsDashboardPageCode,
} from "src/router/urls";
import { SettingsPage, SettingsSubpages } from "src/settings/SettingsPage";
import { SettingsPageRedirect } from "src/settings/SettingsPageRedirect";
import { canAccessSettingsPage } from "src/settings/utils";
import { useIsUserRefreshed } from "src/store/AuthStore";
import { SimpleRoleProvider } from "src/store/SimpleRoleProvider";
import { WelcomePage } from "src/userManagement/WelcomePage";
import { ErrorBoundary } from "src/utils/ErrorBoundary";
import { useBootTheena } from "src/utils/useBootTheena";
import { useLastWorkspaceId } from "src/utils/useLastWorkspaceId";
import { useParamsDecode } from "src/utils/useParamsDecode";
import { OutgoingWebhooksPage } from "src/webhooks/WebhooksPage";

const DocsPage = React.lazy(() => import("src/docs/DocsPage"));
const Retool = React.lazy(() =>
  import("src/retool/Retool").then((module) => ({ default: module.Retool })),
);

enum ORG_ROUTE_CODES {
  UsersPermissions = "users-permissions",
}

const FlowVersionRoutes: React.FC = () => {
  const { workspace } = useWorkspaceContext();
  const { flow_id, version_id, orgId } = useParamsDecode<AuthorPageParamsT>();

  // Fetching flow and flow version here allows to disable refetchOnMount on all child components
  // but still able fetch latest data on initial page visit while navigating within the app
  // const workspaceQuery = useWorkspace(wsId);
  const flowQuery = useFlow(flow_id, { refetchOnMount: true });
  const versionQuery = useFlowVersion(version_id, true);
  const isRevision = false;

  return (
    <LoadingView
      queryResult={[flowQuery, versionQuery]}
      renderUpdated={([flow, version]: [FlowT, FlowVersionT]) => (
        <Outlet context={{ workspace, flow, version, orgId, isRevision }} />
      )}
    />
  );
};

const WorkspaceRoutesContext: React.FC = () => {
  const { wsId, orgId } = useParamsDecode<DashboardPageParamsT>();
  const workspaceQuery = useWorkspace(wsId);

  return (
    <LoadingView
      queryResult={workspaceQuery}
      renderErrored={() => (
        <ErrorPage message="You do not have access to the requested organization or workspace. Please contact Taktile support if you think this is an error." />
      )}
      renderUpdated={(workspace: WorkspaceDataplane) => (
        <Outlet context={{ workspace, orgId }} />
      )}
    />
  );
};

// @AUTH-2107 TODO: Unify with FlowVersionRoutes
const FlowVersionRevisionRoutes: React.FC = () => {
  const { workspace, orgId } = useWorkspaceContext();
  const { flow_id, version_id, revision_etag } =
    useParamsDecode<RevisionPageParamsT>();

  // Fetching flow and flow version here allows to disable refetchOnMount on all child components
  // but still able fetch latest data on initial page visit while navigating within the app
  const flowQuery = useFlow(flow_id, { refetchOnMount: true });
  const versionQuery = useRevision({
    versionId: version_id,
    etag: revision_etag,
  });
  const isRevision = true;

  return (
    <LoadingView
      queryResult={[flowQuery, versionQuery]}
      renderUpdated={([flow, version]: [FlowT, FlowVersionT]) => (
        <Outlet context={{ workspace, flow, version, orgId, isRevision }} />
      )}
    />
  );
};

const FlowRoutes = (
  <Route element={<FlowPage />} path="flows/:flow_id/">
    <Route
      element={
        <ProtectedPage canAccess={(c) => c.flowVersions.canAccess}>
          <FlowVersionsContent />
        </ProtectedPage>
      }
      index
    />

    <Route
      element={
        <ProtectedPage canAccess={(c) => c.flowVersions.canAccess}>
          <FlowTrafficContent />
        </ProtectedPage>
      }
      path="traffic"
    />
    <Route
      element={
        <ProtectedPage canAccess={(c) => c.decisionHistory.canAccess}>
          <FlowHistoryContent />
        </ProtectedPage>
      }
      path="history"
    />
    <Route
      element={
        <ProtectedPage canAccess={(c) => c.outgoingWebhooks.canAccess}>
          <OutgoingWebhooksPage />
        </ProtectedPage>
      }
      path="webhooks"
    />
    <Route
      element={
        <ProtectedPage canAccess={(c) => c.flows.canEdit}>
          <PerformanceContent />
        </ProtectedPage>
      }
      path="performance"
    />
    <Route
      element={<ProtectedRoute canAccess={(c) => c.manualReview.canAccess} />}
      path="review-queue"
    >
      <Route element={<ReviewQueueContent />} index />
      <Route element={<ReviewCaseContent />} path=":case_id" />
    </Route>
    <Route
      element={<ProtectedRoute canAccess={(c) => c.jobs.canAccess} />}
      path="jobs"
    >
      <Route element={<JobsContent />} index />
      <Route element={<JobContent />} path=":job_id" />
    </Route>
    <Route
      element={<ProtectedRoute canAccess={(c) => c.outcomes.canAccess} />}
      path="outcomes"
    >
      <Route element={<OutcomesContent />} index />
    </Route>
  </Route>
);

const WorkspaceRoutes: React.FC = observer(() => {
  const { wsId, orgId } = useParamsDecode<DashboardPageParamsT>();
  const [, setLastWorkspaceId] = useLastWorkspaceId();
  const welcomeUrl = getWelcomeUrl(orgId);
  const { workspace } = useWorkspaceContext();

  const organizations = useUserOrganizations();
  const organization = organizations.data?.find((org) => org.id === orgId);

  useEffect(() => {
    setLastWorkspaceId(wsId);
  }, [setLastWorkspaceId, wsId]);

  if (!workspace || (organizations.data && !organization)) {
    return (
      <Routes>
        <Route
          element={
            <ErrorPage message="You do not have access to the requested organization or workspace. Please contact Taktile support if you think this is an error." />
          }
          path="*"
        />
      </Routes>
    );
  }

  return (
    <SimpleRoleProvider>
      <PartnerStorageManager />
      <OrgFavicon />
      <OmniboxOther />
      <Routes>
        <Route
          element={<Navigate to={getUrlToWsDashboard({ orgId, wsId })} />}
          index
        />

        <Route
          element={<ProtectedRoute canAccess={canAccessSettingsPage} />}
          path="settings"
        >
          <Route
            element={
              <ProtectedPage canAccess={(c) => c.usersPermissions.canAccess}>
                <SettingsPage subpage={SettingsSubpages.Users} />
              </ProtectedPage>
            }
            path="users"
          />
          <Route
            element={
              <ProtectedPage canAccess={(c) => c.connections.canAccess}>
                <SettingsPage subpage={SettingsSubpages.Connections} />
              </ProtectedPage>
            }
            path="connections"
          />
          <Route
            element={
              <ProtectedPage canAccess={(c) => c.apiKeys.canAccess}>
                <SettingsPage subpage={SettingsSubpages.ApiKeys} />
              </ProtectedPage>
            }
            path="api-keys"
          />
          <Route
            element={
              <ProtectedPage canAccess={(c) => c.auditLogs.canAccess}>
                <SettingsPage subpage={SettingsSubpages.AuditLogs} />
              </ProtectedPage>
            }
            path="audit-logs"
          />
          <Route
            element={
              <ProtectedPage canAccess={(c) => c.role === SimpleRoleType.ADMIN}>
                <SettingsPage subpage={SettingsSubpages.Powertools} />
              </ProtectedPage>
            }
            path="powertools"
          />
          <Route element={<SettingsPageRedirect />} index />
        </Route>
        <Route element={<ProtectedRoute redirectPath={welcomeUrl} />}>
          <Route element={<MainPageDashboard />}>
            <Route element={<></>} path={WsDashboardPageCode.Dashboard} />
            <Route
              element={
                <FlowOverview organizationId={orgId} workspaceId={wsId} />
              }
              path={WsDashboardPageCode.Flows}
            />
            <Route path={WsDashboardPageCode.Entities}>
              <Route element={<Entities />} index />
              <Route element={<Entities />} path=":schema" />
              <Route element={<EntityView />} path=":schema/:id" />
            </Route>
            <Route path={WsDashboardPageCode.Events}>
              <Route element={<EventsCatalogueIndex />} index />
              <Route element={<EventsCatalogue />} path=":event_type">
                <Route element={<EventViewSidebar />} path="event/:event_id" />
                <Route element={<EventSchemaEditSidebar />} path="edit" />
              </Route>
            </Route>
            <Route path={WsDashboardPageCode.Features}>
              <Route
                element={
                  <ProtectedPage
                    canAccess={(c) => c.featureCatalogue.canAccess}
                  >
                    <FeaturesPage />
                  </ProtectedPage>
                }
                index
              />
              <Route
                element={
                  <ProtectedPage
                    canAccess={(c) => c.featureQueriesCatalogue.canAccess}
                  >
                    <QueriesPage />
                  </ProtectedPage>
                }
                path="queries"
              />
            </Route>
          </Route>
          <Route
            element={
              <ProtectedPage canAccess={(c) => c.decisionHistory.canAccess}>
                <DecisionsOverview />
              </ProtectedPage>
            }
            path="decisions"
          />
          <Route
            element={<NavigateToUsersPermissions />}
            path={ORG_ROUTE_CODES.UsersPermissions}
          />
          {FlowRoutes}
        </Route>

        <Route element={<NavigateToFlowVersion />} path="flow/:flow_id/" />
        <Route
          element={
            <ProtectedRoute canAccess={(c) => c.flowVersions.canAccess} />
          }
        >
          <Route
            element={<FlowVersionRoutes />}
            path="flow/:flow_id/version/:version_id/"
          >
            <Route element={<VersionAuthoringWrapper />} index />
            <Route
              element={
                <Suspense fallback={<Spinner />}>
                  <DocsPage />
                </Suspense>
              }
              path="docs"
            />
          </Route>

          <Route
            element={<FlowVersionRevisionRoutes />}
            path="flow/:flow_id/version/:version_id/revision/:revision_etag"
          >
            <Route element={<VersionAuthoringWrapper />} index />
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoute
              canAccess={(c) => c.manualReview.canAccess}
              redirectPath={welcomeUrl}
            />
          }
        >
          {/**
           * This route is outside of DashboardLayout,
           * because we don't want it to have sidebar
           * It is just manual review case preview
           */}
          <Route
            element={<ReviewCasePreviewContent />}
            path="flows/:flow_id/review-queue/preview"
          />

          <Route
            element={
              <ProtectedPage canAccess={(c) => c.manualReview.canAccess}>
                <Suspense fallback={<Spinner />}>
                  <Retool wsId={wsId} />
                </Suspense>
              </ProtectedPage>
            }
            path="retool/:retool_id"
          />
        </Route>

        <Route element={<ErrorPage message="404: Page not found" />} path="*" />
      </Routes>
    </SimpleRoleProvider>
  );
});

const NavigateToUsersPermissions = () => {
  const { orgId } = useParamsDecode<{ orgId: string }>();
  const workspaces = useWorkspaces(true);
  const workspace = workspaces.data?.find((ws) => ws.organization_id === orgId);

  if (!workspace) {
    return <Navigate to={getWelcomeUrl(orgId)} replace />;
  }

  return (
    <Navigate
      to={getUrlToSettingsPage(
        orgId,
        workspace?.id ?? "",
        SettingsSubpages.Users,
      )}
      replace
    />
  );
};

const OrgRoutes = observer(() => {
  const { orgId } = useParamsDecode<{ orgId: string }>();
  const { isLoading, data: organizations } = useUserOrganizations();
  const organization = organizations?.find((org) => org.id === orgId);

  const unauthorizedMessage =
    "You do not have access to the requested organization. Please contact Taktile support if you think this is an error.";

  if ((organizations && !organization) || isLoading) {
    return (
      <Routes>
        <Route
          element={
            isLoading ? (
              <Spinner />
            ) : (
              <ErrorPage message={unauthorizedMessage} />
            )
          }
          path="*"
        />
      </Routes>
    );
  }

  return (
    <SimpleRoleProvider>
      <Routes>
        <Route
          element={
            <ProtectedPage canAccess={(c) => c.usersPermissions.canAccess}>
              <NavigateToUsersPermissions />
            </ProtectedPage>
          }
          path={ORG_ROUTE_CODES.UsersPermissions}
        />
        <Route element={<WelcomePage />} path="welcome" />
        <Route element={<ErrorPage message="404: Page not found" />} path="*" />
      </Routes>
    </SimpleRoleProvider>
  );
});

const RoutesWrapper: React.FC = () => (
  <ErrorBoundary>
    <Outlet />
  </ErrorBoundary>
);

export const Router = () => {
  const refreshed = useIsUserRefreshed();
  useBootTheena();

  if (!refreshed) {
    return <Spinner />;
  }

  const basename = baseUrl();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<RoutesWrapper />}>
          <Route element={<GitbookAuthPage />} path="gitbook-auth" />
          <Route element={<PageViewTracker />}>
            <Route element={<PickWorkspace />} path="" />
            <Route element={<AdminPanel />} path={adminPanelPath} />
            <Route
              element={<WorkspaceRoutesContext />}
              path="org/:orgId/ws/:wsId/*"
            >
              <Route element={<WorkspaceRoutes />} path="*" />
            </Route>
            <Route element={<OrgRoutes />} path="org/:orgId/*" />
            <Route element={<ErrorPage />} path="error" />
            <Route element={<DisplayRedirects />} path="display/:type" />
            <Route
              element={<ErrorPage message="404: Page not found" />}
              path="*"
            />
          </Route>
        </Route>
      </>,
    ),
    {
      basename: basename ? basename + "decide/" : "/decide",
    },
  );

  return <RouterProvider router={router} />;
};
