import { ReactNode } from "react";
import { twJoin } from "tailwind-merge";

export const WhitePane: React.FC<{
  children: ReactNode;
  noMinWidth?: boolean;
}> = ({ children, noMinWidth }) => {
  return (
    <div
      className={twJoin(
        "flex h-full w-full items-center justify-center rounded-lg border border-gray-200 bg-white",
        !noMinWidth && "min-w-[1000px]",
      )}
    >
      {children}
    </div>
  );
};
