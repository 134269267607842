import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { twJoin } from "tailwind-merge";

import { ColumnFilter } from "src/base-components/ColumnFilter";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Cell, ColumnVisibilityDropdown } from "src/base-components/Table";
import {
  EnumValue,
  EventConfigEnriched,
  PropertyDefinitionOutput,
  PropertyDefinitionOutputTypeEnum,
} from "src/clients/features-control";
import { parseJsonOrReturnAsIs } from "src/datasets/DatasetTable/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { EntityDetailsWindowPill } from "src/entities/entityView/EntityDetailsWindowPill";
import { entityIcons } from "src/entities/entityView/utils";
import { ManyToOneRelationIcon } from "src/eventsCatalogue/SchemaEditor/RelationIcons";
import { DeserializedEvent } from "src/eventsCatalogue/queries";
import { TYPE_ICONS } from "src/utils/constants";
import { formatDate } from "src/utils/datetime";
import { formatNumber } from "src/utils/numbers";
import { speakPythonPrimitive } from "src/utils/speakPython";

const helper = createColumnHelper<DeserializedEvent>();

export const PropertyWithIcon = ({
  children,
  type,
}: {
  children: React.ReactNode;
  type: PropertyDefinitionOutputTypeEnum;
}) => {
  return (
    <Tooltip placement="top" title={children} asChild>
      <div className="flex items-center gap-0.5 truncate pl-1 pr-2 text-left text-gray-800 font-inter-medium-12px">
        {type === PropertyDefinitionOutputTypeEnum.RELATION ? (
          <ManyToOneRelationIcon />
        ) : (
          <Icon
            color="text-gray-500"
            icon={TYPE_ICONS[type as keyof typeof TYPE_ICONS]}
            size="xs"
          />
        )}
        <span className="truncate">{children}</span>
      </div>
    </Tooltip>
  );
};

const HeaderCell: React.FC<{
  filterKey: string;
  content: string;
  value: PropertyDefinitionOutput;
  filters?: Record<string, string | null>;
  onFilterChange?: (filters: Record<string, string | null>) => void;
}> = ({ filterKey: key, content, value, filters, onFilterChange }) => {
  return (
    <div className="flex h-8 items-center justify-between">
      <PropertyWithIcon type={value.type}>{content}</PropertyWithIcon>
      {onFilterChange &&
        value.type === PropertyDefinitionOutputTypeEnum.ENUM &&
        value.enum_schema && (
          <ColumnFilter
            description={`Filter by ${value.display_name}`}
            elements={value.enum_schema.values.map((v) => ({
              key: v.value,
              value: v.value,
            }))}
            isFiltering={!!filters?.[key]}
            selected={filters?.[key] ?? undefined}
            onResetRequest={() => onFilterChange({ ...filters, [key]: null })}
            onSelect={(filter) => onFilterChange({ ...filters, [key]: filter })}
          />
        )}
    </div>
  );
};

export const getColumns = (
  schema: EventConfigEnriched,
  selectedEventId?: string | null,
  filtering?: {
    filters: Record<string, string | null>;
    onFilterChange: (filters: Record<string, string | null>) => void;
  },
) => {
  const idColumnDisplayName = "ID";
  const timestampColumnDisplayName = "Timestamp";

  const baseColumns = [
    helper.accessor("payload.id", {
      header: () => (
        <HeaderCell
          content={idColumnDisplayName}
          filterKey="id"
          value={schema.properties.id}
        />
      ),
      cell: (info) => (
        <Cell info={info}>
          <CopyTextIcon feedback="inline" value={info.getValue()} />
        </Cell>
      ),
      size: 60,
      maxSize: 60,
      enableHiding: false,
      meta: {
        displayName: idColumnDisplayName,
      },
    }),
    helper.accessor("payload.timestamp", {
      header: () => (
        <HeaderCell
          content={timestampColumnDisplayName}
          filterKey="timestamp"
          value={schema.properties.timestamp}
        />
      ),
      cell: (info) => (
        <Cell info={info}>
          {formatDate(info.getValue(), "MMM d, yyyy 'at' h:mm a")}
        </Cell>
      ),
      enableHiding: false,
      maxSize: 200,
      meta: {
        displayName: timestampColumnDisplayName,
      },
    }),
  ];

  const remainingColumns = Object.entries(schema.properties)
    .filter(([key]) => !["id", "timestamp"].includes(key))
    .map(([key, value]: [string, PropertyDefinitionOutput]) => {
      return helper.accessor(`payload.${key}`, {
        header: () => (
          <HeaderCell
            content={
              value.display_name ?? value.relation_schema?.display_name ?? key
            }
            filterKey={key}
            filters={
              value.type === PropertyDefinitionOutputTypeEnum.ENUM
                ? filtering?.filters
                : undefined
            }
            value={value}
            onFilterChange={
              value.type === PropertyDefinitionOutputTypeEnum.ENUM
                ? filtering?.onFilterChange
                : undefined
            }
          />
        ),
        cell: (info) => {
          const cellValue = info.getValue();

          if (
            value.type === PropertyDefinitionOutputTypeEnum.DATETIME ||
            value.type === PropertyDefinitionOutputTypeEnum.DATE
          ) {
            return (
              <Cell info={info}>
                {formatDate(
                  cellValue,
                  value.type === PropertyDefinitionOutputTypeEnum.DATETIME
                    ? "MMM d, yyyy 'at' h:mm a"
                    : "MMM d, yyyy",
                )}
              </Cell>
            );
          }

          if (
            value.type === PropertyDefinitionOutputTypeEnum.NUMBER ||
            value.type === PropertyDefinitionOutputTypeEnum.INTEGER
          ) {
            return (
              <Cell className="justify-end" info={info}>
                {formatNumber(cellValue, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </Cell>
            );
          }

          if (value.type === PropertyDefinitionOutputTypeEnum.ENUM) {
            const parsedCellValue = parseJsonOrReturnAsIs(cellValue);
            const enumValue = value.enum_schema?.values.find(
              (v: any) => v.value === parsedCellValue,
            );
            if (!enumValue) return;

            return (
              <Cell info={info}>
                <EnumPill enumValue={enumValue} />
              </Cell>
            );
          }

          if (value.type === PropertyDefinitionOutputTypeEnum.RELATION) {
            const fallbackName = `${cellValue._id.slice(0, 4)}...${cellValue._id.slice(-4)}`;
            return (
              <Cell info={info}>
                <EntityDetailsWindowPill
                  entityIcon={
                    entityIcons[
                      value.relation_schema
                        ?.entity_type as keyof typeof entityIcons
                    ]
                  }
                  fullWidth={false}
                  id={cellValue._id}
                  name={fallbackName}
                  schemaId={cellValue._type}
                />
              </Cell>
            );
          }

          if (value.type === PropertyDefinitionOutputTypeEnum.BOOLEAN) {
            return (
              <Cell info={info}>{speakPythonPrimitive(String(cellValue))}</Cell>
            );
          }

          return (
            <Cell info={info}>
              <Tooltip placement="top" title={cellValue} asChild>
                <div className="truncate">{cellValue}</div>
              </Tooltip>
            </Cell>
          );
        },
        meta: {
          displayName:
            value.display_name ?? value.relation_schema?.display_name,
        },
        maxSize: 300,
      });
    });

  return [
    ...baseColumns,
    ...remainingColumns,
    helper.display({
      id: "actions",
      header: (info) => (
        <div className="w-13.5 flex justify-end pr-1.5">
          <ColumnVisibilityDropdown max={10} min={3} table={info.table} />
        </div>
      ),
      cell: (info) => (
        <EventActions
          id={info.row.original.payload.id}
          selectedEventId={selectedEventId}
        />
      ),
      size: 54,
    }),
  ];
};

const EventActions = ({
  id,
  selectedEventId,
}: {
  id: string;
  selectedEventId?: string | null;
}) => {
  return (
    <div className="w-13.5 group flex justify-end pr-1.5">
      <Tooltip placement="top" title="View Details">
        <span
          className={twJoin(
            "group-hover:visible",
            id !== selectedEventId && "invisible",
          )}
        >
          <Icon
            color={id === selectedEventId ? "text-indigo-600" : "text-gray-500"}
            dataLoc="event-view"
            icon={faEye}
            size="xs"
          />
        </span>
      </Tooltip>
    </div>
  );
};

export const EnumPill = ({ enumValue }: { enumValue: EnumValue }) => (
  <Pill size="sm" variant={enumValue.color || "gray"}>
    <Pill.Text>{enumValue.value}</Pill.Text>
  </Pill>
);
