import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { validate as validateUUID } from "uuid";

import { DecisionEnvironment } from "src/api/types";
import ManyToOneIcon from "src/assets/ManyToOneIcon.svg?react";
import OneToManyIcon from "src/assets/OneToManyIcon.svg?react";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { DataValue } from "src/base-components/DataList";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Cell, TableComp } from "src/base-components/Table";
import { Tooltip } from "src/design-system/Tooltip";
import { EntityDetailsWindowPill } from "src/entities/entityView/EntityDetailsWindowPill";
import { LinkedEntityPill } from "src/entities/entityView/LinkedEntityPill";
import {
  entityIcons,
  findSchema,
  getSchemaIcon,
  getTableData,
  propertyTypeIcons,
} from "src/entities/entityView/utils";
import {
  Cardinality,
  EntityFieldType,
  EntityResource,
  EntitySchemaResource,
  TableRow,
  TableValue,
  useEntity,
  useEntitySchemas,
} from "src/entities/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";

const getType = (info: CellContext<TableRow, TableValue>) =>
  info.row.original._type;

const renderByType = (
  type: EntityFieldType,
  value: string,
  entitySchema: string | undefined,
  isWindowPill: boolean,
) => {
  if (type === "relation" && entitySchema) {
    const Comp = isWindowPill ? EntityDetailsWindowPill : LinkedEntityPill;
    return (
      <div className="mr-1">
        <Comp
          entityIcon={entityIcons[entitySchema as keyof typeof entityIcons]}
          id={value}
          name={value}
          schemaId={entitySchema}
        />
      </div>
    );
  }

  if (validateUUID(value)) {
    return <CopyTextIcon value={value} />;
  }

  if (["enum", "tag"].includes(type)) {
    return (
      <div className="overflow-hidden">
        <Pill size="sm" variant="gray" fullWidth>
          <Pill.Text>{value}</Pill.Text>
        </Pill>
      </div>
    );
  }

  if (type === "string") {
    return <p>{value}</p>;
  }

  return <DataValue field="" value={value} />;
};

export const renderValue = (
  type: EntityFieldType,
  value: TableValue,
  entitySchema: string | undefined,
  isWindowPill: boolean,
) => {
  if (Array.isArray(value)) {
    return value.map((val) =>
      renderByType(type, val, entitySchema, isWindowPill),
    );
  }

  return renderByType(type, value, entitySchema, isWindowPill);
};

export const TypeIcon = ({
  type,
  cardinality,
}: {
  type: EntityFieldType;
  cardinality: Cardinality | undefined;
}) => {
  if (type === "relation") {
    return (
      <div className="mr-0.5 shrink-0">
        {cardinality === "1" ? <OneToManyIcon /> : <ManyToOneIcon />}
      </div>
    );
  }

  return (
    <Icon
      color="text-gray-500"
      icon={propertyTypeIcons[type] ?? faQuestion}
      size="2xs"
    />
  );
};

const columnHelper = createColumnHelper<TableRow>();

const getColumns = (isWindowPill: boolean) => [
  columnHelper.accessor("_display_name", {
    header: () => null,
    size: 129,
    maxSize: 129,
    cell: (info: CellContext<TableRow, TableValue>) => {
      const type = getType(info);
      const cardinality = info.row.original._cardinality;
      return (
        <Cell info={info}>
          <TypeIcon cardinality={cardinality} type={type} />
          <Tooltip title={info.row.original._display_name} asChild>
            <p className="truncate capitalize">
              {info.row.original._display_name}
            </p>
          </Tooltip>
        </Cell>
      );
    },
  }),
  columnHelper.accessor("value", {
    header: () => null,
    size: 189,
    maxSize: 189,
    cell: (info: CellContext<TableRow, TableValue>) => (
      <Cell info={info}>
        {renderValue(
          getType(info),
          info.row.original.value,
          info.row.original._rel_schema,
          isWindowPill,
        )}
      </Cell>
    ),
  }),
];

type EntityDetailsSidePaneProps = {
  entityId: string;
  schema: string;
  showHeader?: boolean;
  isWindowPill?: boolean;
};

type EntityDetailsHeadeProps = {
  entitySchema: EntitySchemaResource;
  entity: EntityResource;
};

const EntityDetailsHeader: React.FC<EntityDetailsHeadeProps> = ({
  entitySchema,
  entity,
}) => {
  return (
    <div className="flex gap-3">
      <span className="rounded-lg border border-gray-200 p-2">
        <Icon
          color="text-gray-600"
          icon={getSchemaIcon(entitySchema)}
          size="md"
        />
      </span>
      <div>
        <p className="font-inter-normal-13px">
          {entity?.properties?.[entitySchema._primary_property]}
        </p>
        <p className="text-gray-500 font-inter-medium-11px">
          {entity?.properties?.[entitySchema._secondary_property]}
        </p>
      </div>
    </div>
  );
};

export const EntityDetailsSidePane: React.FC<EntityDetailsSidePaneProps> = ({
  entityId,
  schema,
  showHeader = true,
  isWindowPill = false,
}) => {
  const { workspace } = useWorkspaceContext();

  const { data: entitySchemas, isLoading: isLoadingSchemas } = useEntitySchemas(
    {
      baseUrl: workspace.base_url ?? "",
    },
  );
  const { data: entityData, isLoading: isLoadingEntity } = useEntity({
    schema,
    env: DecisionEnvironment.SANDBOX,
    baseUrl: workspace.base_url ?? "",
    entityId,
  });

  const [tableData, entitySchema] = useMemo(() => {
    const entitySchema = findSchema(schema, entitySchemas);

    return [getTableData(entitySchema, entityData), entitySchema];
  }, [schema, entityData, entitySchemas]);

  const columns = useMemo(() => getColumns(isWindowPill), [isWindowPill]);

  return (
    <div>
      {showHeader && entitySchema && entityData && (
        <>
          <EntityDetailsHeader
            entity={entityData}
            entitySchema={entitySchema}
          />
          <Divider spacing="my-4" />
        </>
      )}
      <TableComp
        columns={columns}
        data={tableData}
        frameClassName="[&_thead_tr]:hidden [&_table]:border-b-0"
        isLoading={isLoadingSchemas || isLoadingEntity}
        variant="compact"
      />
    </div>
  );
};
