import {
  ColumnDef,
  HeaderGroup,
  Row,
  Table,
  getCoreRowModel,
  useReactTable,
  flexRender,
  RowData,
  TableOptions,
  HeaderContext,
  CellContext,
  getFilteredRowModel,
  VisibilityState,
} from "@tanstack/react-table";
import { noop, omit, times } from "lodash";
import React, { useRef, useState } from "react";
import { VirtualItem, useVirtual } from "react-virtual";
import { twJoin, twMerge } from "tailwind-merge";
import { useDebouncedCallback } from "use-debounce";
import { useLocalStorage } from "usehooks-ts";

import { ColumnVisibilityDropdown as ColumnVisibilityDropdownBase } from "src/base-components/ColumnVisibilityDropdown";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";

type TableActions = Record<string, (...args: any[]) => void>;
type TableVariant = "default" | "compact";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface TableMeta<TData extends RowData> {
    actions?: TableActions;
    variant?: TableVariant;
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    displayName?: string | null;
  }
}

type RowProps = {
  classNameOverrides?: string;
  [key: string]: any;
};
type HeaderGroupProps = {
  classNameOverrides?: string;
  [key: string]: any;
};

type PropsT<T extends object, A extends TableActions> = {
  data: T[];
  columns: ColumnDef<T, any>[];
  frameClassName?: string;
  rowClassName?: string;
  rowPropsGetter?: (rowData: Row<T>) => RowProps;
  headerPropsGetter?: (headerProps: HeaderGroup<T>) => HeaderGroupProps;
  renderTableHeader?: (tableInstance: Table<T>) => React.ReactNode;
  noScroll?: boolean;
  dataLoc?: string;
  isLoading?: boolean;
  actions?: A;
  getRowId?: TableOptions<T>["getRowId"];
  loadingRowsCount?: number;
  onScrolledToTheBottom?: () => void;
  isFetchingNextPage?: boolean;
  variant?: TableVariant;
  globalFilter?: string;
  // Used to store the visual state of the table
  uiStoreKey?: string;
};

const ROW_CLASS_NAMES = "[&:not(:last-child)]:border-b group border-gray-100";
const HEADER_CLASS_NAMES =
  "sticky top-0 bg-opacity-100 bg-white border-b border-gray-100 font-inter-medium-12px text-left";

export const TableComp = <T extends object, A extends TableActions>({
  data,
  columns,
  frameClassName,
  rowClassName,
  rowPropsGetter,
  headerPropsGetter,
  noScroll = false,
  isLoading = false,
  loadingRowsCount = 10,
  dataLoc,
  actions,
  getRowId,
  onScrolledToTheBottom,
  isFetchingNextPage,
  variant = "default",
  globalFilter,
  uiStoreKey,
}: PropsT<T, A>) => {
  const [columnVisibility, setColumnVisibility] =
    useVisibilityStore(uiStoreKey);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: { size: undefined },
    meta: { actions, variant },
    getRowId,
    onColumnVisibilityChange: setColumnVisibility,
    state: { globalFilter, columnVisibility },
    getFilteredRowModel: globalFilter ? getFilteredRowModel() : undefined,
  });
  const { getHeaderGroups } = tableInstance;

  const headerGroups = getHeaderGroups();
  const rows = tableInstance.getRowModel().rows;

  const renderHeaderGroup = (headerGroup: HeaderGroup<T>) => {
    const headerProps = headerPropsGetter?.(headerGroup);

    return (
      <tr
        key={headerGroup.id}
        className={twMerge(HEADER_CLASS_NAMES, headerProps?.classNameOverrides)}
        {...omit(headerProps, "classNameOverrides")}
      >
        {headerGroup.headers.map((column) => {
          const ctx = column.getContext();

          return (
            <th
              key={column.id}
              className="p-0"
              colSpan={column.colSpan}
              style={{
                minWidth: ctx.column.columnDef.minSize,
                maxWidth: ctx.column.columnDef.maxSize,
                width: ctx.column.columnDef.size ? column.getSize() : undefined,
              }}
            >
              {flexRender(column.column.columnDef.header, column.getContext())}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderRow = (row: Row<T>, virtualRow: VirtualItem) => {
    const rowProps = rowPropsGetter?.(row);
    return (
      <tr
        key={row.id}
        ref={virtualRow.measureRef}
        className={twMerge(
          ROW_CLASS_NAMES,
          rowClassName,
          rowProps?.classNameOverrides,
        )}
        {...omit(rowProps, "classNameOverrides")}
      >
        {row.getVisibleCells().map((cell) => {
          return (
            // h-[inherit] & p-0 allow the content inside td to take full width/height
            <td
              key={cell.id}
              className="h-[inherit] p-0"
              style={{
                minWidth: cell.column.columnDef.minSize,
                maxWidth: cell.column.columnDef.maxSize,
                width: cell.column.columnDef.size
                  ? cell.column.getSize()
                  : undefined,
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderLoadingRow = (index: number) => {
    return (
      <tr key={index} className={twMerge(ROW_CLASS_NAMES, rowClassName)}>
        {columns.map((column, i) => {
          return (
            <td key={`${column.id}_${i}`} className="relative h-[inherit] p-0">
              <div
                className={twJoin(
                  "flex items-center p-1.5",
                  variant === "compact" ? "min-h-[32px]" : "min-h-[36px]",
                )}
              >
                <SkeletonPlaceholder height="h-5" width="w-4/5" />
              </div>
            </td>
          );
        })}
      </tr>
    );
  };

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize: totalRowSize } = rowVirtualizer;
  // Dynamic top and botton padding is added to the table content to maintain the correct scroll bar size and position
  const paddingTop = virtualRows?.[0]?.start || 0;
  const paddingBottom =
    totalRowSize - (virtualRows?.[virtualRows.length - 1]?.end || 0);

  const debouncedOnScrolledToTheBottom = useDebouncedCallback(
    onScrolledToTheBottom ?? noop,
    1000,
    {
      leading: true,
      trailing: false,
    },
  );

  const onScroll = (containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (scrollHeight - scrollTop - clientHeight < 1000) {
        debouncedOnScrolledToTheBottom();
      }
    }
  };

  return (
    <div
      ref={tableContainerRef}
      className={twMerge(
        "h-full",
        !noScroll && "decideScrollbar overflow-auto",
        frameClassName,
      )}
      data-loc={dataLoc}
      onScroll={(e) =>
        onScrolledToTheBottom ? onScroll(e.target as HTMLDivElement) : undefined
      }
    >
      <table
        className={twJoin(
          "w-full table-auto border-collapse border-spacing-0 text-gray-800",
          variant === "compact" && "border-b border-gray-100",
        )}
      >
        <thead>{headerGroups.map(renderHeaderGroup)}</thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {isLoading
            ? times(loadingRowsCount, (i) => renderLoadingRow(i))
            : virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index];
                return renderRow(row, virtualRow);
              })}
          {isFetchingNextPage &&
            times(loadingRowsCount, (i) => renderLoadingRow(i))}
          {!isLoading && paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export const Header = <D extends object, V>({
  children,
  info,
  dataLoc,
}: {
  children?: React.ReactNode;
  info: HeaderContext<D, V>;
  dataLoc?: string;
}) => {
  if (!children) {
    // If no children we consider it as a placeholder and return null
    return null;
  }

  const variant = info.table.options.meta?.variant;

  return (
    <div
      className={twJoin(
        "flex items-center",
        variant === "compact" && "h-8 pl-1.5 pr-2",
      )}
      data-loc={dataLoc}
    >
      <div className="text-gray-800 font-inter-medium-12px">{children}</div>
    </div>
  );
};

export const Cell = <D extends object, V>({
  children,
  className,
  dataLoc,
  info,
}: {
  children?: React.ReactNode;
  className?: string;
  dataLoc?: string;
  info: CellContext<D, V>;
}) => {
  const variant = info.table.options.meta?.variant;
  return (
    <div
      className={twJoin(
        "flex items-center truncate text-gray-800 font-inter-normal-12px",
        variant === "compact" && "h-8 py-1.5 pl-1.5 pr-2",
        className,
      )}
      data-loc={dataLoc}
    >
      {children}
    </div>
  );
};

/**
 * By default, this component allows to hide/show columns for given table.
 * But it is not stored anywhere.
 * If uiStoreKey is provided to the table component,
 * it will use the local storage to store the visibility state.
 */
export const ColumnVisibilityDropdown = <TData extends RowData>({
  dataLoc,
  table,
  min,
  max,
}: {
  dataLoc?: string;
  table: Table<TData>;
  min?: number;
  max?: number;
}) => {
  const columns = table
    .getAllLeafColumns()
    // Skip display columns, use only columns with actual data
    .filter((column) => column.accessorFn);
  const visibleColumns = table.getVisibleLeafColumns();
  const onChange = (value: string[]) => {
    table.setColumnVisibility(
      columns.reduce((acc, col) => {
        acc[col.id] = value.includes(col.id);
        return acc;
      }, {} as VisibilityState),
    );
  };

  return (
    <ColumnVisibilityDropdownBase
      dataLoc={dataLoc}
      items={columns.map((column) => ({
        label: column.columnDef.meta?.displayName ?? column.id,
        value: column.id,
        disabled: !column.getCanHide()
          ? "This column is required and cannot be hidden"
          : undefined,
      }))}
      max={max}
      min={min}
      value={visibleColumns.map((column) => column.id)}
      onChange={onChange}
      onReset={table.resetColumnVisibility}
    />
  );
};

const useVisibilityStore = (key: string | undefined) => {
  const NOOP_STORE_KEY = "NOOP_STORE_KEY";
  const visibilityLocalStorage = useLocalStorage(key ?? NOOP_STORE_KEY, {});
  const visibilityLocalState = useState({});

  if (!key) {
    // If no key is provided, just use internal table state
    return visibilityLocalState;
  }

  return visibilityLocalStorage;
};
