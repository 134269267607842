import {
  faArrowLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { Cell } from "@tanstack/react-table";
import { uniqBy } from "lodash";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { GenericObjectT } from "src/api/flowTypes";
import { useFlows } from "src/api/queries";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { Pill } from "src/base-components/Pill";
import { isValidEntityId } from "src/decisionsOverview/utils";
import { DecisionStatusCode } from "src/flow/decisionHistory/SharedStatusColumn";
import { useAuthoringContext } from "src/router/routerContextHooks";
import {
  getBaseUrl,
  getUrlToDecisionsOverview,
  getUrlToHistoricDecisionTrace,
} from "src/router/urls";
import { getDateOnly } from "src/utils/datetime";
import { useDecisionHistoryPaneEnv } from "src/versionDecisionHistory/useDecisionHistoryPaneEnv";

type RowMenuProps = {
  isPowertoolsEnabled: boolean;
  onAddTestCase: (decisionId: string) => void;
  isSelected: boolean;
  cell: Cell<DecisionHistoryRecordV2<GenericObjectT>, any>;
  xrayTraceURL?: string | null;
  currentDecision?: DecisionHistoryRecordV2<GenericObjectT> | null;
};

export const RowMenu: React.FC<RowMenuProps> = ({
  isPowertoolsEnabled,
  onAddTestCase,
  isSelected,
  cell,
  xrayTraceURL,
  currentDecision,
}) => {
  const { selectedEnv: decisionEnv } = useDecisionHistoryPaneEnv();
  const [selectedFlowId, setSelectedFlowId] = useState<string | null>(null);
  const {
    orgId,
    workspace,
    flow: flowWithoutChildFlows,
  } = useAuthoringContext();
  const flows = useFlows({ workspaceId: workspace.id });
  const flow =
    flows.data?.find((f) => f.id === flowWithoutChildFlows.id) ?? null;

  const isPending =
    cell.row.original.status_code === ("202" as DecisionStatusCode);
  const calledFlowVersionIds = isSelected
    ? Object.keys(currentDecision?.linked_decisions ?? {})
    : [];
  const childFlows = uniqBy(
    (flow?.child_flows ?? []).filter((childFlow) =>
      childFlow.versions?.some((version) =>
        calledFlowVersionIds.includes(version.id),
      ),
    ),
    "id",
  );

  const controlElements: OptionsDropdownElement[] = (() => {
    const selectedFlow =
      selectedFlowId && childFlows.find((flow) => flow.id === selectedFlowId);
    if (currentDecision && selectedFlow && selectedFlow.versions) {
      return [
        {
          key: "nav",
          nav: selectedFlow.name,
          icon: faArrowLeft,
          action: () => setSelectedFlowId(null),
        },
        ...selectedFlow.versions
          .filter((v) => calledFlowVersionIds.includes(v.id))
          .map((version) => ({
            key: version.name,
            action: () => {
              setSelectedFlowId(null);
              const url = getUrlToHistoricDecisionTrace({
                orgId,
                wsId: workspace.id,
                decisionId: currentDecision.id,
                decisionEnv,
                flowId: selectedFlow.id,
                versionId: version.id,
                from: getDateOnly(currentDecision.start_time),
                to: getDateOnly(currentDecision.end_time),
              });
              window.open(url, "_blank");
            },
            suffix: (
              <span className="opacity-0 group-hover:opacity-100">
                <Icon
                  color="text-gray-500"
                  icon={faUpRightFromSquare}
                  size="sm"
                />
              </span>
            ),
          })),
      ];
    }

    const elements: OptionsDropdownElement[] = isPending
      ? []
      : [
          {
            key: "Turn into test case",
            action: () => onAddTestCase(cell.row.original.id),
          },
        ];

    if (isValidEntityId(cell.row.original.entity_id)) {
      elements.push(MENU_DIVIDER);
      elements.push({
        key: "nav",
        nav: "View related decisions",
        subtitle: (
          <>
            Show all decisions with the same Entity ID{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text fontType="code">
                {cell.row.original.entity_id}
              </Pill.Text>
            </Pill>
          </>
        ),
        action: () => {
          const url =
            getBaseUrl() +
            getUrlToDecisionsOverview(orgId, workspace.id, {
              entityId: cell.row.original.entity_id,
            });
          window.open(url, "_blank");
        },
      });
    }

    if (isPowertoolsEnabled) {
      elements.push({
        key: "Open Trace",
        disabled: !(Boolean(xrayTraceURL) && isSelected),
        action: () => window.open(xrayTraceURL ?? "", "_blank"),
      });
    }

    if (isSelected && calledFlowVersionIds.length > 0) {
      if (!isPending) {
        elements.push(MENU_DIVIDER);
      }
      elements.push({
        key: "header",
        header: "Child Flow Decisions",
      });

      childFlows.forEach((flow) => {
        elements.push({
          key: "nav",
          nav: flow.name,
          action: () => {
            setSelectedFlowId(flow.id);
          },
          suffix: (
            <span className="opacity-0 group-hover:opacity-100">
              <Icon color="text-gray-500" icon={faChevronRight} size="sm" />
            </span>
          ),
        });
      });
    }
    return elements;
  })();

  return (
    <EllipsisOptionsDropdown
      buttonClassName={twJoin(
        "ml-1 text-gray-500",
        !isSelected && "opacity-0 group-hover/row:opacity-100",
      )}
      elements={controlElements}
      iconSize="sm"
      placement="bottom-start"
    />
  );
};
