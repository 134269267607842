import TaktileLogo from "src/assets/Taktile.svg?react";
import { OrgLogo } from "src/base-components/OrgLogo";
import { useWorkspaceAndOrg } from "src/utils/useCurrentWorkspace";

type Props = {
  dataLoc: string;
};

export const AppHeaderLogo: React.FC<Props> = ({ dataLoc }) => {
  const { org, isLoading } = useWorkspaceAndOrg();

  if (org && org.whitelabel) {
    return <OrgLogo org={org} size="medium" noBorder />;
  }
  if (!isLoading) {
    return <TaktileLogo data-loc={dataLoc} />;
  }

  return <></>;
};
