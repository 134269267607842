import {
  faCalculatorSimple,
  faHashtag,
  faMinus,
  faText,
} from "@fortawesome/pro-regular-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { addSeconds } from "date-fns";
import React from "react";

import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Cell, Header, TableComp } from "src/base-components/Table";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { DisplayDescriptionCell } from "src/featureCatalogue/Features/Features";
import { formatDuration } from "src/featureCatalogue/utils";
import { WhitePane } from "src/jobs/common/WhitePane";
import { formatDate } from "src/utils/datetime";

type FeatureRow = {
  key: string;
  type: string;
  name: string;
  description: string;
  query: {
    event: string;
    window_hop: number;
    window_length: number;
  };
  value: number;
  timestamp: string;
};

const columnHelper = createColumnHelper<FeatureRow>();

const COLUMNS = [
  columnHelper.accessor("name", {
    header: (info) => <Header info={info}>Feature name</Header>,
    cell: (info) => {
      const name = info.getValue();
      return (
        <Cell className="gap-x-0.5" info={info}>
          <Icon
            color="text-gray-500"
            icon={
              info.row.original.type === "integer" ||
              info.row.original.type === "number"
                ? faHashtag
                : info.row.original.type === "string"
                  ? faText
                  : faMinus
            }
            size="xs"
          />
          <Tooltip
            disabled={!name || name.length <= 50}
            placement="top"
            title={name}
            asChild
          >
            <div className="truncate">{info.getValue()}</div>
          </Tooltip>
        </Cell>
      );
    },
    size: 250,
    maxSize: 250,
  }),
  columnHelper.accessor("description", {
    header: (info) => <Header info={info}>Description</Header>,
    cell: (info) => <DisplayDescriptionCell info={info as any} />,
    size: 300,
    maxSize: 300,
  }),
  columnHelper.accessor("key", {
    header: (info) => <Header info={info}>Key</Header>,
    cell: (info) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Text>{info.getValue()}</Pill.Text>
        </Pill>
      </Cell>
    ),
  }),
  {
    accessorKey: "query.event",
    header: (info: any) => <Header info={info}>Event</Header>,
    cell: (info: any) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Icon icon={faCalculatorSimple} />
          <Pill.Text>{info.getValue()}</Pill.Text>
        </Pill>
      </Cell>
    ),
  },
  {
    accessorKey: "query.window_hop",
    header: (info: any) => <Header info={info}>Time window</Header>,
    cell: (info: any) => (
      <Cell info={info}>{formatDuration(info.getValue())}</Cell>
    ),
    size: 100,
  },
  {
    accessorKey: "timestamp",
    header: (info: any) => <Header info={info}>Last Updated</Header>,
    cell: (info: any) => {
      const lastUpdateTimestamp = info.getValue();
      const windowHop = info.row.original.query.window_hop;
      const nextUpdate = addSeconds(new Date(lastUpdateTimestamp), windowHop);

      return (
        <Cell info={info}>
          <Tooltip
            placement="bottom"
            title={`Next update on ${formatDate(nextUpdate, "MMM d, yyyy 'at' h:mm a")}`}
          >
            <div>
              {formatDate(lastUpdateTimestamp, "MMM d, yyyy 'at' h:mm a")}
            </div>
          </Tooltip>
        </Cell>
      );
    },
    size: 150,
  },
  {
    accessorKey: "value",
    header: (info: any) => <Header info={info}>Value</Header>,
    cell: (info: any) => (
      <Cell className="justify-end text-indigo-600 font-code-12" info={info}>
        {info.getValue()}
      </Cell>
    ),
  },
];

const features = [
  {
    key: "total_transaction_amount",
    type: "string",
    name: "Total Transaction Amount",
    description:
      "1. **New maximum** _description_\n2. **New maximum** _description_",
    query: {
      event: "transaction",
      window_hop: 86400,
      window_length: 10,
    },
    value: 120.0,
    timestamp: "2024-01-01T00:00Z",
  },
  {
    key: "average_transaction_amount",
    type: "number",
    name: "Average Transaction Amount",
    description:
      "Average transaction amount for a customer Average transaction amount for a customer",
    query: {
      event: "transaction",
      window_hop: 14400,
      window_length: 10,
    },
    value: 100.0,
    timestamp: "2024-01-01T00:00Z",
  },
  {
    key: "transaction_frequency",
    type: "integer",
    name: "Transaction Frequency",
    description: "Number of transactions performed within the time window",
    query: {
      event: "transaction",
      window_hop: 3600,
      window_length: 24,
    },
    value: 15,
    timestamp: "2024-01-01T00:00Z",
  },
  {
    key: "max_single_transaction",
    type: "number",
    name: "Maximum Single Transaction",
    description: "Highest value of a single transaction in the time window",
    query: {
      event: "transaction",
      window_hop: 43200,
      window_length: 30,
    },
    value: 999.99,
    timestamp: "2024-01-01T00:00Z",
  },
  {
    key: "merchant_category",
    type: "string",
    name: "Most Common Merchant Category",
    description: "Most frequent merchant category in recent transactions",
    query: {
      event: "transaction",
      window_hop: 86400,
      window_length: 7,
    },
    value: 200,
    timestamp: "2024-01-01T00:00Z",
  },
  {
    key: "transaction_success_rate",
    type: "number",
    name: "Transaction Success Rate",
    description: "Percentage of successful transactions out of total attempts",
    query: {
      event: "transaction",
      window_hop: 7200,
      window_length: 48,
    },
    value: 98,
    timestamp: "2024-01-01T00:00Z",
  },
  {
    key: "unique_merchants",
    type: "integer",
    name: "Unique Merchants Count",
    description: "Number of distinct merchants transacted with",
    query: {
      event: "transaction",
      window_hop: 21600,
      window_length: 72,
    },
    value: 8,
    timestamp: "2024-01-01T00:00Z",
  },
];

export const Features: React.FC<{}> = () => {
  return (
    <div className="p-5">
      <WhitePane>
        <div className="flex w-full flex-col gap-y-4">
          {features.length === 0 ? (
            <EmptyState
              dataLoc="features-empty-state"
              description="Features help you detect patterns and analyze behavior across your entities and events."
              headline="No features configured yet"
              icon={faCalculatorSimple}
            />
          ) : (
            <TableComp
              columns={COLUMNS}
              data={features}
              dataLoc="features-list"
              frameClassName="w-full p-4"
              variant="compact"
            />
          )}
        </div>
      </WhitePane>
    </div>
  );
};
