import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { omit } from "lodash";

import { historyApi } from "src/api/endpoints";
import {
  decisionHistoryApiRoute,
  decisionHistoryV3ApiRoute,
} from "src/api/exporterApi";
import { GenericObjectT } from "src/api/flowTypes";
import {
  DecideDecisionError,
  DecideDecisionSuccess,
  DecisionEnvironment,
  DecisionHistoryError,
} from "src/api/types";
import { DateRange } from "src/base-components/DateRangePicker";
import {
  DecisionsApiGetDecisionDecisionsDecisionIdGetRequest,
  DecisionsApiListDecisionsDecisionsGetRequest,
  HistoricalDecisionsResponseV3,
  HistoricalDecisionV3,
} from "src/clients/history-v3";
import { EQUALITY_OPERATORS } from "src/constants/operators";
import { JSONValue } from "src/datasets/DatasetTable/types";
import { DecisionStatusCode } from "src/flow/decisionHistory/SharedStatusColumn";

export type DecisionHistorySuccessResponse<
  ResponseData extends GenericObjectT = GenericObjectT,
> = Pick<DecideDecisionSuccess<ResponseData>, "data" | "metadata" | "status">;

export type DecisionHistoryErrorResponse = Pick<
  DecideDecisionError,
  "detail" | "metadata" | "status"
>;

export type EqualityOperator =
  (typeof EQUALITY_OPERATORS)[keyof typeof EQUALITY_OPERATORS]["code"];

export type SingleOutcomeFilter = {
  outcome_type_key: string;
  filter: {
    property: string;
    operator: EqualityOperator;
    value: string | number | boolean;
  };
};

export type MultipleOutcomeFilter = {
  outcome_type_key: string;
  filter: {
    property: string;
    operator: "in" | "not_in";
    values: (string | number | boolean)[];
  };
};

export type OutcomePresenceFilter = {
  outcome_type_key: string;
  filter: "exists" | "not_exists";
};

export type DecisionsOutcomeFilter =
  | OutcomePresenceFilter
  | SingleOutcomeFilter
  | MultipleOutcomeFilter;

export type DecisionHistoryIntegrationDuration = {
  type: string;
  node_id: string | null;
  request_id: string;
  provider: string;
  resource: string;
  provider_response_time: number;
  provider_response_status: "error" | "success" | "cache" | "timeout";
  request_attempts: number;
};

export type DecisionHistoryRecordV2<
  ResponseData extends GenericObjectT = GenericObjectT,
> =
  // To be able to discriminate the response type by the is_error field
  (
    | {
        is_error: false;
        response: DecisionHistorySuccessResponse<ResponseData>;
      }
    | { is_error: true; response: DecisionHistoryErrorResponse }
  ) & {
    id: string;
    flow: {
      id: string;
      slug: string;
      version_id: string;
      version_name: string;
    };
    entity_id: string;
    is_error: boolean;
    error: DecisionHistoryError | null;
    is_sandbox: boolean;
    duration_in_milliseconds: number;
    start_time: string; // ISO
    end_time: string; // ISO
    masked_fields: unknown;
    request: unknown;
    /**
     * Only includes data if the endpoint is called with the option to include node results
     */
    node_results: {
      nodes: {
        [nodeId: string]: {
          id: string;
          title: string;
          tracing: unknown;
          data: GenericObjectT;
        };
      };
      node_execution_metadata?: {
        [nodeId: string]: {
          variables?: {
            [key: string]: {
              value: JSONValue;
              type_: string | null | undefined;
            };
          };
        };
      };
    };
    external_resources: unknown;
    flow_version_etag: string;
    xray_trace_id?: string | null;
    status_code: string;
    parent_flow_id: string | null;
    parent_flow_version_id: string | null;
    parent_decision_id: string | null;
    linked_decisions: null | Record<
      string,
      Record<
        string,
        {
          decision_id: string;
          originating_node_id: string;
          is_called_from_loop?: boolean;
          loop_index?: number;
        }
      >
    >;
    durations?: Array<DecisionHistoryIntegrationDuration>;
  };

export type DecisionHistoryRecordV3 = (
  | {
      is_error: false;
    }
  | { is_error: true; response: DecisionHistoryErrorResponse }
) &
  HistoricalDecisionV3;

type OriginFilterOptions = "api_call" | "job" | "flow";

export type DecisionHistoryFiltersV2 = {
  timeWindow?: DateRange;
  environment?: DecisionEnvironment;
  fields: Partial<{
    status_code: DecisionStatusCode;
    flow_version_id: string;
    entity_id: string | null;
    parent_decision_id: string | null;
    entity_or_parent_decision_id: string | null;
    parent_flow_id: string;
    parent_flow_version_id: string;
    job_id: string;
    job_run_id: string;
    origin: OriginFilterOptions;
  }>;
};

export type GetDecisionsParams = {
  baseUrl: string;
  flowSlug: string;
  filters: DecisionHistoryFiltersV2;
  limit: number;
  include_node_results?: boolean;
  include_request_data?: boolean;
  include_response_data?: boolean;
  include_integration_durations?: boolean;
};

export type DecisionHistoryPage<
  ResponseData extends GenericObjectT = GenericObjectT,
> = {
  data: DecisionHistoryRecordV2<ResponseData>[];
  page_min_timestamp: string;
};

type DecisionHistoryQueryParams = Omit<GetDecisionsParams, "limit">;
type DecisionHistoryQueryKeyParams = Omit<
  DecisionHistoryQueryParams,
  "filters"
> & { filters: DecisionHistoryFiltersV2 | null };

export type DevisionHistoryV3GetRequest = Omit<
  DecisionsApiListDecisionsDecisionsGetRequest,
  "outcomeFilters"
> & {
  outcomeFilters?: DecisionsOutcomeFilter[];
};

type DecisionHistoryV3QueryParams = Omit<
  DevisionHistoryV3GetRequest,
  "limit"
> & {
  enabled?: boolean;
  baseUrl: string;
};

export const buildHistoryDecisionV2QueryKey = ({
  baseUrl,
  flowSlug,
  include_node_results,
  include_integration_durations,
  include_request_data,
  include_response_data,
  filters,
}: DecisionHistoryQueryKeyParams) => {
  const baseKey = [
    "decisions",
    baseUrl,
    flowSlug,
    include_node_results,
    include_integration_durations,
    include_request_data,
    include_response_data,
  ];

  return [...baseKey, ...(filters ? [filters] : [])];
};

export const decisionsV3BaseKey = ["decision", "v3"];

export const buildHistoryDecisionsV3QueryKey = (
  params: DecisionHistoryV3QueryParams,
) => {
  return [
    ...decisionsV3BaseKey,
    ...(Object.keys(params).sort() as (keyof DecisionHistoryV3QueryParams)[])
      .filter((k) => k !== "pageToken" && k !== "enabled")
      .map((k) => params[k]),
  ];
};

export const useHistoryDecisionsV2 = <
  ResponseData extends GenericObjectT = GenericObjectT,
>(
  params: DecisionHistoryQueryParams & { enabled?: boolean },
) => {
  const env: "sandbox" | "prod" | "all" =
    params.filters.environment === undefined
      ? "all"
      : params.filters.environment === DecisionEnvironment.LIVE
        ? "prod"
        : "sandbox";
  return useInfiniteQuery<DecisionHistoryPage<ResponseData>, Error>({
    queryKey: buildHistoryDecisionV2QueryKey(params),
    getNextPageParam: (lastPage) => {
      return lastPage.data.length > 0 ? lastPage.page_min_timestamp : undefined;
    },
    queryFn: async ({
      pageParam = undefined,
    }): Promise<DecisionHistoryPage<ResponseData>> => {
      const response = await decisionHistoryApiRoute.get<{
        decisions: DecisionHistoryRecordV2<ResponseData>[];
        page_max_timestamp: string;
        page_min_timestamp: string;
      }>("/decisions", {
        baseURL: params.baseUrl,
        params: {
          flow_slug: params.flowSlug,
          before_timestamp:
            pageParam ?? params.filters.timeWindow?.to?.toISOString(),
          after_timestamp: params.filters.timeWindow?.from?.toISOString(),
          environment: env,
          limit: 100,
          only_finalized_decisions: false,
          include_extra_fields: true,
          include_node_results: params.include_node_results,
          include_request_data: params.include_request_data,
          include_response_data: params.include_response_data,
          ...params.filters.fields,
        },
      });
      return {
        data: response.data.decisions,
        page_min_timestamp: response.data.page_min_timestamp,
      };
    },
    staleTime: 0,
    // If we don't refetch on mount, changing the filters may show out of date data
    refetchOnMount: true,
    enabled: params.enabled,
  });
};

export const useHistoryDecisionsV3 = (params: DecisionHistoryV3QueryParams) => {
  if (params.flowIds && params.flowVersionIds) {
    // Bugfix for weird behaviour where flowVersionIds matching stops working
    // when flowIds is also set
    params = {
      ...params,
      flowIds: undefined,
    };
  }

  if (params.outcomeFilters?.length === 0) {
    params.outcomeFilters = undefined;
  }

  return useInfiniteQuery<HistoricalDecisionsResponseV3, Error>({
    queryKey: buildHistoryDecisionsV3QueryKey(params),
    getNextPageParam: (lastPage) => {
      return lastPage.decisions.length > 0
        ? lastPage.next_page_token
        : undefined;
    },
    queryFn: async ({
      pageParam = undefined,
    }): Promise<HistoricalDecisionsResponseV3> => {
      const response = await historyApi(
        params.baseUrl,
      ).listDecisionsDecisionsGet({
        ...params,
        ...(pageParam && { pageToken: pageParam }),
        ...(Array.isArray(params.outcomeFilters) && {
          outcomeFilters: btoa(JSON.stringify(params.outcomeFilters)),
        }),
      });
      return response.data;
    },
    staleTime: 0,
    // If we don't refetch on mount, changing the filters may show out of date data
    refetchOnMount: true,
    enabled: params.enabled,
  });
};

export type GetDecisionParams = {
  baseUrl: string;
  decisionId: string;
  includeExtraFields?: boolean;
  signal?: AbortSignal;
  includeNodeResultsData?: boolean;
  includeLinkedDecisions?: boolean;
};

export const getHistoryDecision = async <
  ResponseData extends GenericObjectT = GenericObjectT,
>(
  params: GetDecisionParams,
) =>
  await decisionHistoryApiRoute.get<DecisionHistoryRecordV2<ResponseData>>(
    `/decisions/${params.decisionId}`,
    {
      baseURL: params.baseUrl,
      params: {
        include_extra_fields: params.includeExtraFields || undefined,
        include_linked_decisions: params.includeLinkedDecisions !== false,
        include_node_results_data: params.includeNodeResultsData !== false,
        include_integration_durations: true,
      },
      signal: params.signal,
    },
  );
export const NO_DECISION_FOUND = "noneFound" as const;
export const FLOW_MISSMATCH = "flowMissmatch" as const;
export const VERSION_MISSMATCH = "versionMissmatch" as const;
export const ENVIRONMENT_MISSMATCH_IS_PROD = "isProdDecision" as const;
export const ENVIRONMENT_MISSMATCH_IS_SANDBOX = "isSandboxDecision" as const;
export type SingleDecisionQueryErrorReason =
  | typeof NO_DECISION_FOUND
  | typeof FLOW_MISSMATCH
  | typeof VERSION_MISSMATCH
  | typeof ENVIRONMENT_MISSMATCH_IS_PROD
  | typeof ENVIRONMENT_MISSMATCH_IS_SANDBOX;

export type SingleDecisionEnvelope<
  T extends DecisionHistoryRecordV2 | HistoricalDecisionV3,
> =
  | {
      isQueryError: true;
      reason: SingleDecisionQueryErrorReason;
    }
  | {
      isQueryError: false;
      decision: T;
    };
type DecisionMatchParams = {
  versionIdToMatch?: string;
  flowIdToMatch?: string;
  environmentToMatch?: DecisionEnvironment;
  initialData?: SingleDecisionEnvelope<DecisionHistoryRecordV2>;
};

export const useHistoryDecisionV2 = <
  ResponseData extends GenericObjectT = GenericObjectT,
>(
  params: GetDecisionParams & DecisionMatchParams,
) => {
  return useQuery<SingleDecisionEnvelope<DecisionHistoryRecordV2>, Error>({
    queryKey: ["decisionsV2", omit(params, "initialData")],
    queryFn: async () => {
      try {
        const decision = (await getHistoryDecision<ResponseData>(params)).data;
        const flowMatches = params.flowIdToMatch
          ? decision.flow.id === params.flowIdToMatch
          : true;
        if (!flowMatches) {
          return { isQueryError: true, reason: FLOW_MISSMATCH };
        }
        const versionMatches = params.versionIdToMatch
          ? decision.flow.version_id === params.versionIdToMatch
          : true;
        if (!versionMatches)
          return { isQueryError: true, reason: VERSION_MISSMATCH };
        if (
          params.environmentToMatch === DecisionEnvironment.SANDBOX &&
          !decision.is_sandbox
        )
          return { isQueryError: true, reason: ENVIRONMENT_MISSMATCH_IS_PROD };

        if (
          params.environmentToMatch === DecisionEnvironment.LIVE &&
          decision.is_sandbox
        )
          return {
            isQueryError: true,
            reason: ENVIRONMENT_MISSMATCH_IS_SANDBOX,
          };

        return { isQueryError: false, decision };
      } catch (error: any) {
        if (
          (error as AxiosError).response?.status === 422 ||
          (error as AxiosError).response?.status === 404
        ) {
          return { isQueryError: true, reason: NO_DECISION_FOUND };
        } else throw error;
      }
    },
    enabled: Boolean(params.decisionId) && params.baseUrl !== "",
    retry: 1,
    staleTime: 0,
    cacheTime: 0,
    initialData: params.initialData,
  });
};

export const useHistoryDecisionV3 = (
  params: DecisionsApiGetDecisionDecisionsDecisionIdGetRequest & {
    baseUrl: string;
    versionIdToMatch?: string;
    flowIdToMatch?: string;
    environmentToMatch?: DecisionEnvironment;
    initialData?: SingleDecisionEnvelope<HistoricalDecisionV3>;
  },
) => {
  return useQuery<SingleDecisionEnvelope<HistoricalDecisionV3>, Error>({
    queryKey: ["decisions", "v3", omit(params, "initialData")],
    queryFn: async () => {
      try {
        const decision = (
          await historyApi(params.baseUrl).getDecisionDecisionsDecisionIdGet(
            params,
          )
        ).data;
        const flowMatches = params.flowIdToMatch
          ? decision.flow.id === params.flowIdToMatch
          : true;
        if (!flowMatches) {
          return { isQueryError: true, reason: FLOW_MISSMATCH };
        }
        const versionMatches = params.versionIdToMatch
          ? decision?.flow?.version?.id === params.versionIdToMatch
          : true;
        if (!versionMatches)
          return { isQueryError: true, reason: VERSION_MISSMATCH };
        if (
          params.environmentToMatch !== undefined &&
          decision.environment === DecisionEnvironment.LIVE &&
          params.environmentToMatch !== DecisionEnvironment.LIVE
        ) {
          return {
            isQueryError: true,
            reason: ENVIRONMENT_MISSMATCH_IS_PROD,
          };
        }
        if (
          params.environmentToMatch !== undefined &&
          decision.environment === DecisionEnvironment.SANDBOX &&
          params.environmentToMatch !== DecisionEnvironment.SANDBOX
        ) {
          return {
            isQueryError: true,
            reason: ENVIRONMENT_MISSMATCH_IS_SANDBOX,
          };
        }

        return { isQueryError: false, decision };
      } catch (error: any) {
        if (
          (error as AxiosError).response?.status === 422 ||
          (error as AxiosError).response?.status === 404
        ) {
          return { isQueryError: true, reason: NO_DECISION_FOUND };
        } else throw error;
      }
    },
    enabled: Boolean(params.decisionId) && Boolean(params.baseUrl),
    retry: 1,
    staleTime: 0,
    cacheTime: 0,
    initialData: params.initialData,
  });
};

export const useNodeResultData = (params: {
  nodeId: string;
  decisionId: string;
  baseUrl: string;
}) => {
  return useQuery<GenericObjectT>({
    queryKey: ["nodeResultData", params],
    queryFn: async () => {
      const response = await decisionHistoryV3ApiRoute.get(
        `decisions/${params.decisionId}/node_results/${params.nodeId}`,
        { params: { $fields: "data" }, baseURL: params.baseUrl },
      );
      return response.data.data;
    },
    enabled: !!params.decisionId && !!params.nodeId,
  });
};
