import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { startOfToday, endOfToday } from "date-fns";
import { isEmpty } from "lodash";
import { useState, useMemo, useEffect } from "react";
import { SelectRangeEventHandler } from "react-day-picker";
import { DateRange } from "react-day-picker";
import { twJoin } from "tailwind-merge";

import { DateRangePicker } from "src/base-components/DateRangePicker";
import {
  AccordionRoot,
  EditorAccordionItem,
} from "src/base-components/EditorAccordionItem";
import { TableComp } from "src/base-components/Table";
import { EmptyState } from "src/design-system/EmptyState";
import { useSidepane } from "src/entities/entityView/utils";
import { getTableStoreKey } from "src/eventsCatalogue/EventsCatalogue";
import {
  useEventType,
  useEvents,
  useEventTypes,
} from "src/eventsCatalogue/queries";
import { getColumns } from "src/eventsCatalogue/tableConfig";
import { useEnvironment } from "src/eventsCatalogue/useEnvironment";
import { WhitePane } from "src/jobs/common/WhitePane";
import { EntityViewParams } from "src/router/urls";
import { useTimeWindow } from "src/utils/timeWindow";
import { useParamsDecode } from "src/utils/useParamsDecode";

type EventBlockProps = {
  eventType: string;
  isOpen: boolean;
  entityId: string;
  displayName: string;
};

const EventBlock: React.FC<EventBlockProps> = ({
  displayName,
  eventType,
  isOpen,
  entityId,
}) => {
  const [filters, setFilters] = useState<Record<string, string | null>>({});
  const [environment] = useEnvironment();
  const { dateRangePickerValue, onDateRangePickerChange, timeWindow } =
    useTimeWindow(
      {
        from: startOfToday(),
        to: endOfToday(),
      },
      `events-time-window-${eventType}`,
    );

  const { data: schema, isLoading: isLoadingSchema } = useEventType(
    isOpen ? eventType : "",
  );

  const {
    data: events,
    isLoading: isEventsLoading,
    isRefetching: isRefetchingEvents,
  } = useEvents({
    eventType: isOpen ? eventType : "",
    timeWindow: {
      from: timeWindow.from.toISOString(),
      to: timeWindow.to.toISOString(),
    },
    entityId,
    environment,
    filters,
  });

  const { resourceId: selectedEventId, toggleSidepane } = useSidepane("event");

  const memoizedColumns = useMemo(() => {
    return schema
      ? getColumns(schema, selectedEventId, {
          filters,
          onFilterChange: setFilters,
        })
      : [];
  }, [schema, selectedEventId, filters]);

  const onTimeWindowChange: SelectRangeEventHandler = (
    range: DateRange | undefined,
  ) => {
    if (range?.from && range?.to) {
      onDateRangePickerChange(range);
    }
  };

  const headerContent = (
    <div
      className={twJoin(
        "h-13 content-center rounded-lg rounded-l-none border border-gray-200 bg-white",
        isOpen && "rounded-b-none",
      )}
    >
      <div className="flex items-center gap-x-2 pr-4">
        <DateRangePicker
          borderless={true}
          suffixIcon={true}
          value={dateRangePickerValue}
          onChange={onTimeWindowChange}
        />
      </div>
    </div>
  );

  const isLoading = isEventsLoading || isLoadingSchema || isRefetchingEvents;

  return (
    <EditorAccordionItem
      className="decideScrollbar overflow-hidden overflow-x-auto p-4 pt-3"
      contentWrapperClassName="bg-white border-b border-x border-gray-200 rounded-b-lg overflow-hidden"
      headerClassName={twJoin(
        "rounded-lg rounded-r-none border border-r-0 border-gray-200 bg-white transition-all",
        isOpen && "rounded-b-none",
      )}
      headerContent={headerContent}
      title={displayName}
      value={eventType}
    >
      {!isLoading &&
      (isEmpty(filters) || Object.values(filters).every((value) => !value)) &&
      events?.length === 0 ? (
        <EmptyState
          dataLoc={`${eventType}-empty-state`}
          description="Try adjusting the filter to find what you are looking for"
          headline="No events found"
          icon={faSearch}
        />
      ) : (
        <>
          <TableComp
            columns={memoizedColumns}
            data={events || []}
            frameClassName="w-full"
            isLoading={isLoading}
            rowPropsGetter={(row) => ({
              onClick: () => {
                toggleSidepane({
                  id: row.original.payload.id,
                  eventType: eventType,
                });
              },
              classNameOverrides:
                row.original.payload.id === selectedEventId ? "bg-gray-50" : "",
            })}
            uiStoreKey={getTableStoreKey(eventType)}
            variant="compact"
            noScroll
          />
          {!isLoading && events?.length === 0 && (
            <EmptyState
              dataLoc={`${eventType}-empty-state`}
              description="Try adjusting the filter to find what you are looking for"
              headline="No events found"
              icon={faSearch}
            />
          )}
        </>
      )}
    </EditorAccordionItem>
  );
};

type EntityEventsProps = {
  entityId: string;
};

export const EntityEvents: React.FC<EntityEventsProps> = ({ entityId }) => {
  const { data: eventTypes, isLoading: isLoadingEventTypes } = useEventTypes();
  const [accordionValue, setAccordionValue] = useState<string[]>([]);

  useEffect(() => {
    if (eventTypes && eventTypes.length > 0) {
      setAccordionValue([eventTypes[0].event_type]);
    }
  }, [eventTypes]);

  if (!eventTypes?.length && !isLoadingEventTypes) {
    return (
      <WhitePane>
        <EmptyState
          dataLoc="events-empty-state"
          description="There are no events found related to this entity yet."
          headline="No events found"
          icon={faSearch}
        />
      </WhitePane>
    );
  }

  return (
    <AccordionRoot
      className="flex flex-col gap-y-3"
      type="multiple"
      value={accordionValue}
      onValueChange={setAccordionValue}
    >
      {eventTypes?.map((eventType) => (
        <EventBlock
          key={eventType.event_type}
          displayName={eventType.display_name_plural}
          entityId={entityId}
          eventType={eventType.event_type}
          isOpen={accordionValue.includes(eventType.event_type)}
        />
      ))}
    </AccordionRoot>
  );
};

export const EventsTabContent = () => {
  const { id } = useParamsDecode<EntityViewParams>();

  return (
    <div className="p-5">
      <EntityEvents entityId={id} />
    </div>
  );
};
