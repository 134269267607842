import { faClock } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Icon } from "src/base-components/Icon";
import { DecisionFields } from "src/decisionsOverview/DecisionFields";
import { DecisionsSidepane } from "src/decisionsOverview/DecisionsSidepane";
import { DecisionSidepaneProps } from "src/decisionsOverview/DecisionsSidepaneWrapper";
import { DecisionInputFieldsTree } from "src/entities/entityView/DecisionFieldsTree";
import { ManualReviewTabContent } from "src/entities/entityView/ManualReviewTabContent";

export const DecisionSidepaneWrapper: React.FC<DecisionSidepaneProps> = ({
  widthClass,
  decision,
  isOpen,
  onClose,
}) => {
  const tabs = [
    {
      label: "Input",
      key: "input-data-tab",
      content: <DecisionInputFieldsTree decisionId={decision?.id!} />,
    },
    {
      label: (
        <div className="flex flex-row items-center gap-x-1">
          <p className="text-gray-800">Manual Review</p>
          <Icon color="text-gray-500" icon={faClock} size="xs" />
        </div>
      ),
      key: "manual-review-data-tab",
      content: decision && (
        <ManualReviewTabContent
          decisionId={decision?.id}
          flowId={decision?.flow.id ?? ""}
          flowSlug={decision?.flow.slug ?? ""}
        />
      ),
    },
    {
      label: "Output",
      key: "output-data-tab",
      content: <DecisionFields decisionId={decision?.id!} type="response" />,
    },
  ];

  return (
    <DecisionsSidepane
      decision={decision}
      defaultTab={tabs[0].key}
      isInputSchemaError={false}
      isOpen={isOpen}
      tabs={tabs}
      widthClass={widthClass}
      onClose={onClose}
    />
  );
};
