import { CellContext, createColumnHelper } from "@tanstack/react-table";

import {
  ReviewCaseHighlightsElement,
  ReviewCaseHighlightsField,
} from "src/api/types";
import { Divider } from "src/base-components/Divider";
import { Cell, TableComp } from "src/base-components/Table";

type ProcessedHighlight =
  | Exclude<ReviewCaseHighlightsElement, ReviewCaseHighlightsField>
  | { type: "table"; tableRows: ReviewCaseHighlightsField[] };

const columnHelper = createColumnHelper<ReviewCaseHighlightsField>();
const columns = [
  columnHelper.accessor("readable_name", {
    header: () => null,
    cell: (info: CellContext<ReviewCaseHighlightsField, string>) => (
      <Cell info={info}>{info.getValue()}</Cell>
    ),
    size: 206,
  }),
  columnHelper.accessor("value", {
    header: () => null,
    cell: (info: CellContext<ReviewCaseHighlightsField, string>) => (
      <Cell info={info}>{info.getValue()}</Cell>
    ),
  }),
];

const HighlightTable: React.FC<{ data: ReviewCaseHighlightsField[] }> = ({
  data,
}) => {
  return (
    <TableComp
      columns={columns}
      data={data}
      frameClassName="my-4"
      variant="compact"
    />
  );
};

const processHighlights = (highlights: ReviewCaseHighlightsElement[]) => {
  return highlights.reduce<ProcessedHighlight[]>((acc, current) => {
    if (current.type !== "field") {
      acc.push(current);
      return acc;
    }

    // If it's a field type, check if the previous item was a table
    const lastItem = acc[acc.length - 1];
    if (lastItem && lastItem.type === "table") {
      // Add to existing table
      lastItem.tableRows.push(current);
    } else {
      // Create new table
      acc.push({
        type: "table",
        tableRows: [current],
      });
    }

    return acc;
  }, []);
};

export const ManualReviewHighlights: React.FC<{
  highlights: ReviewCaseHighlightsElement[];
}> = ({ highlights }) => {
  const processedHighlights = processHighlights(highlights);

  return processedHighlights.map((highlight, index) => {
    if (highlight.type === "description") {
      return (
        <div key={index} className="review-case-description">
          {highlight.value}
        </div>
      );
    }
    if (highlight.type === "divider") {
      return <Divider key={index} spacing="my-4" />;
    }
    return <HighlightTable key={index} data={highlight.tableRows} />;
  });
};
