import {
  faInfoCircle,
  faMoneySimpleFromBracket,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "src/base-components/Button";
import { Divider } from "src/base-components/Divider";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import {
  EventConfigInput,
  EventConfigOutput,
} from "src/clients/features-control";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { PropertiesSchemaEditor } from "src/eventsCatalogue/SchemaEditor/PropertiesSchemaEditor";
import {
  FormProperties,
  formPropertiesToSchemaProperties,
  schemaPropertiesToFormProperties,
} from "src/eventsCatalogue/SchemaEditor/utils";
import { useEventType, useUpdateEventType } from "src/eventsCatalogue/queries";
import { EventPageParamsT, getUrlToEventsPage } from "src/router/urls";
import { logger } from "src/utils/logger";
import { isPreconditionError } from "src/utils/predicates";
import { useParamsDecode } from "src/utils/useParamsDecode";

export type EventSchemaForm = Omit<EventConfigOutput, "properties"> & {
  properties: FormProperties;
};

export const EventSchemaEditSidebar = () => {
  const {
    wsId,
    orgId,
    event_type: eventType,
  } = useParamsDecode<EventPageParamsT>();
  const navigate = useNavigate();
  const {
    data: eventTypeConfig,
    isLoading,
    isFetching,
  } = useEventType(eventType);
  const { mutateAsync: updateEventType, isLoading: isPending } =
    useUpdateEventType();

  const eventConfig = useMemo(
    () =>
      eventTypeConfig
        ? {
            ...eventTypeConfig,
            properties: schemaPropertiesToFormProperties(
              eventTypeConfig.properties,
            ),
          }
        : undefined,
    [eventTypeConfig],
  );

  const form = useForm<EventSchemaForm>({
    values: eventConfig,
  });

  const onSubmit = async (values: EventSchemaForm) => {
    const eventConfig = {
      ...values,
      properties: formPropertiesToSchemaProperties(values.properties),
    } as unknown as EventConfigInput;
    // Essentially EventConfigOutput and EventConfigInput are the same, but
    // but generator generates underlying enums as two different types.
    // So we cast on type to another.

    if (!eventConfig.etag) return;

    try {
      await updateEventType({
        etag: eventConfig.etag,
        eventType,
        ...eventConfig,
      });
    } catch (e) {
      if (isPreconditionError(e)) {
        toastActions.failure({
          title: `Failed to save the event schema`,
          description:
            "Someone made changes while you were editing. Please reload and try again.",
        });
        return;
      }
      logger.error(e);
      toastActions.failure({
        title: `Failed to save the event schema`,
        description: "Please reload and try again.",
      });
    }
  };

  return (
    <form
      className="decideScrollbar flex w-150 shrink-0 flex-col gap-y-4 bg-white px-4 py-5"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <FormProvider {...form}>
        <div className="flex items-center gap-x-1.5">
          <div className="flex-1 text-gray-800 font-inter-semibold-13px">
            Edit Event schema
          </div>
          <Button
            disabled={
              !form.formState.isDirty || isFetching || isLoading || isPending
            }
            htmlType="submit"
            loading={isPending}
            size="sm"
          >
            Save changes
          </Button>
          <Tooltip
            body="For additional changes you want to make on Event Schema, please contact Taktile team."
            placement="top-end"
          >
            <Icon color="text-gray-500" icon={faInfoCircle} size="xs" />
          </Tooltip>
          <Icon
            color="text-gray-500"
            icon={faTimes}
            size="xs"
            onClick={() =>
              navigate(
                getUrlToEventsPage({
                  orgId,
                  wsId,
                  eventType,
                }),
              )
            }
          />
        </div>
        <Divider />
        <EventConfigurationRoot isLoading={isLoading} />
        <PropertiesSchemaEditor isLoading={isLoading} />
      </FormProvider>
    </form>
  );
};

const EventConfigurationRoot: React.FC<{
  isLoading: boolean;
}> = ({ isLoading }) => {
  const form = useFormContext<EventConfigOutput>();

  return (
    <div className="flex gap-x-2">
      <div className="flex h-15 w-15 shrink-0 items-center justify-center rounded-xl border border-gray-200">
        {isLoading ? (
          <SkeletonPlaceholder height="h-6" width="w-6" />
        ) : (
          <Icon
            color="text-gray-600"
            icon={faMoneySimpleFromBracket}
            size="2xl"
          />
        )}
      </div>
      <div className="flex min-w-0 flex-1 flex-col gap-y-2">
        <div className="text-gray-500 font-inter-normal-12px">Name</div>
        {isLoading ? (
          <SkeletonPlaceholder
            height="h-8"
            rounded="rounded-lg"
            width="w-full"
          />
        ) : (
          <Input
            disabled
            fullWidth
            monospaced
            {...form.register("event_type", { required: true })}
          />
        )}
      </div>
      <div className="flex min-w-0 flex-1 flex-col gap-y-2">
        <div className="text-gray-500 font-inter-normal-12px">
          Display Name Singular
        </div>
        {isLoading ? (
          <SkeletonPlaceholder
            height="h-8"
            rounded="rounded-lg"
            width="w-full"
          />
        ) : (
          <Input
            errored={!!form.formState.errors.display_name_singular}
            fullWidth
            {...form.register("display_name_singular", {
              required: "This field is required",
            })}
          />
        )}
        {form.formState.errors.display_name_singular && (
          <ErrorHint>
            {form.formState.errors.display_name_singular?.message}
          </ErrorHint>
        )}
      </div>
      <div className="flex min-w-0 flex-1 flex-col gap-y-2">
        <div className="text-gray-500 font-inter-normal-12px">
          Display Name Plural
        </div>
        {isLoading ? (
          <SkeletonPlaceholder
            height="h-8"
            rounded="rounded-lg"
            width="w-full"
          />
        ) : (
          <Input
            errored={!!form.formState.errors.display_name_plural}
            fullWidth
            {...form.register("display_name_plural", {
              required: "This field is required",
            })}
          />
        )}
        {form.formState.errors.display_name_plural && (
          <ErrorHint>
            {form.formState.errors.display_name_plural?.message}
          </ErrorHint>
        )}
      </div>
    </div>
  );
};
