import {
  ConnectionResourceTemplateConfigKey,
  makeConnectionTemplate,
} from "src/connections/types";

const permissiblePurposeElements: { key: string; value: string }[] = [
  { key: "AR", value: "AR - To acquire/service/insure account" },
  { key: "BN", value: "BN - Consumer initiated transaction" },
  { key: "CF", value: "CF - Credit medical information returned" },
  { key: "CI", value: "CI - Written authorization" },
  { key: "CL", value: "CL - Collection" },
  { key: "CP", value: "CP - Child support" },
  { key: "CR", value: "CR - Written authorization" },
  { key: "CS", value: "CS - Child support" },
  { key: "CT", value: "CT - Credit transaction" },
  { key: "EF", value: "EF - Employment/medical information returned" },
  { key: "EP", value: "EP - Employment" },
  { key: "GF", value: "GF - Government license or benefit" },
  { key: "GR", value: "GR - Government" },
  { key: "IM", value: "IM - Insurance/medical information returned" },
  { key: "IN", value: "IN - Insurance underwriting" },
  { key: "IS", value: "IS - Written authorization" },
  { key: "MS", value: "MS - Credit monitoring" },
  { key: "PR", value: "PR - Insurance underwriting" },
  { key: "RA", value: "RA - Account review" },
  { key: "RP", value: "RP - Consumer request" },
  { key: "SI", value: "SI - Court order/subpoena" },
  { key: "TS", value: "TS - Tenant screening" },
];

const getResourceConfig = (): ConnectionResourceTemplateConfigKey[] => [
  {
    key: "permissible_purpose",
    required: true,
    name: "Permissible purpose",
    type: "simple_selection",
    hint: `You are legally required to provide a permissible purpose for obtaining the requested report. Please select the purpose that best describes your reason for accessing the report. Refer to our docs to understand the meaning of the codes.`,
    elements: permissiblePurposeElements,
  },
  {
    key: "subscriber_member_code",
    required: true,
    name: "Subscriber member code",
    type: "text",
    hint: `The member code is part of your organization's subscriber code, which is provided directly by TransUnion`,
  },
  {
    key: "subscriber_industry_code",
    required: true,
    name: "Subscriber industry code",
    type: "text",
    hint: `The industry code is part of your organization's subscriber code, which is provided directly by TransUnion`,
  },
  {
    key: "subscriber_prefix_code",
    required: true,
    name: "Subscriber prefix code",
    type: "text",
    hint: `The prefix code is part of your organization's subscriber code, which is provided directly by TransUnion`,
  },
];

export const TransUnionTemplate = makeConnectionTemplate({
  provider: "trans_union",
  secrets: [
    {
      key: "subscriber_code_password",
      name: "Subscriber code password",
      required: true,
      hint: "The subscriber code password is provided directly by TransUnion",
    },
    {
      key: "certificate",
      name: "Certificate",
      required: true,
      hint: `The chain of certificates should be in PEM format (including the "Bag Attributes" section) and start with the leaf certificate. Line breaks should be replaced with "\\n".`,
    },
    {
      key: "root_ca_certificate",
      name: "Root CA certificate",
      required: true,
      hint: `The root CA certificate should be in PEM format (including the "Bag Attributes" section). Line breaks should be replaced with "\\n".`,
    },
    {
      key: "private_key",
      name: "Private key",
      required: true,
      hint: `The private key should be in PEM format (including the "Bag Attributes" section). Line breaks should be replaced with "\\n".`,
    },
  ],
  resources: {
    tru_vision_model_report: {
      name: "TruVision Model Report",
      configKeys: getResourceConfig(),
    },
    tru_vision_credit_report: {
      name: "TruVision Credit Report",
      configKeys: getResourceConfig(),
    },
  },
});
