import { faArrowUpRight, faEye } from "@fortawesome/pro-regular-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { DecisionEnvironment } from "src/api/types";
import ManyToOneIcon from "src/assets/ManyToOneIcon.svg?react";
import OneToManyIcon from "src/assets/OneToManyIcon.svg?react";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { DataValue } from "src/base-components/DataList";
import {
  AccordionRoot,
  EditorAccordionItem,
} from "src/base-components/EditorAccordionItem";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Cell, Header, TableComp } from "src/base-components/Table";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import {
  EntityDetailsFloatingWindow,
  EntityDetailsWindowPill,
} from "src/entities/entityView/EntityDetailsWindowPill";
import { getSchemaIcon } from "src/entities/entityView/utils";
import {
  EntityResource,
  EntitySchemaResource,
  useRelatedEntities,
  EntitySchemaProperty,
} from "src/entities/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntityViewParams } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type Props = {
  relatedEntities: EntityResource[];
  schemas: EntitySchemaResource[];
};

const Title: React.FC<{ property: string; schema: EntitySchemaResource }> = ({
  property,
  schema,
}) => (
  <div className="flex items-center gap-x-2">
    <h3 className="capitalize font-inter-semibold-13px">{property}</h3>
    <Pill size="sm" variant="gray">
      <Pill.Icon color="text-gray-400" icon={getSchemaIcon(schema)} />
      <Pill.Text>
        <span className="capitalize">{schema._display_name_plural}</span>
      </Pill.Text>
    </Pill>
  </div>
);

const EntityTypeIcon: React.FC<{
  schema: EntitySchemaResource;
  propertyKey: string;
  property: EntitySchemaProperty;
}> = ({ schema, propertyKey, property }) => {
  if (property._type === "relation") {
    return (
      <div className="mr-0.5 shrink-0">
        {property._cardinality === "1" ? <OneToManyIcon /> : <ManyToOneIcon />}
      </div>
    );
  }
  const icon =
    schema._primary_property === propertyKey
      ? faArrowUpRight
      : SCHEMA_TYPE_ICONS[property._type as keyof typeof SCHEMA_TYPE_ICONS];
  return <Icon color="text-gray-500" icon={icon} size="2xs" />;
};

const ValueCell: React.FC<{
  schema: EntitySchemaResource;
  property: EntitySchemaProperty;
  propertyKey: string;
  info: CellContext<EntityResource, unknown>;
}> = ({ info, schema, property, propertyKey }) => {
  const isPrimary = schema._primary_property === propertyKey;
  const isEnum = property._type === "enum";

  if (isPrimary) {
    const row = info.row.original;

    return (
      <Cell info={info}>
        <EntityDetailsWindowPill
          entityIcon={getSchemaIcon(schema)}
          id={row._id}
          name={info.getValue() as string}
          schemaId={schema._id}
        />
      </Cell>
    );
  }

  return (
    <Cell info={info}>
      {isEnum ? (
        <Pill size="sm" variant="gray">
          <Pill.Text>{info.getValue() as string}</Pill.Text>
        </Pill>
      ) : (
        <DataValue field={propertyKey} value={info.getValue()} />
      )}
    </Cell>
  );
};

const EntityAccordionItem: React.FC<{
  schema: EntitySchemaResource;
  entities: EntityResource[];
  isOpen: boolean;
  value: string;
}> = ({ value, schema, entities, isOpen }) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const columns = useMemo(() => {
    const helper = createColumnHelper<EntityResource>();
    const schemaFields = Object.entries(schema.properties ?? []);
    const userColumns = schemaFields.map(([key, propertyType]) =>
      helper.accessor(`properties.${key}`, {
        header: (info) => (
          <Header info={info}>
            <div className="flex items-center gap-x-0.5">
              <EntityTypeIcon
                property={propertyType}
                propertyKey={key}
                schema={schema}
              />
              {propertyType._display_name}
            </div>
          </Header>
        ),
        cell: (info) => (
          <ValueCell
            info={info}
            property={propertyType}
            propertyKey={key}
            schema={schema}
          />
        ),
      }),
    );
    const idColumn = helper.accessor("_id", {
      header: (info) => (
        <Header info={info}>
          <div className="flex items-center gap-x-0.5">
            <Icon
              color="text-gray-500"
              icon={SCHEMA_TYPE_ICONS["string"]}
              size="2xs"
            />
            Entity ID
          </div>
        </Header>
      ),
      size: 100,
      cell: (info) => (
        <Cell info={info}>
          <CopyTextIcon value={info.getValue()} />
          <EntityDetailsFloatingWindow
            button={<></>}
            entityIcon={getSchemaIcon(schema)}
            id={info.getValue()}
            isOpen={selectedId === info.getValue()}
            name={info.getValue()}
            schemaId={schema._id}
            onClose={() => setSelectedId(null)}
          />
        </Cell>
      ),
    });

    const detailsColumn = helper.accessor("properties.options", {
      header: () => null,
      size: 54,
      cell: (info) => (
        <Cell className="items-center justify-end" info={info}>
          <Icon
            color={twMerge(
              "text-gray-500 group-hover:visible",
              selectedId === info.row.original._id
                ? "text-indigo-600"
                : "invisible",
            )}
            icon={faEye}
            size="xs"
          />
        </Cell>
      ),
    });

    return [idColumn, ...userColumns, detailsColumn];
  }, [schema, selectedId]);

  return (
    <EditorAccordionItem
      className="decideScrollbar overflow-hidden overflow-x-auto p-4"
      contentWrapperClassName="bg-white border-b border-x border-gray-200 rounded-b-lg overflow-hidden"
      headerClassName={twJoin(
        "rounded-lg border border-gray-200 bg-white transition-all",
        isOpen && "rounded-b-none",
      )}
      title={<Title property={value} schema={schema} />}
      value={value}
    >
      <TableComp
        columns={columns}
        data={entities}
        rowClassName="group"
        rowPropsGetter={(row) => ({
          onClick: () => {
            setSelectedId((prev) =>
              prev === row.original._id ? null : row.original._id,
            );
          },
          classNameOverrides: twJoin(
            row.original._id === selectedId ? "bg-gray-50" : "hover:bg-gray-50",
            "cursor-pointer",
          ),
        })}
        variant="compact"
      />
    </EditorAccordionItem>
  );
};

const RelatedEntities: React.FC<Props> = () => {
  const { schema, id: entityId } = useParamsDecode<EntityViewParams>();
  const { workspace } = useWorkspaceContext();

  const [accordionValue, setAccordionValue] = useState<string[]>([
    "cards",
    "employer",
  ]);

  const { data: relatedEntities } = useRelatedEntities({
    schema,
    env: DecisionEnvironment.SANDBOX,
    baseUrl: workspace.base_url ?? "",
    entityId,
  });

  return (
    <AccordionRoot
      className="flex flex-col gap-y-3 pb-6"
      type="multiple"
      value={accordionValue}
      onValueChange={setAccordionValue}
    >
      {relatedEntities?.map(({ schema, entities }) => (
        <EntityAccordionItem
          key={schema._id}
          entities={entities}
          isOpen={accordionValue.includes(schema._id)}
          schema={schema}
          value={schema._id}
        />
      ))}
    </AccordionRoot>
  );
};

export const RelatedEntitiesWrapper = () => {
  return (
    <div className="p-5">
      <RelatedEntities relatedEntities={[]} schemas={[]} />
    </div>
  );
};
