import { capitalize } from "lodash";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { EntityDetailsSidePane } from "src/entities/entityView/EntityDetailsSidePane";
import { EntityTabs } from "src/entities/entityView/EntityTabs";
import { EntityViewSidePane } from "src/entities/entityView/EntityViewSidePane";
import { ExtendedHistoricalDecisionV3 } from "src/flow/decisionHistory/Columns";
import { PageHeader } from "src/layout/PageHeader";
import { EntityViewParams, getWsUrl } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const sidePaneWidthClass = "w-[420px]" as const;

export const EntityView = () => {
  const [selectedDecision, setSelectedDecision] =
    useState<ExtendedHistoricalDecisionV3 | null>(null);
  const {
    schema,
    id: entityId,
    orgId,
    wsId,
  } = useParamsDecode<EntityViewParams>();
  const location = useLocation();

  const buildBreadcrumbUrl = (schema: string) =>
    `${getWsUrl(orgId, wsId)}entities/${encodeURIComponent(schema)}${location.search}`;

  const breadcrumbs = [
    {
      key: "entity-schema",
      breadcrumb: (
        <PageHeader.Breadcrumb
          dataLoc="entity-schema"
          href={buildBreadcrumbUrl(schema!)}
          label={capitalize(schema)}
          hideIcon
        />
      ),
    },
    {
      key: "entity-name",
      breadcrumb: (
        <PageHeader.Breadcrumb
          dataLoc="entity-name"
          href={`${location.pathname}${location.search}`}
          label="Charles Darwin"
          active
        />
      ),
    },
  ];

  return (
    <FloatingWindowsProvider>
      <div className="flex h-full flex-col">
        <PageHeader breadcrumbs={breadcrumbs} />
        <div className="flex min-h-0 flex-1">
          <div className="h-full w-90 shrink-0 border-l border-gray-200 bg-white p-5">
            <EntityDetailsSidePane
              entityId={entityId}
              isWindowPill={true}
              schema={schema}
            />
          </div>
          <EntityTabs
            selectedDecision={selectedDecision}
            onSelectDecision={setSelectedDecision}
          />
          {Boolean(selectedDecision) && (
            <div className={twJoin(sidePaneWidthClass, "flex-shrink-0 p-5")} />
          )}
          <EntityViewSidePane
            selectedDecision={selectedDecision}
            setSelectedDecision={setSelectedDecision}
          />
        </div>
      </div>
    </FloatingWindowsProvider>
  );
};
