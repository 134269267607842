import { useValidateProductionEnvironment } from "src/api/connectApi/queries";
import { FlowVersionT } from "src/api/flowTypes";
import { useFlowVersion } from "src/api/flowVersionQueries";
import { NodeTypes } from "src/clients/flow-api/api";
import { useWorkspaceAndOrg } from "src/utils/useCurrentWorkspace";

const getFlowPublishValidatorMessage = (
  validateProductionEnvironmentResponse:
    | { invalid_connection_config_ids?: string[] }
    | undefined,
  version: { graph?: { nodes?: Record<string, any> } } | undefined,
): React.ReactNode => {
  if (
    !validateProductionEnvironmentResponse?.invalid_connection_config_ids
      ?.length
  ) {
    return "";
  }

  const invalidConnectionIds =
    validateProductionEnvironmentResponse?.invalid_connection_config_ids;
  const nodeNames: string[] = [];

  invalidConnectionIds.forEach((id) => {
    const nodes = Object.values(version?.graph?.nodes || {}).filter(
      (n) => n.meta?.connection_id === id,
    );
    nodes.forEach((node) => {
      nodeNames.push(node?.name || "Unnamed node");
    });
  });

  return (
    <div>
      This decision flow version cannot be published because it has{" "}
      {nodeNames.length} {nodeNames.length === 1 ? "node" : "nodes"} which{" "}
      {nodeNames.length === 1 ? "does" : "do"} not have production credentials
      configured:
      <ul>
        {nodeNames.map((name, i) => (
          <li key={i}>• {name}</li>
        ))}
      </ul>
    </div>
  );
};

const ValidateIntegrationNodeConfigs = (
  version: FlowVersionT | undefined,
  workspaceBaseUrl: string | undefined,
): { publishDisabledMessage: React.ReactNode; publishDisabled: boolean } => {
  const manifestConnectionIds: string[] = [];
  if (version?.graph?.nodes) {
    const nodeIds = new Set(
      Object.values(version.graph.nodes)
        .filter((node) => node.type === NodeTypes.MANIFEST_CONNECTION_NODE)
        .map((node) => node.meta.connection_id),
    );
    manifestConnectionIds.push(...nodeIds);
  }

  const { data: validateProductionEnvironmentResponse } =
    useValidateProductionEnvironment(workspaceBaseUrl, manifestConnectionIds);

  const publishDisabled =
    !!validateProductionEnvironmentResponse?.invalid_connection_config_ids
      ?.length;
  const publishDisabledMessage = getFlowPublishValidatorMessage(
    validateProductionEnvironmentResponse,
    version,
  );

  return { publishDisabledMessage, publishDisabled };
};

export const useFlowPublishValidator = (
  flow_version: FlowVersionT | undefined,
): { publishDisabledMessage: React.ReactNode; publishDisabled: boolean } => {
  const { data: version } = useFlowVersion(flow_version?.id);
  const workspaceData = useWorkspaceAndOrg();

  return ValidateIntegrationNodeConfigs(
    version,
    workspaceData.workspace?.base_url,
  );
};
