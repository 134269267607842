import ThomsonReutersIcon from "src/assets/ThomsonReutersIcon.svg?react";
import {
  getDefaultThomsonReutersClearPersonSearch,
  getDefaultThomsonReutersClearBusinessSearch,
  getDefaultThomsonReutersRiskPersonSearch,
  getDefaultThomsonReutersRiskBusinessSearch,
  getDefaultThomsonReutersBusinessReport,
  getDefaultThomsonReutersPersonReport,
} from "src/connections/connectionConfigs/thomsonReuters/thomsonReutersResources";
import { ThomsonReutersTemplate } from "src/connections/connectionConfigs/thomsonReuters/thomsonReutersTemplate";
import { ConnectionConfig } from "src/connections/connectionConfigs/types";

export const THOMSON_REUTERS_CONFIG: ConnectionConfig<"thomson_reuters"> = {
  icon: ThomsonReutersIcon,
  template: ThomsonReutersTemplate,
  description: "Search, reporting and analytics capabilities",
  name: "Thomson Reuters (US)",
  resources: {
    thomson_reuters_clear_person_search: {
      externalLink:
        "https://www.notion.so/taktile/CLEAR-ID-Confirm-Person-Search-3622b339858c44049317f61c68c3455b",
      integrationResource: getDefaultThomsonReutersClearPersonSearch,
    },
    thomson_reuters_clear_business_search: {
      externalLink:
        "https://www.notion.so/taktile/CLEAR-ID-Confirm-Business-Search-75b02a9105ef4a51ab1cf1877d6aa24a",
      integrationResource: getDefaultThomsonReutersClearBusinessSearch,
    },
    thomson_reuters_risk_person_search: {
      externalLink:
        "https://www.notion.so/taktile/Risk-Inform-Person-Search-30353ce69b624e348d2aa4ab25345e83",
      integrationResource: getDefaultThomsonReutersRiskPersonSearch,
    },
    thomson_reuters_risk_business_search: {
      externalLink:
        "https://www.notion.so/taktile/Risk-Inform-Business-Search-5e637ee6fd5042d1aad249c23ae56c2d",
      integrationResource: getDefaultThomsonReutersRiskBusinessSearch,
    },
    thomson_reuters_person_report: {
      externalLink:
        "https://www.notion.so/taktile/Person-Report-af1a907795f148648fab2463465d2ff8",
      integrationResource: getDefaultThomsonReutersPersonReport,
    },
    thomson_reuters_business_report: {
      externalLink:
        "https://www.notion.so/taktile/Business-Report-245f7d7f9d7f42d088dcd8f5d1f5a94f",
      integrationResource: getDefaultThomsonReutersBusinessReport,
    },
  },
};
