import { faCalculatorSimple, faCode } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { useCapabilities } from "src/hooks/useCapabilities";
import { PageHeader } from "src/layout/PageHeader";
import { SubHeader, SubHeaderTab, SubHeaderTabs } from "src/layout/SubHeader";
import {
  DashboardPageParamsT,
  getUrlToFeaturesPage,
  getUrlToQueryPreviewPage,
} from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const FeatureCatalogueWrapper: React.FC<{
  header: React.ReactNode;
  children: React.ReactNode;
}> = ({ header, children }) => {
  const { featureQueriesCatalogue } = useCapabilities();
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();

  return (
    <div data-loc="catalogue-wrapper">
      <PageHeader title="Features" />
      <SubHeader
        leftSlot={
          <SubHeaderTabs>
            <SubHeaderTab
              icon={faCalculatorSimple}
              to={getUrlToFeaturesPage(orgId, wsId)}
              end
            >
              Features
            </SubHeaderTab>
            {featureQueriesCatalogue.canAccess && (
              <SubHeaderTab
                icon={faCode}
                to={getUrlToQueryPreviewPage(orgId, wsId)}
              >
                Queries
              </SubHeaderTab>
            )}
          </SubHeaderTabs>
        }
      >
        {header}
      </SubHeader>

      <div className="m-auto flex w-full max-w-[1800px] flex-col gap-y-5.5 px-4 pt-4">
        {children}
      </div>
    </div>
  );
};
