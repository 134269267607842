import {
  EntityEnumSchemaProperty,
  EntitySchemaProperty,
} from "src/entities/queries";

export const isEntityEnumSchemaProperty = (
  property: EntitySchemaProperty,
): property is EntityEnumSchemaProperty => {
  return property._type === "enum";
};
