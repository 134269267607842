import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  useDeleteOrganizationLogo,
  useUpdateOrganizationLogo,
  useUserOrganizationLogo,
} from "src/api/queries";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Label } from "src/base-components/Label";
import { Modal } from "src/design-system/Modal";
import { UploadIcon } from "src/layout/OrgLogoModal/UploadIcon";
import { DashboardPageParamsT } from "src/router/urls";
import { logger } from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

type OrgLogoModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const OrgLogoModal: React.FC<OrgLogoModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { orgId } = useParamsDecode<DashboardPageParamsT>();
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors, isValid },
  } = useForm<{ file: File | undefined | "deleted" }>({
    defaultValues: { file: undefined },
    mode: "onChange",
  });
  const { data: logoData } = useUserOrganizationLogo(orgId);
  const imgSrc = useMemo(() => {
    try {
      // @ts-expect-error
      return logoData ? URL.createObjectURL(logoData) : undefined;
    } catch (error) {
      logger.error("Error creating object URL", { error });
      return undefined;
    }
  }, [logoData]);

  const deleteLogo = useDeleteOrganizationLogo();
  const uploadLogo = useUpdateOrganizationLogo();

  const formSubmit = handleSubmit(
    async (data: { file: File | undefined | "deleted" }) => {
      if (data.file && data.file !== "deleted") {
        await uploadLogo.mutateAsync({
          organizationId: orgId,
          logo: data.file,
        });
      } else if (data.file === "deleted") {
        await deleteLogo.mutateAsync({
          organizationId: orgId,
        });
      }

      onClose();
    },
  );

  return (
    <Modal afterLeave={() => reset()} open={isOpen} onClose={onClose}>
      <Modal.Header>Edit organization</Modal.Header>
      <form onSubmit={formSubmit}>
        <Modal.Content>
          <Label mb="mb-1">Organization logo</Label>
          {errors.file?.message && (
            <ErrorHint>{errors.file?.message}</ErrorHint>
          )}
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange } }) => (
              <div className="w-fit">
                <UploadIcon
                  currentImageSource={imgSrc}
                  onFileRemoved={() => {
                    onChange("deleted");
                  }}
                  onFileSelected={(file) => {
                    onChange(file);
                  }}
                />
              </div>
            )}
            rules={{
              validate: {
                fileSize: (file) => {
                  if (file === "deleted" || file === undefined) return true;
                  return (
                    file.size <= 100 * 1024 ||
                    "File must be smaller than 100 KB"
                  );
                },
                fileType: (file) => {
                  if (file === "deleted" || file === undefined) return true;
                  const validTypes = [
                    "image/svg+xml",
                    "image/png",
                    "image/jpeg",
                  ];
                  return (
                    validTypes.includes(file.type) || "Invalid file format"
                  );
                },
              },
            }}
          />
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              disabled={isSubmitting || !isValid}
              htmlType="submit"
              loading={isSubmitting}
              variant="primary"
            >
              Save
            </Button>
          }
        />
      </form>
    </Modal>
  );
};
