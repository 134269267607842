import React from "react";
import { Navigate } from "react-router-dom";

import { OrganizationT } from "src/api/flowTypes";
import { useUserOrganizations, useWorkspaces } from "src/api/queries";
import { LoadingView } from "src/base-components/LoadingView";
import { WorkspaceDataplane, WorkspaceStatus } from "src/clients/flow-api";
import { OrganizationInDB } from "src/clients/taktile-api";
import { getUrlToWsDashboard, getWelcomeUrl } from "src/router/urls";
import { useLastWorkspaceId } from "src/utils/useLastWorkspaceId";

const NavigateToWorkspace: React.FC<{
  workspaces: WorkspaceDataplane[];
  orgs: OrganizationT[];
}> = ({ orgs, workspaces }) => {
  const [lastWorkspaceId] = useLastWorkspaceId(workspaces[0]?.id);

  if (workspaces.length) {
    const workspace =
      workspaces.find((ws) => ws.id === lastWorkspaceId) ??
      workspaces.find((ws) => ws.status === WorkspaceStatus.SUCCESS) ??
      workspaces[0];

    return (
      <Navigate
        to={getUrlToWsDashboard({
          orgId: workspace.organization_id,
          wsId: workspace.id,
        })}
      />
    );
  }

  if (orgs[0]?.id) {
    return (
      <Navigate
        to={{
          pathname: getWelcomeUrl(orgs[0].id),
        }}
      />
    );
  }

  return (
    <Navigate
      to={{
        pathname: "/error",
        search: `?${new URLSearchParams({
          error: "No workspace found",
        }).toString()}`,
      }}
    />
  );
};

export const PickWorkspace: React.FC = () => {
  const workspacesResult = useWorkspaces();
  const userOrganizationsResult = useUserOrganizations();

  return (
    <LoadingView
      queryResult={[workspacesResult, userOrganizationsResult]}
      renderUpdated={([wss, orgs]: [
        WorkspaceDataplane[],
        OrganizationInDB[],
      ]) => <NavigateToWorkspace orgs={orgs} workspaces={wss} />}
    />
  );
};
