import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";

import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";

type OverlayProps = {
  icon: IconProp;
  message: string;
  description: string;
  onRetry?: () => void;
};

export const Overlay: React.FC<OverlayProps> = ({
  icon,
  message,
  description,
  onRetry,
}) => {
  return (
    <div className="absolute left-0 top-0 z-10 grid h-full w-full content-start justify-items-center bg-white bg-opacity-80 p-16">
      <div className="mb-4 rounded-lg bg-indigo-100 p-3 text-indigo-600">
        <Icon icon={icon} size="xs" />
      </div>
      <div className="mb-6 w-15 text-center">
        <div className="mb-2 text-gray-800 font-inter-semibold-13px">
          {message}
        </div>
        <div className="text-gray-500 font-inter-normal-12px">
          {description}
        </div>
      </div>
      {onRetry && (
        <Button iconLeft={faRefresh} variant="secondary" onClick={onRetry}>
          Retry
        </Button>
      )}
    </div>
  );
};
