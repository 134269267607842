import { autocompletion } from "@codemirror/autocomplete";
import { useCallback, useEffect, useId, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

import { CodeEditor } from "src/base-components/CodeInput/CodeEditor";
import { jsonToPon, ponToJson } from "src/datasets/DatasetTable/utils";
import { Callout } from "src/design-system/Callout";

type PonEditorProps = {
  value: string;
  onChange?: (value: string) => void;
};

const format = (value: string) => {
  const jsonValue = ponToJson(value);
  try {
    const jsonValueFormatted = JSON.stringify(JSON.parse(jsonValue), null, 2);

    return jsonToPon(jsonValueFormatted);
  } catch (e) {
    return value;
  }
};

const unformat = (value: string) => {
  const jsonValue = ponToJson(value);
  try {
    const jsonValueUnformatted = JSON.stringify(JSON.parse(jsonValue));

    return jsonToPon(jsonValueUnformatted);
  } catch (e) {
    return value;
  }
};

const COMPLETION_EXTENSION = autocompletion({
  override: [() => null],
});
export const PonEditor: React.FC<PonEditorProps> = ({
  value: propValue,
  onChange,
}) => {
  const [value, setValue] = useState(() => format(propValue));
  const [error, setError] = useState<{
    message: string;
    line: number | undefined;
  } | null>(null);

  useEffect(() => {
    setValue(format(propValue));
  }, [propValue]);

  const id = useId();

  const validate = useCallback(
    (value: string | undefined) => {
      if (!value) return;

      try {
        JSON.parse(ponToJson(value));
      } catch (e: any) {
        if (e instanceof SyntaxError) {
          const lineNumberStr = e.message.match(/line (\d+)/)?.[1];

          setError({
            message: e.message,
            line: lineNumberStr ? parseInt(lineNumberStr, 10) : undefined,
          });
        } else {
          setError({
            message: "Invalid JSON format. Please correct the JSON format.",
            line: undefined,
          });
        }
      }
    },
    [setError],
  );

  const validateDebounced = useDebounceCallback(validate, 1000);

  return (
    <div className="relative flex h-full flex-col gap-y-2 overflow-hidden">
      {error && <Callout type="error">{error.message}</Callout>}
      <CodeEditor
        // Disable autocompletion
        completionExtension={COMPLETION_EXTENSION}
        dataLoc={`pon-editor-${id}`}
        errorHighlightedLine={error?.line}
        language="python"
        value={value}
        autoFocus
        highlightOnFocus
        onBlur={() => {
          const newValue = unformat(value) ?? "";
          if (newValue !== propValue) {
            onChange?.(newValue);
          }
        }}
        onChange={(value: string | undefined) => {
          setError(null);
          validateDebounced(value);
          return setValue(value ?? "");
        }}
      />
    </div>
  );
};
