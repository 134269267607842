import { Root } from "@radix-ui/react-accordion";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { AdvancedConfig } from "src/aiNode/editorComponents/AdvancedConfig";
import { PromptEditor } from "src/aiNode/editorComponents/PromptEditor";
import { ResponseMapping } from "src/aiNode/editorComponents/ResponseMapping";
import { AiNodeForm, BasicModelCard } from "src/aiNode/types";
import { useAvailableAiModels } from "src/api/connectApi/queries";
import { useWorkspace } from "src/api/queries";
import { FieldErrorsT } from "src/api/types";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { LoadingView } from "src/base-components/LoadingView";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import { AiNode, AiNodeDataT } from "src/constants/NodeDataTypes";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { convertFieldErrorsBeToFe } from "src/utils/FieldErrorUtils";
import { useSubmitForm } from "src/utils/useSubmitForm";

type WrapperPropsT = {
  node: AiNode;
  workspaceId: string;
} & NodeEditorBaseProps<AiNodeDataT>;

export const AiNodeEditor: React.FC<WrapperPropsT> = ({
  immutable,
  node,
  workspaceId,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const workspace = useWorkspace(workspaceId);
  const availableModels = useAvailableAiModels(workspace.data?.base_url);
  return (
    <LoadingView
      queryResult={availableModels}
      renderUpdated={(availableModels: BasicModelCard[]) => (
        <AiNodeEditorInternal
          availableModels={availableModels}
          displayedError={displayedError}
          immutable={immutable}
          isReactive={isReactive}
          node={node}
          onUpdate={onUpdate}
        />
      )}
    />
  );
};

type PropsT = {
  immutable: boolean;
  node: AiNode;
  availableModels: BasicModelCard[];
} & NodeEditorBaseProps<AiNodeDataT>;

const AiNodeEditorInternal: React.FC<PropsT> = ({
  availableModels,
  immutable,
  node,
  displayedError,
  isReactive,
  onUpdate,
}) => {
  const { renderedInDiffView } = useDiffViewContext();
  const currentFormValues: AiNodeForm = {
    model: node.data.model,
    prompts: node.data.prompts,
    response_config: node.data.response_config,
    inference_config: node.data.inference_config,
  };

  const formProps = useForm({
    defaultValues: currentFormValues,
    ...(isReactive && { values: currentFormValues }),
  });

  useSubmitForm({
    onChange: (data: AiNodeForm) => {
      onUpdate({ newData: data });
    },
    disabled: isReactive,
    previousValues: currentFormValues,
    watch: formProps.watch,
  });

  const runFieldErrors: FieldErrorsT | undefined = displayedError?.field_errors
    ? convertFieldErrorsBeToFe(displayedError.field_errors)
    : undefined;

  return (
    <FormProvider {...formProps}>
      <form>
        <Root
          className={accordionRootClassName}
          defaultValue={["prompts", "response_config", "inference_config"]}
          type="multiple"
        >
          <AccordionItem
            className="pb-6 pr-6"
            disabled={immutable || renderedInDiffView}
            title="Prompt"
            value="prompts"
          >
            <PromptEditor immutable={immutable} isReactive={isReactive} />
          </AccordionItem>
          <AccordionItem
            className="pb-6 pr-6"
            disabled={immutable || renderedInDiffView}
            title="Configure AI response"
            value="response_config"
          >
            <ResponseMapping
              immutable={immutable}
              runFieldErrors={runFieldErrors}
            />
          </AccordionItem>
          <AccordionItem
            className="pb-6 pr-6"
            disabled={immutable || renderedInDiffView}
            title="Advanced settings"
            value="inference_config"
          >
            <AdvancedConfig availableModels={availableModels} />
          </AccordionItem>
        </Root>
      </form>
    </FormProvider>
  );
};
