import { Edge, getIncomers, getOutgoers } from "reactflow";
import { v4 as uuidV4 } from "uuid";

import { GenericObjectT, GraphT, NodeBET } from "src/api/flowTypes";
import { getDefaultEnvironmentsConfig } from "src/baseConnectionNode/defaultValues";
import {
  AiNodeMeta,
  EdgeDb,
  FlowLoopMeta,
  NodeGroupDb,
  NodeGroupUpsert,
  NodeTypes,
} from "src/clients/flow-api";
import {
  getDefaultAssignmentNode,
  getDefaultCodeNode,
  getDefaultCustomConnectionNode,
  getDefaultDecisionTableNode,
  getDefaultFlowEdge,
  getDefaultFlowNode,
  getDefaultGroupNode,
  getDefaultGroupSeparatorNode,
  getDefaultInputNode,
  getDefaultIntegrationNode,
  getDefaultManualReviewNode,
  getDefaultMlNode,
  getDefaultOutputNode,
  getDefaultRuleNodeV2,
  getDefaultScorecardNode,
  getDefaultSplitBranchNodeV2,
  getDefaultSplitEdge,
  getDefaultSplitMergeNode,
  getDefaultSplitNodeV2,
  getDefaultInboundWebhookConnectionNode,
  getDefaultDatabaseConnectionNode,
  getDefaultLoopNode,
  getDefaultManifestConnectionNode,
  getDefaultAiNode,
} from "src/constants/DefaultValues";
import {
  AssignmentNodeDataT,
  BeMappedNode,
  CustomConnectionNode,
  DecisionTableNodeDataT,
  FlowNodeDataT,
  GroupNode,
  IntegrationNode,
  MLNode,
  NodeT,
  RuleNodeV2,
  ScorecardNodeDataT,
  SimpleNode,
  SplitMergeNodeDataT,
  SplitNodeV2,
  SplitNodeV2DataT,
  InboundWebhookConnectionNode,
  ManualReviewNodeDataT,
  DatabaseConnectionNode,
  LoopNodeDataT,
  ManifestConnectionNode,
  AiNode,
} from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { EDGE_TYPE } from "src/flowGraph/EdgeTypes";
import { IntegrationResourceBET } from "src/integrationNode/types";
import {
  integrationResourceBeToFe,
  integrationResourceFeToBe,
} from "src/integrationNode/utils";
import { ManifestIntegrationResourceBET } from "src/manifestConnectionNode/types";
import {
  manifestIntegrationResourceBeToFe,
  manifestIntegrationResourceFeToBe,
} from "src/manifestConnectionNode/utils";
import { logger } from "src/utils/logger";
import { assertUnreachable } from "src/utils/typeUtils";

type ReactFlowGraph = {
  nodes: BeMappedNode[];
  groups: GroupNode[];
  edges: Edge[];
};

// Util to map graph from BE adoption to FE and reverse-map the same
export class GraphTransform {
  public static nodeMapper = (node: NodeBET, _index?: number): BeMappedNode => {
    const nodeId = node.id;
    const nodeName = node.name;

    switch (node.type) {
      case NodeTypes.AI_NODE: {
        return getDefaultAiNode({
          name: nodeName,
          id: nodeId,
          meta: node.meta as AiNodeMeta,
        });
      }
      case NodeTypes.INPUT: {
        return getDefaultInputNode({
          name: nodeName,
          id: nodeId,
        });
      }
      case NodeTypes.TRANSFORM: {
        const isSplitMergeNode = node.meta?.is_split_merge_node ?? false;
        if (isSplitMergeNode) {
          return getDefaultSplitMergeNode({
            id: nodeId,
            name: nodeName,
          });
        }
        const isSplitBranchNode = node.meta?.is_split_branch_node ?? false;
        if (isSplitBranchNode) {
          return getDefaultSplitBranchNodeV2({
            id: nodeId,
            name: nodeName,
          });
        }
        const codeNodeSrc = node.meta?.src ?? "";
        return getDefaultCodeNode({
          name: nodeName,
          src: codeNodeSrc,
          id: nodeId,
        });
      }
      case NodeTypes.OUTPUT: {
        const outputNodeSrc = node.meta?.src ?? "";
        return getDefaultOutputNode({
          name: nodeName,
          src: outputNodeSrc,
          id: nodeId,
        });
      }
      case NodeTypes.SPLIT_2: {
        return getDefaultSplitNodeV2({
          id: nodeId,
          name: nodeName,
          branches: node.meta?.branches,
          defaultEdgeId: node.meta?.default_edge_id,
        });
      }
      case NodeTypes.RULE_2:
        return getDefaultRuleNodeV2({
          name: nodeName,
          branches: node.meta?.branches,
          defaultEffects: node.meta?.default_effects,
          id: nodeId,
        });
      case NodeTypes.DATA_INTEGRATION: {
        const integrationResourceFE = integrationResourceBeToFe(
          node.meta as IntegrationResourceBET,
        );

        return getDefaultIntegrationNode({
          integrationResource: integrationResourceFE,
          name: nodeName,
          id: nodeId,
        });
      }

      case NodeTypes.CUSTOM_CONNECTION_NODE: {
        // This ensures that the config object is updated with the
        // new schema format for the env-aware custom connection node

        // TODO: Remove this once the migration is complete
        const config = node.meta?.config;
        if (
          config?.testing?.use_fallback_live_connection &&
          !config?.environments_config
        ) {
          config.environments_config = {
            production: "production",
            sandbox_mode_data_source: "empty_response",
            authoring_mode_data_source: "production",
          };
        }

        return getDefaultCustomConnectionNode({
          providerResource: node.meta?.provider_resource,
          connectionId: node.meta?.connection_id,
          resourceConfigId: node.meta?.resource_config_id,
          mediaKey: node.meta?.media_key,
          optionals: {
            verb: node.meta?.verb,
            path: node.meta?.path,
            params: node.meta?.params,
            headers: node.meta?.headers,
            body: node.meta?.body,
            response: node.meta?.response,
            config,
          },
          name: nodeName,
          id: nodeId,
        });
      }

      case NodeTypes.MANIFEST_CONNECTION_NODE: {
        const manifestIntegrationResourceFE = manifestIntegrationResourceBeToFe(
          node.meta as ManifestIntegrationResourceBET,
        );
        return getDefaultManifestConnectionNode({
          manifestIntegrationResource: manifestIntegrationResourceFE,
          name: nodeName,
          id: nodeId,
        });
      }

      case NodeTypes.WEBHOOK_CONNECTION_NODE: {
        const config = node.meta?.config;
        // Making sure that for old nodes we always have at least the default config
        if (config && !config.environments_config) {
          config.environments_config = getDefaultEnvironmentsConfig();
        }

        return getDefaultInboundWebhookConnectionNode({
          providerResource: node.meta?.provider_resource,
          connectionId: node.meta?.connection_id,
          resourceConfigId: node.meta?.resource_config_id,
          mediaKey: node.meta?.media_key,
          optionals: {
            correlation_id: node.meta?.correlation_id,
            response: node.meta?.response,
            config: node.meta?.config,
          },
          name: nodeName,
          id: nodeId,
        });
      }

      case NodeTypes.SQL_DATABASE_CONNECTION_NODE: {
        // provider_response_time insight was added after initial DB node release.
        // Therefore not all existing DB nodes have it mapped to a node ID.
        const response = node.meta?.response;
        if (response && !response.provider_response_time?.id) {
          response.provider_response_time = {
            active: false,
            id: uuidV4(),
            mapped_to: "",
          };
        }
        return getDefaultDatabaseConnectionNode({
          providerResource: node.meta?.provider_resource,
          connectionId: node.meta?.connection_id,
          resourceConfigId: node.meta?.resource_config_id,
          optionals: {
            variables: node.meta?.variables,
            query: node.meta?.query,
            response: node.meta?.response,
            config: node.meta?.config,
          },
          name: nodeName,
          id: nodeId,
        });
      }

      case NodeTypes.ML: {
        return getDefaultMlNode({
          data: {
            src: node.meta?.src,
            model_id: node.meta?.model_id,
            model_filename: node.meta?.model_filename,
            description: node.meta?.description,
            graph_name: node.meta?.graph_name,
            producer_name: node.meta?.producer_name,
            version: node.meta?.version,
            author: node.meta?.author,
            training_data: node.meta?.training_data,
            inputs: node.meta?.inputs,
            outputs: node.meta?.outputs,
            custom_metadata: node.meta?.custom_metadata,
          },
          name: nodeName,
          id: nodeId,
        });
      }

      case NodeTypes.DECISION_TABLE: {
        return getDefaultDecisionTableNode({
          name: nodeName,
          id: nodeId,
          data: {
            predicate_fields: node.meta?.predicate_fields,
            effect_fields: node.meta?.effect_fields,
            cases: node.meta?.cases,
            fallback: node.meta?.fallback,
          },
        });
      }

      case NodeTypes.ASSIGNMENT_NODE: {
        return getDefaultAssignmentNode({
          name: nodeName,
          id: nodeId,
          default_effects: node.meta?.default_effects,
        });
      }

      case NodeTypes.SCORECARD: {
        return getDefaultScorecardNode({
          name: nodeName,
          id: nodeId,
          assign_field: node.meta.assign_field,
          equal_weights: node.meta.equal_weights,
          extract_scores: node.meta.extract_scores,
          factors: node.meta.factors,
        });
      }

      case NodeTypes.FLOW: {
        return getDefaultFlowNode({
          name: nodeName,
          id: nodeId,
          childFlowVersionId: node.meta?.child_flow_version_id,
          childFlowVersionKnownEtag: node.meta?.child_flow_version_known_etag,
          isStale: node.meta?.is_stale,
          childFlowId: node.meta?.child_flow_id,
          childFlowSlug: node.meta?.child_flow_slug,
          inputMappings: node.meta?.input_mappings,
          outputMappings: node.meta?.output_mappings,
        });
      }

      case NodeTypes.FLOW_LOOP: {
        return getDefaultLoopNode({
          name: nodeName,
          id: nodeId,
          child_flow_version_id: node.meta?.child_flow_version_id,
          child_flow_version_known_etag:
            node.meta?.child_flow_version_known_etag,
          is_stale: node.meta?.is_stale,
          child_flow_id: node.meta?.child_flow_id,
          child_flow_slug: node.meta?.child_flow_slug,
          input_mappings: node.meta?.input_mappings,
          output_destination_list_expression:
            node.meta.output_destination_list_expression,
          break_condition: node.meta.break_condition,
          target_list_expression: node.meta.target_list_expression,
        });
      }

      case NodeTypes.REVIEW_CONNECTION_NODE: {
        return getDefaultManualReviewNode({
          id: nodeId,
          name: nodeName,
          highlights: node.meta.highlights,
          response_form: node.meta.response_form,
          connection_id: node.meta.connection_id,
          providerResource: node.meta.provider_resource,
          resource_config_id: node.meta.resource_config_id,
          response_metadata_key: node.meta.response_metadata_key,
          reviewer_assign_strategy: node.meta.reviewer_assign_strategy,
          reviewer_assign_strategy_v2: node.meta.reviewer_assign_strategy_v2,
        });
      }

      default: {
        return assertUnreachable(node.type);
      }
    }
  };

  private static edgesMapper = (edge: EdgeDb): Edge => {
    const isSplitEdge = edge.meta?.is_split_edge ?? false;
    if (isSplitEdge) {
      return getDefaultSplitEdge({
        sourceId: edge.start_node_id,
        targetId: edge.end_node_id,
        id: edge.id,
      });
    }

    return getDefaultFlowEdge({
      sourceId: edge.start_node_id,
      targetId: edge.end_node_id,
      id: edge.id,
    });
  };

  public static groupMapper = (
    groupBe: NodeGroupDb,
    nodes: BeMappedNode[],
    edges: Edge[],
  ): GroupNode | undefined => {
    const { source, sink } = getSourceAndSink(
      groupBe.node_ids || [],
      nodes,
      edges,
    );
    if (source && sink) {
      return getDefaultGroupNode({
        id: groupBe.id,
        name: groupBe.name,
        childrenIDs: groupBe.node_ids || [],
        sourceChildId: source.id,
        sinkChildId: sink.id,
      });
    }
  };

  public static nodeReverseMapper = (node: BeMappedNode): NodeBET | null => {
    switch (node.type) {
      case NODE_TYPE.AI_NODE: {
        const { label, model, prompts, response_config, inference_config } = (
          node as AiNode
        ).data;
        return {
          id: node.id,
          name: label,
          type: NodeTypes.AI_NODE,
          meta: {
            model,
            prompts,
            response_config,
            inference_config,
          },
        };
      }
      case NODE_TYPE.INPUT_NODE: {
        return {
          id: node.id,
          name: "data",
          type: NodeTypes.INPUT,
          meta: {},
        };
      }
      case NODE_TYPE.CODE_NODE: {
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.TRANSFORM,
          meta: { src: (node as SimpleNode).data.src },
        };
      }
      case NODE_TYPE.OUTPUT_NODE: {
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.OUTPUT,
          meta: { src: (node as SimpleNode).data.src },
        };
      }
      case NODE_TYPE.SPLIT_NODE_V2: {
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.SPLIT_2,
          meta: {
            branches: (node as SplitNodeV2).data.branches,
            default_edge_id: (node as SplitNodeV2).data.default_edge_id,
          },
        };
      }
      case NODE_TYPE.SPLIT_BRANCH_NODE_V2: {
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.TRANSFORM,
          meta: { src: "", is_split_branch_node: true },
        };
      }
      case NODE_TYPE.SPLIT_MERGE_NODE: {
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.TRANSFORM,
          meta: {
            src: "",
            is_split_merge_node: true,
          },
        };
      }
      case NODE_TYPE.RULE_NODE_V2: {
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.RULE_2,
          meta: {
            branches: (node as RuleNodeV2).data.branches,
            default_effects: (node as RuleNodeV2).data.default_effects,
          },
        };
      }
      case NODE_TYPE.INTEGRATION_NODE: {
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.DATA_INTEGRATION,
          meta: integrationResourceFeToBe((node as IntegrationNode).data),
        };
      }
      case NODE_TYPE.MANIFEST_CONNECTION_NODE: {
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.MANIFEST_CONNECTION_NODE,
          meta: manifestIntegrationResourceFeToBe(
            (node as ManifestConnectionNode).data,
          ),
        };
      }
      case NODE_TYPE.ML_NODE: {
        const {
          label: name,
          src,
          model_id,
          description,
          graph_name,
          producer_name,
          version,
          author,
          training_data,
          inputs,
          outputs,
          model_filename,
          custom_metadata,
        } = (node as MLNode).data;
        return {
          id: node.id,
          name,
          type: NodeTypes.ML,
          meta: {
            src,
            model_id,
            description,
            graph_name,
            producer_name,
            version,
            author,
            training_data,
            inputs,
            outputs,
            model_filename,
            custom_metadata,
          },
        };
      }
      case NODE_TYPE.CUSTOM_CONNECTION_NODE: {
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.CUSTOM_CONNECTION_NODE,
          meta: {
            provider_resource: (node as CustomConnectionNode).data
              .providerResource,
            connection_id: (node as CustomConnectionNode).data.connectionId,
            resource_config_id: (node as CustomConnectionNode).data
              .resourceConfigId,
            verb: (node as CustomConnectionNode).data.verb,
            path: (node as CustomConnectionNode).data.path,
            params: (node as CustomConnectionNode).data.params,
            headers: (node as CustomConnectionNode).data.headers,
            response: (node as CustomConnectionNode).data.response,
            body: (node as CustomConnectionNode).data.body,
            config: (node as CustomConnectionNode).data.config,
            media_key: (node as CustomConnectionNode).data.mediaKey,
          },
        };
      }

      case NODE_TYPE.WEBHOOK_CONNECTION_NODE: {
        const webhookNode = node as InboundWebhookConnectionNode;

        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.WEBHOOK_CONNECTION_NODE,
          meta: {
            provider_resource: webhookNode.data.providerResource,
            connection_id: webhookNode.data.connectionId,
            resource_config_id: webhookNode.data.resourceConfigId,
            correlation_id: webhookNode.data.correlation_id,
            response: webhookNode.data.response,
            config: webhookNode.data.config,
            media_key: webhookNode.data.mediaKey,
          },
        };
      }

      case NODE_TYPE.SQL_DATABASE_CONNECTION_NODE: {
        const dbcNode = node as DatabaseConnectionNode;

        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.SQL_DATABASE_CONNECTION_NODE,
          meta: {
            provider_resource: dbcNode.data.providerResource,
            connection_id: dbcNode.data.connectionId,
            resource_config_id: dbcNode.data.resourceConfigId,
            variables: dbcNode.data.variables,
            query: dbcNode.data.query,
            response: dbcNode.data.response,
            config: dbcNode.data.config,
          },
        };
      }

      case NODE_TYPE.DECISION_TABLE_NODE: {
        const nodeData = node.data as DecisionTableNodeDataT;
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.DECISION_TABLE,
          meta: {
            predicate_fields: nodeData.predicate_fields,
            effect_fields: nodeData.effect_fields,
            cases: nodeData.cases,
            fallback: nodeData.fallback,
          },
        };
      }

      case NODE_TYPE.ASSIGNMENT_NODE: {
        const nodeData = node.data as AssignmentNodeDataT;
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.ASSIGNMENT_NODE,
          meta: {
            default_effects: nodeData.default_effects,
          },
        };
      }

      case NODE_TYPE.SCORECARD_NODE: {
        const nodeData = node.data as ScorecardNodeDataT;
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.SCORECARD,
          meta: nodeData,
        };
      }

      case NODE_TYPE.FLOW_NODE: {
        const nodeData = node.data as FlowNodeDataT;
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.FLOW,
          meta: {
            child_flow_version_id: nodeData.child_flow_version_id,
            is_stale: nodeData.is_stale,
            child_flow_version_known_etag:
              nodeData.child_flow_version_known_etag,
            child_flow_id: nodeData.child_flow_id,
            child_flow_slug: nodeData.child_flow_slug,
            input_mappings: nodeData.input_mappings,
            output_mappings: nodeData.output_mappings,
          },
        };
      }

      case NODE_TYPE.REVIEW_CONNECTION_NODE: {
        const nodeData = node.data as ManualReviewNodeDataT;
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.REVIEW_CONNECTION_NODE,
          meta: {
            highlights: nodeData.highlights,
            resource_config_id: nodeData.resource_config_id,
            provider_resource: nodeData.providerResource,
            response_form: nodeData.response_form,
            connection_id: nodeData.connection_id,
            response_metadata_key: nodeData.response_metadata_key,
            reviewer_assign_strategy: nodeData.reviewer_assign_strategy,
            reviewer_assign_strategy_v2: nodeData.reviewer_assign_strategy_v2,
          },
        };
      }

      case NODE_TYPE.LOOP_NODE: {
        const nodeData = node.data as LoopNodeDataT;
        const meta: FlowLoopMeta = {
          child_flow_version_id: nodeData.child_flow_version_id,
          child_flow_version_known_etag: nodeData.child_flow_version_known_etag,
          is_stale: nodeData.is_stale,
          input_mappings: nodeData.input_mappings,
          output_destination_list_expression:
            nodeData.output_destination_list_expression,
          break_condition: nodeData.break_condition,
          target_list_expression: nodeData.target_list_expression,
          child_flow_id: nodeData.child_flow_id,
          child_flow_slug: nodeData.child_flow_slug,
        };
        return {
          id: node.id,
          name: node.data.label,
          type: NodeTypes.FLOW_LOOP,
          meta: meta,
        };
      }

      default: {
        return assertUnreachable(node.type);
      }
    }
  };

  public static edgesReverseMapper = (edge: Edge): EdgeDb => {
    return {
      id: edge.id,
      start_node_id: edge.source,
      end_node_id: edge.target,
      meta: { is_split_edge: edge.type === EDGE_TYPE.SPLIT_EDGE },
    };
  };

  public static groupReverseMapper = (group: GroupNode): NodeGroupUpsert => {
    const groupData = group.data;
    return {
      id: group.id,
      name: groupData.label,
      node_ids: groupData.childrenIDs,
    };
  };

  public static map = (graph: GraphT): ReactFlowGraph => {
    const nodes = graph.nodes.map(this.nodeMapper);
    const edges: Edge[] = graph.edges.map(this.edgesMapper);
    const groups = graph.node_groups
      .map((beGroup) => this.groupMapper(beGroup, nodes, edges))
      .filter((group) => group !== undefined) as GroupNode[];
    return { nodes, groups, edges };
  };
}

export const getIncomingEdges = (node: NodeT, edges: Edge[]): Edge[] =>
  edges.filter((edge: Edge) => edge.target === node.id);

export const getOutgoingEdges = (node: NodeT, edges: Edge[]): Edge[] =>
  edges.filter((edge: Edge) => edge.source === node.id);

//This function returns the merge node that closes a given split node.
//This is achieved by going down one branch that originates at the
//Split Node and counting how many split nodes are opened (+1) and
//closed (-1) until the one that was opened first is closed (0 is reached).
export const findSplitMergeNode = (
  splitNode: SplitNodeV2,
  nodes: BeMappedNode[],
  edges: Edge[],
): BeMappedNode | undefined => {
  let splitNodesToBeMerged = 1;
  let currNode: BeMappedNode | undefined = getOutgoers<
    SplitMergeNodeDataT | SplitNodeV2DataT
  >(splitNode, nodes, edges)[0] as BeMappedNode;
  if (currNode?.type === NODE_TYPE.SPLIT_MERGE_NODE) return currNode;

  while (splitNodesToBeMerged > 0) {
    if (!currNode) return;
    currNode = getOutgoers(currNode, nodes, edges)[0] as BeMappedNode;
    if (currNode.type === NODE_TYPE.SPLIT_NODE_V2) splitNodesToBeMerged++;
    if (currNode.type === NODE_TYPE.SPLIT_MERGE_NODE) splitNodesToBeMerged--;
  }
  return currNode;
};

export const findBranchNodes = (
  startingNode: BeMappedNode,
  nodes: BeMappedNode[],
  edges: Edge[],
  branchNodes: Set<BeMappedNode>,
  parentSplitNode: SplitNodeV2,
  excludeNestedMergeNodes: boolean = false,
): void => {
  const mergeNode = findSplitMergeNode(parentSplitNode, nodes, edges);

  const recursivelyFindBranchNodes = (currNode: BeMappedNode) => {
    if (!mergeNode) return;
    if (currNode.id === mergeNode.id) {
      return;
    }
    if (
      currNode.type !== NODE_TYPE.SPLIT_MERGE_NODE ||
      !excludeNestedMergeNodes
    ) {
      branchNodes.add(currNode);
    }
    for (let node of getOutgoers(currNode, nodes, edges) as BeMappedNode[]) {
      recursivelyFindBranchNodes(node);
    }
  };

  recursivelyFindBranchNodes(startingNode);
};

export const findBranchesAndNodes = (
  splitNode: SplitNodeV2,
  nodes: BeMappedNode[],
  edges: Edge[],
  excludeNestedMergeNodes: boolean = false,
): BeMappedNode[][] => {
  if (splitNode.type && splitNode.type !== NODE_TYPE.SPLIT_NODE_V2) {
    return [];
  }
  const branchToBranchNodes = [];
  for (let branchNode of getOutgoers<SplitMergeNodeDataT | SplitNodeV2DataT>(
    splitNode,
    nodes,
    edges,
  ) as BeMappedNode[]) {
    const nodesOnBranch = new Set<BeMappedNode>();
    findBranchNodes(
      branchNode,
      nodes,
      edges,
      nodesOnBranch,
      splitNode,
      excludeNestedMergeNodes,
    );
    branchToBranchNodes.push(Array.from(nodesOnBranch));
  }
  return branchToBranchNodes;
};

export const isNodeSelectable = (node: BeMappedNode) =>
  !(
    node.type === NODE_TYPE.SPLIT_MERGE_NODE ||
    node.type === NODE_TYPE.SPLIT_BRANCH_NODE_V2
  );

export const getSelectableParentNode = (
  currentNode: BeMappedNode | undefined,
  nodes: BeMappedNode[],
  edges: Edge[],
): BeMappedNode | undefined => {
  if (currentNode) {
    if (isNodeSelectable(currentNode)) {
      return currentNode;
    }
    for (let node of getIncomers(currentNode, nodes, edges) as BeMappedNode[]) {
      return getSelectableParentNode(node, nodes, edges);
    }
  }
  return currentNode;
};

export const orderNodesAndEdgesForLayout = (
  nodes: NodeT[],
  edges: Edge[],
): [nodes: NodeT[], edges: Edge[]] => {
  // Nodes and Edges in state are stored in order of their creation
  // Most recently added nodes and edges would be towards end of the list
  // This function discovers the graph using DFS and passes the nodes and edges
  // in discovered order for dagre to draft layout
  const root =
    nodes.find((node) => node.type === NODE_TYPE.INPUT_NODE) ??
    nodes.find((node) => getIncomers(node, nodes, edges).length === 0);

  if (root) {
    // Stores order in which nodes are discovered using DFS
    const orderedNodes: NodeT[] = [];
    const orderedEdges: Edge[] = [];
    // Creates required mapping to efficiently find nodes and edges
    // as required for traversal
    const nodesMap: Map<string, NodeT> = new Map();
    const edgesMap: Map<string, Edge> = new Map();
    const nodeToOutgoingEdgesMap: Map<string, string[]> = new Map();

    nodes.forEach((node) => {
      nodesMap.set(node.id, node);
    });
    edges.forEach((edge) => {
      edgesMap.set(edge.id, edge);
      const sourceNode = nodesMap.get(edge.source);
      if (sourceNode) {
        // If its a split node add the branch edge ids in the order they are sorted in the split node
        if ("default_edge_id" in sourceNode.data) {
          nodeToOutgoingEdgesMap.set(sourceNode.id, [
            ...sourceNode.data.branches.map((branch) => branch.edge_id),
          ]);
        } else if (nodeToOutgoingEdgesMap.has(sourceNode.id)) {
          // this should never reach in present scenario, but added for generalisation
          const existingEdges = nodeToOutgoingEdgesMap.get(sourceNode.id);
          existingEdges &&
            nodeToOutgoingEdgesMap.set(sourceNode.id, [
              ...existingEdges,
              edge.id,
            ]);
        } else {
          // cases for other node types
          nodeToOutgoingEdgesMap.set(sourceNode.id, [edge.id]);
        }
      }
    });

    // Stores visited nodes and edges while doing DFS
    const visitedNodes = new Set<string>();
    const visitedEdges = new Set<string>();
    // DFS code
    const stack = [root];
    while (stack.length > 0) {
      const frontNode = stack.pop();
      if (frontNode) {
        if (!visitedNodes.has(frontNode.id)) {
          orderedNodes.push(frontNode);
          visitedNodes.add(frontNode.id);
        }
        const outgoingEdges = nodeToOutgoingEdgesMap.get(frontNode.id) ?? [];
        outgoingEdges.forEach((edgeId: string) => {
          if (!visitedEdges.has(edgeId)) {
            const mappedEdge = edgesMap.get(edgeId);
            if (mappedEdge) {
              orderedEdges.push(mappedEdge);
              const outgoingNode = nodesMap.get(mappedEdge.target);
              outgoingNode && stack.push(outgoingNode);
            }
            visitedEdges.add(edgeId);
          }
        });
      }
    }

    if (
      orderedNodes.length !== nodes.length ||
      orderedEdges.length !== edges.length
    ) {
      // Code should never reach here unless the graph is disconnected
      logger.error(
        "Terrible Failure:",
        "Couldn't discover entire graph, might be disconnected",
        orderedNodes,
        nodes,
        orderedEdges,
        edges,
      );
      return [nodes, edges];
    }
    return [orderedNodes, orderedEdges];
  } else {
    // Fallback case
    return [nodes, edges];
  }
};

/**
 * Together nodes and edges represent a subgraph. To insert this into a graph we need to add an incoming edge to the first node, and an outgoing edge from the last node.
 */
export type Subgraph = {
  /**
   * Nodes: store all the nodes flat in an array. Order is important, the first node should be the root and the last sink of the subgraph.
   */
  nodes: BeMappedNode[];
  /**
   * Edges: store all the edges between these nodes.
   */
  edges: Edge[];
  groups: GroupNode[];
};

export type SubGraphClipboard = Subgraph & { workspaceId: string };

/**
 * Creates new IDs for all the given nodes and edges
 * @param  nodes The nodes which should get a new ID
 * @param  edges The edges which should get a new ID
 */
export const getNodesAndEdgesWithNewIDs = ({
  nodes,
  edges,
  groups,
}: Subgraph): Subgraph => {
  // These objects store the new IDs which map to the old IDs
  const oldToNewNodeIDs: GenericObjectT = {};
  const oldToNewEdgeIDs: GenericObjectT = {};

  // Give each node a new ID
  const nodesWithNewIDs = nodes.map((node) => {
    const newId = uuidV4();
    oldToNewNodeIDs[node.id] = newId;
    return { ...node, id: newId };
  });

  // Give each edge a new ID, and while doing so update the source and target IDs to the new node IDs
  const edgesWithNewIDs = edges.map((edge) => {
    const newId = uuidV4();
    oldToNewEdgeIDs[edge.id] = newId;
    return {
      ...edge,
      id: newId,
      source: oldToNewNodeIDs[edge.source],
      target: oldToNewNodeIDs[edge.target],
    };
  });

  // For splitnodes: Update the edge_id and default_edge_id to the new edge IDs
  const nodesWithUpdatedSplitNodes = nodesWithNewIDs.map((node) => {
    if (node.type === NODE_TYPE.SPLIT_NODE_V2) {
      return {
        ...node,
        data: {
          ...node.data,
          branches: (node as SplitNodeV2).data.branches.map((branch) => ({
            ...branch,
            edge_id: oldToNewEdgeIDs[branch.edge_id],
          })),
          default_edge_id:
            oldToNewEdgeIDs[(node as SplitNodeV2).data.default_edge_id],
        },
      } as SplitNodeV2;
    }
    return node;
  });

  const groupsWithUpdatedChildren: GroupNode[] = groups.map((group) => {
    const newGroupId = uuidV4();
    return {
      ...group,
      id: newGroupId,
      data: {
        ...group.data,
        childrenIDs: group.data.childrenIDs.map((id) => oldToNewNodeIDs[id]),
        sourceChildId: oldToNewNodeIDs[group.data.sourceChildId],
        sinkChildId: oldToNewNodeIDs[group.data.sinkChildId],
        uiState: {
          ...group.data.uiState,
          incomingEdgeId: uuidV4(),
          outgoingEdgeId: uuidV4(),
          preSeparatorNode: getDefaultGroupSeparatorNode({
            type: NODE_TYPE.GROUP_PRE_SEPARATOR_NODE,
            parentNodeId: newGroupId,
          }),
          postSeparatorNode: getDefaultGroupSeparatorNode({
            type: NODE_TYPE.GROUP_POST_SEPARATOR_NODE,
            parentNodeId: newGroupId,
          }),
        },
      },
    };
  });

  return {
    nodes: nodesWithUpdatedSplitNodes,
    edges: edgesWithNewIDs,
    groups: groupsWithUpdatedChildren,
  };
};

/**
 * Returns the edges which start and end in the given nodes
 * @param  allEdges A list of edges
 * @param  nodes The nodes whose edges we want to get
 */
export const getEdgesInSubgraph = (
  allEdges: Edge[],
  nodes: NodeT[],
): Edge[] => {
  return allEdges.filter(
    (edge) =>
      nodes.find((node) => node.id === edge.source) &&
      nodes.find((node) => node.id === edge.target),
  );
};

/**
 * Returns true if the graph has exactly one node without incoming edges and all other nodes are reachable from that node.
 */
export const graphHasRootAndIsConnected = (
  edges: Edge[],
  nodes: BeMappedNode[],
): boolean => {
  // copy the array to allow adding and removing nodes without side effects
  nodes = [...nodes];
  const nodesWithNoIncomers = nodes.filter(
    (node) => getIncomers(node, nodes, edges).length === 0,
  );

  if (nodesWithNoIncomers.length !== 1) return false;
  const originNode = nodesWithNoIncomers[0];
  const enqueuedEdges: Edge[] = [];

  enqueuedEdges.push(...getOutgoingEdges(originNode, edges));
  nodes.splice(nodes.indexOf(originNode), 1);
  while (enqueuedEdges.length > 0) {
    const currentNode = nodes.find(
      (node) => node.id === enqueuedEdges[0].target,
    );
    if (currentNode) {
      enqueuedEdges.push(
        ...edges.filter((edge) => edge.source === currentNode.id),
      );
      nodes.splice(nodes.indexOf(currentNode), 1);
    }
    enqueuedEdges.splice(0, 1);
  }

  return nodes.length === 0;
};

type getUpdatedNodeGroupParameter = {
  group: GroupNode;
  updatedChildrenIds: string[];
  newSourceChildId?: string;
  newSinkChildId?: string;
};

/**
 * Returns a group updated with the new childrenIds.
 */
export const getUpdatedGroup = ({
  group,
  updatedChildrenIds,
  newSourceChildId,
  newSinkChildId,
}: getUpdatedNodeGroupParameter): GroupNode => ({
  ...group,
  data: {
    ...group.data,
    childrenIDs: updatedChildrenIds,
    ...(newSourceChildId && { sourceChildId: newSourceChildId }),
    ...(newSinkChildId && { sinkChildId: newSinkChildId }),
  },
});

export const getParentGroup = (
  groups: Map<string, GroupNode>,
  nodeId: string,
): GroupNode | undefined =>
  Array.from(groups.values()).find((group) =>
    group.data.childrenIDs.some((childId) => childId === nodeId),
  );

export const getSourceAndSink = (
  subgraphNodeIds: string[],
  nodes: BeMappedNode[],
  edges: Edge[],
): { source: BeMappedNode | undefined; sink: BeMappedNode | undefined } => {
  const groupNodes = nodes.filter((node) =>
    subgraphNodeIds.some((childId) => childId === node.id),
  );
  const subGraphEdges = getEdgesInSubgraph(edges, groupNodes);
  const source = groupNodes.find(
    (node) => getIncomers(node, groupNodes, subGraphEdges).length === 0,
  );
  const sink = groupNodes.find(
    (node) => getOutgoers(node, groupNodes, subGraphEdges).length === 0,
  );
  return { source, sink };
};
// If the node has a parentNode its position is relative to the parent, thus we need to add the parent nodes x position to get an absolute value
export const getAbsoluteXPosition = (
  node: BeMappedNode,
  groups: Map<string, GroupNode>,
): number => {
  let groupXOffset = 0;
  // On expanded groups the parentNode id will be set
  if (node.parentNode) {
    const group = groups.get(node.parentNode);
    if (group) {
      groupXOffset = group.position.x;
    }
  } else {
    // On collapsed groups we need to search all the children ids in all the groups
    const group = getParentGroup(groups, node.id);
    if (group) {
      groupXOffset = group.position.x;
    }
  }
  return node.position.x + groupXOffset;
};
