import {
  faBuilding,
  faCalculatorSimple,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import { formatDialect, trino, hive } from "sql-formatter";

import { Pill, PillVariants } from "src/base-components/Pill";
import { Cell, Header } from "src/base-components/Table";
import {
  ColumnMapping,
  EntityMapping,
  FeatureQueryEnvironmentSetting,
  FeatureQueryStatus,
  FeatureResponse,
  PreviewQueryRequestEnvironmentEnum,
} from "src/clients/features-control";
import { formatDuration as formatDurationUtil } from "src/utils/numbers";

export const STATUS_COLOR_MAP: Record<FeatureQueryStatus, PillVariants> = {
  active: "green",
  inactive: "gray",
} as const;

export type Entity = "card" | "account" | "company";
export const ENTITY_ICON_MAP = {
  card: faCreditCard,
  account: faUser,
  company: faBuilding,
} as const;

export type Query = {
  id: string;
  environment_settings: Record<string, FeatureQueryEnvironmentSetting>;
  event: string;
  query: string;
  backfilling_query: string;
  columns: ColumnMapping;
  window_hop: number;
  window_length: number;
  created_at: string;
  updated_at: string;
  finalized_at?: string;
  etag: string;
};

export type Common = {
  id: string;
  column: string;
  window_hop: number;
  window_length: number;
  status: FeatureQueryStatus;
  entities: Entity[];
  event: string;
  query: string;
};

export type Feature = FeatureResponse;

export type QueryRow = Common;
export type FeatureRow = Feature;

export const formatDuration = (seconds: number): string => {
  return formatDurationUtil(seconds * 1000, {
    locale: "long",
    format: ["years", "months", "weeks", "days", "hours", "minutes", "seconds"],
  });
};

export const commonColumns = [
  {
    accessorKey: "event",
    header: (info: any) => <Header info={info}>Event</Header>,
    cell: (info: any) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Icon icon={faCalculatorSimple} />
          <Pill.Text>{info.getValue()}</Pill.Text>
        </Pill>
      </Cell>
    ),
  },
  {
    accessorKey: "columns.entities",
    header: (info: any) => <Header info={info}>Entity</Header>,
    cell: (info: any) => (
      <Cell className="flex space-x-2" info={info}>
        {info.getValue()?.map((entity: EntityMapping) => {
          const icon =
            ENTITY_ICON_MAP[
              entity.entity_name as keyof typeof ENTITY_ICON_MAP
            ] ?? faBuilding;
          return (
            <Pill key={entity.entity_name} size="sm" variant="gray">
              <Pill.Icon icon={icon} />
              <Pill.Text>{capitalize(entity.entity_name)}</Pill.Text>
            </Pill>
          );
        })}
      </Cell>
    ),
  },
  {
    accessorKey: "window_hop",
    header: (info: any) => <Header info={info}>Time window</Header>,
    cell: (info: any) => (
      <Cell info={info}>{formatDuration(info.getValue())}</Cell>
    ),
  },
  {
    accessorKey: "window_length",
    header: (info: any) => <Header info={info}>Freshness</Header>,
    cell: (info: any) => (
      <Cell info={info}>{formatDuration(info.getValue())}</Cell>
    ),
  },
];

export const formatAthenaSqlQuery = (sql: string | undefined) => {
  if (!sql) return "";

  try {
    return formatDialect(sql, { dialect: trino });
  } catch (e) {
    try {
      return formatDialect(sql, { dialect: hive });
    } catch (e) {
      return sql;
    }
  }
};

export type OutletContextT = {
  type: "header" | "content";
  searchQuery?: string;
};

export const isActiveQuery = (
  query: Query,
  environment: PreviewQueryRequestEnvironmentEnum,
) => {
  return (
    query.environment_settings[environment].status === FeatureQueryStatus.ACTIVE
  );
};

export const isDisabledQuery = (
  query: Query,
  environment: PreviewQueryRequestEnvironmentEnum,
) => {
  if (query.finalized_at) return true;

  return isActiveQuery(query, environment);
};
