import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { AnimatePresence, m } from "framer-motion";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import {
  DateRangePicker,
  DateRangePickerResetable,
  DateRangePickerProps,
} from "src/base-components/DateRangePicker";
import { Icon } from "src/base-components/Icon";
import { SearchBoxPropsT } from "src/base-components/SearchBox";
import { Tooltip } from "src/design-system/Tooltip";

type ButtonProps = {
  as?: "button" | "div";
  icon: IconProp;
  tooltip: string;
  disabled?: boolean;
  dataLoc?: string;
  spin?: boolean;
  onClick: () => void;
};

export type ButtonType = React.FC<ButtonProps>;

export const SubHeaderButton: ButtonType = ({
  disabled,
  icon,
  tooltip,
  dataLoc,
  spin,
  as: Tag = "button",
  onClick,
}) => {
  return (
    <Tooltip delay={350} placement="bottom" title={tooltip} asChild>
      <Tag
        className="border-l border-gray-200 p-3.5 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500/25 disabled:cursor-not-allowed disabled:bg-gray-50"
        data-loc={dataLoc}
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        <Icon
          color={
            disabled ? "text-gray-300" : "text-gray-500 hover:text-gray-700"
          }
          icon={icon}
          size="xs"
          spin={spin}
        />
      </Tag>
    </Tooltip>
  );
};

type SearchBoxProps = Omit<SearchBoxPropsT, "value"> & { tooltip?: string };
export type SearchBoxType = React.FC<SearchBoxProps>;

export const SubHeaderSearchBox: SearchBoxType = ({
  dataLoc,
  placeholder,
  defaultValue,
  tooltip,
  onChange,
}) => {
  const [hasFocus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const isOpen =
    hasFocus || Boolean(defaultValue) || Boolean(inputRef.current?.value);
  const debouncedOnChange = debounce(onChange, 300);

  return (
    <Tooltip
      disabled={!Boolean(tooltip)}
      placement="bottom"
      title={tooltip}
      asChild
    >
      <div
        className={twJoin(
          "flex h-full cursor-pointer items-center overflow-hidden border-l border-gray-200 px-3.5 py-1",
          hasFocus && "ring-2 ring-inset ring-indigo-500/25",
        )}
        data-loc={`${dataLoc}-button`}
        onClick={() => {
          setFocus(true);
          inputRef.current?.focus();
        }}
      >
        <Icon color="text-gray-500" icon={faMagnifyingGlass} size="xs" />
        <AnimatePresence>
          <m.div
            animate={isOpen ? "open" : "closed"}
            className="h-full"
            initial={isOpen ? "open" : "closed"}
            transition={{ type: "tween", ease: "easeOut" }}
            variants={{
              open: { width: "14rem" },
              closed: { width: "0" },
            }}
          >
            <input
              ref={inputRef}
              className="h-full w-full bg-white pl-2 font-inter-normal-12px placeholder:text-gray-500 focus:outline-none"
              data-loc={dataLoc}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onBlur={() => setFocus(false)}
              onChange={(event) => debouncedOnChange(event.target.value)}
              onFocus={() => setFocus(true)}
            />
          </m.div>
        </AnimatePresence>
      </div>
    </Tooltip>
  );
};

type DatePickerProps = Pick<
  DateRangePickerProps,
  "onChange" | "value" | "placeholder" | "rangeLimitInDays" | "disabled"
> &
  DateRangePickerResetable;

export type DatePickerType = React.FC<DatePickerProps>;

export const SubHeaderDatePicker: DatePickerType = ({
  onChange,
  placeholder,
  value,
  disabled,
  ...resetableProps
}) => {
  return (
    <div
      className={twJoin(
        "flex h-full min-w-[15.5rem] items-center border-l border-gray-200 px-1.5",
        "[&:has(span[data-open])]:ring-2 [&:has(span[data-open])]:ring-inset [&:has(span[data-open])]:ring-indigo-500/25",
      )}
    >
      <DateRangePicker
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        borderless
        suffixIcon
        onChange={onChange}
        {...resetableProps}
      />
    </div>
  );
};

export const SubHeaderTabs: React.FC<{
  children: React.ReactNode;
  dataLoc?: string;
}> = ({ children, dataLoc }) => (
  <nav className="flex h-full gap-x-4 bg-white" data-loc={dataLoc}>
    {children}
  </nav>
);

type TabProps = {
  to: string;
  children: React.ReactNode;
  icon?: IconProp;
  end?: boolean;
};

export const SubHeaderTab: React.FC<TabProps> = ({
  to,
  children,
  icon,
  end,
}) => (
  <NavLink
    className="relative inline-flex h-full items-center"
    end={end}
    to={to}
  >
    {({ isActive }) => (
      <>
        <div className="relative flex h-full items-center px-1 text-gray-800 font-inter-semibold-13px">
          <div className="flex items-center gap-x-1.5">
            {icon && <Icon color="text-gray-500" icon={icon} size="xs" />}
            <span>{children}</span>
          </div>
        </div>
        {isActive && (
          <div className="absolute bottom-0 h-[3px] w-full rounded-full bg-indigo-600" />
        )}
      </>
    )}
  </NavLink>
);

type Props = {
  leftSlot?: React.ReactNode;
  children?: React.ReactNode;
};

export const SubHeader: React.FC<Props> = ({ leftSlot, children }) => {
  return (
    <div className="flex h-12 items-center justify-between gap-x-2 border-b border-l border-gray-200 bg-white pl-4">
      {leftSlot}
      {children && (
        <div className="ml-auto flex h-full items-center overflow-clip">
          {children}
        </div>
      )}
    </div>
  );
};
