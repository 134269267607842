import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import {
  featuresApi as featuresApiCreator,
  featureQueriesApi as featureQueriesApiCreator,
} from "src/api/endpoints";
import {
  FeatureQueryCreate,
  FeatureQuery,
  FeatureQueryPut,
  PreviewQueryResponse,
  PreviewQueryRequestEnvironmentEnum,
  SetFeatureQueryStatusRequest,
} from "src/clients/features-control";
import { FeatureForm } from "src/featureCatalogue/Features/ManageFeatureModal";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

const PREFIX_CACHE_KEY = "features";

export const useFeatures = () => {
  const { workspace } = useWorkspaceContext();
  const featuresApi = featuresApiCreator(workspace.base_url!);

  return useQuery({
    queryKey: [PREFIX_CACHE_KEY, workspace.id],
    queryFn: () => featuresApi.getFeaturesFeaturesGet(),
  });
};

export const useFeatureByKey = (key: string) => {
  const { workspace } = useWorkspaceContext();
  const featuresApi = featuresApiCreator(workspace.base_url!);
  return useQuery({
    queryKey: [PREFIX_CACHE_KEY, workspace.id, key],
    queryFn: () => featuresApi.getFeatureHandlerFeaturesKeyGet({ key }),
    enabled: Boolean(key),
    retry: (_, error) => {
      return !(error instanceof AxiosError && error.response?.status === 404);
    },
  });
};

export const useCreateFeature = () => {
  const { workspace } = useWorkspaceContext();
  const featuresApi = featuresApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (feature: FeatureForm) =>
      featuresApi.createFeatureHandlerFeaturesPost({
        featureCreate: {
          key: feature.key,
          name: feature.name,
          description: feature.description,
          query_id: null,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PREFIX_CACHE_KEY] });
    },
  });
};

export const useUpdateFeature = () => {
  const { workspace } = useWorkspaceContext();
  const featuresApi = featuresApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      key,
      feature,
      etag,
    }: {
      key: string;
      feature: Partial<Omit<FeatureForm, "key">>;
      etag: string;
    }) =>
      featuresApi.patchFeatureHandlerFeaturesKeyPatch({
        key,
        featurePatch: {
          name: feature.name,
          description: feature.description,
        },
        ifMatch: etag,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PREFIX_CACHE_KEY] });
    },
  });
};

const FEATURE_QUERIES_PREFIX_CACHE_KEY = "feature-queries";

export const useFeatureQueries = () => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);

  return useQuery({
    queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id],
    queryFn: async () =>
      (await featureQueriesApi.getFeatureQueriesFeatureQueriesGet()).data,
  });
};

export const useFeatureQuery = (id: string) => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);
  return useQuery({
    queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id, id],
    queryFn: async () =>
      (
        await featureQueriesApi.getFeatureQueryFeatureQueriesIdGet({
          id,
        })
      ).data,
    enabled: !!id,
  });
};

export const useFeatureQueryPreview = ({
  onSuccess,
}: {
  onSuccess?: (data: PreviewQueryResponse["data"]) => void;
} = {}) => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);

  return useMutation<
    PreviewQueryResponse["data"],
    AxiosError<{ detail: string }>,
    { queryString: string; windowLength: number }
  >({
    mutationKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id, "preview"],
    mutationFn: async ({
      queryString,
      windowLength = 300,
    }: {
      queryString: string;
      windowLength: number;
    }) => {
      return (
        await featureQueriesApi.previewQueryFeatureQueriesPreviewPost({
          previewQueryRequest: {
            query: queryString,
            limit: 100,
            window_length: windowLength,
            environment: PreviewQueryRequestEnvironmentEnum.LIVE,
          },
        })
      ).data.data;
    },
    onSuccess,
    onError: (error, variables) => {
      if (error.response?.status === 500) {
        logger.error(
          "Unexpected error: Feature query preview failed: ",
          error,
          "Query: ",
          variables,
        );
      }
    },
    useErrorBoundary: false,
  });
};

export const useCreateFeatureQuery = () => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation<FeatureQuery, Error, FeatureQueryCreate>({
    mutationFn: async (query: FeatureQueryCreate) => {
      return (
        await featureQueriesApi.createFeatureQueryHandlerFeatureQueriesPost({
          featureQueryCreate: query,
        })
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id],
      });
    },
  });
};

type UseUpdateFeatureQueryProps = {
  onError?: (error: AxiosError<{ detail: string }>) => void;
};

export const useUpdateFeatureQuery = ({
  onError,
}: UseUpdateFeatureQueryProps = {}) => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation<
    FeatureQuery,
    AxiosError<{ detail: string }>,
    FeatureQueryPut & { id: string; etag: string }
  >({
    mutationFn: async ({ id, etag, ...query }) => {
      return (
        await featureQueriesApi.updateFeatureQueryHandlerFeatureQueriesIdPut({
          id,
          featureQueryPut: query,
          ifMatch: etag,
        })
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id],
      });
    },
    onError,
  });
};

export const useSetFeatureQueryStatus = ({
  onError,
}: UseUpdateFeatureQueryProps = {}) => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = featureQueriesApiCreator(workspace.base_url!);
  const queryClient = useQueryClient();

  return useMutation<
    FeatureQuery,
    AxiosError<{ detail: string }>,
    SetFeatureQueryStatusRequest & { id: string; etag: string }
  >({
    mutationFn: async ({ id, etag, ...query }) => {
      return (
        await featureQueriesApi.setFeatureQueryStatusHandlerFeatureQueriesIdSetStatusPost(
          {
            id,
            setFeatureQueryStatusRequest: query,
            ifMatch: etag,
          },
        )
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FEATURE_QUERIES_PREFIX_CACHE_KEY, workspace.id],
      });
    },
    onError,
  });
};
