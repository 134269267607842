import { createColumnHelper } from "@tanstack/react-table";
import React from "react";

import { useReviewCase } from "src/api/queries";
import { Cell, TableComp } from "src/base-components/Table";
import { DataValue } from "src/manualReview/reviewCaseCommon/Card";

type Props = {
  id: string;
  flowSlug: string;
  baseUrl: string | undefined;
};

type TableRow = {
  key: string;
  value: string;
};

const columnHelper = createColumnHelper<TableRow>();
const columns = [
  columnHelper.accessor("key", {
    header: () => null,
    cell: (info) => <Cell info={info}>{info.getValue()}</Cell>,
    size: 206,
  }),
  columnHelper.accessor("value", {
    header: () => null,
    cell: (info) => <DataValue value={info.getValue()} />,
  }),
];

export const ManualReviewInspectData: React.FC<Props> = ({
  id,
  flowSlug,
  baseUrl,
}) => {
  const { data, isLoading } = useReviewCase(baseUrl, flowSlug, id);
  const tableData = Object.entries(data?.inspect_data ?? {}).map(
    ([key, value]) => ({
      key,
      value,
    }),
  );

  return (
    <TableComp
      columns={columns}
      data={tableData}
      frameClassName="my-4"
      isLoading={isLoading}
      variant="compact"
    />
  );
};
