/* tslint:disable */
/* eslint-disable */
/**
 * Retrieve API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface DeserializedEvent
 */
export interface DeserializedEvent {
    /**
     * 
     * @type {object}
     * @memberof DeserializedEvent
     */
    'payload': object;
    /**
     * 
     * @type {string}
     * @memberof DeserializedEvent
     */
    'event_type': string;
    /**
     * 
     * @type {string}
     * @memberof DeserializedEvent
     */
    'ingestion_timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof DeserializedEvent
     */
    'environment': DeserializedEventEnvironmentEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DeserializedEventEnvironmentEnum {
    SANDBOX = 'sandbox',
    LIVE = 'live'
}

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface OnlineFeatureValue
 */
export interface OnlineFeatureValue {
    /**
     * 
     * @type {number}
     * @memberof OnlineFeatureValue
     */
    'w': number;
    /**
     * 
     * @type {number}
     * @memberof OnlineFeatureValue
     */
    't': number;
    /**
     * 
     * @type {string}
     * @memberof OnlineFeatureValue
     */
    'v': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve event by id
         * @param {string} id 
         * @param {string} eventType 
         * @param {GetEventByIdEventsEventTypeIdGetEnvironmentEnum} environment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventByIdEventsEventTypeIdGet: async (id: string, eventType: string, environment: GetEventByIdEventsEventTypeIdGetEnvironmentEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEventByIdEventsEventTypeIdGet', 'id', id)
            // verify required parameter 'eventType' is not null or undefined
            assertParamExists('getEventByIdEventsEventTypeIdGet', 'eventType', eventType)
            // verify required parameter 'environment' is not null or undefined
            assertParamExists('getEventByIdEventsEventTypeIdGet', 'environment', environment)
            const localVarPath = `/events/{event_type}/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"event_type"}}`, encodeURIComponent(String(eventType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (environment !== undefined) {
                localVarQueryParameter['environment'] = environment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve events
         * @param {string} startTimestamp 
         * @param {string} endTimestamp 
         * @param {string} eventType 
         * @param {GetEventsEventsGetEnvironmentEnum} environment 
         * @param {string | null} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsEventsGet: async (startTimestamp: string, endTimestamp: string, eventType: string, environment: GetEventsEventsGetEnvironmentEnum, entityId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTimestamp' is not null or undefined
            assertParamExists('getEventsEventsGet', 'startTimestamp', startTimestamp)
            // verify required parameter 'endTimestamp' is not null or undefined
            assertParamExists('getEventsEventsGet', 'endTimestamp', endTimestamp)
            // verify required parameter 'eventType' is not null or undefined
            assertParamExists('getEventsEventsGet', 'eventType', eventType)
            // verify required parameter 'environment' is not null or undefined
            assertParamExists('getEventsEventsGet', 'environment', environment)
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (startTimestamp !== undefined) {
                localVarQueryParameter['start_timestamp'] = (startTimestamp as any instanceof Date) ?
                    (startTimestamp as any).toISOString() :
                    startTimestamp;
            }

            if (endTimestamp !== undefined) {
                localVarQueryParameter['end_timestamp'] = (endTimestamp as any instanceof Date) ?
                    (endTimestamp as any).toISOString() :
                    endTimestamp;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['event_type'] = eventType;
            }

            if (environment !== undefined) {
                localVarQueryParameter['environment'] = environment;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve event by id
         * @param {string} id 
         * @param {string} eventType 
         * @param {GetEventByIdEventsEventTypeIdGetEnvironmentEnum} environment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventByIdEventsEventTypeIdGet(id: string, eventType: string, environment: GetEventByIdEventsEventTypeIdGetEnvironmentEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeserializedEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventByIdEventsEventTypeIdGet(id, eventType, environment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.getEventByIdEventsEventTypeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve events
         * @param {string} startTimestamp 
         * @param {string} endTimestamp 
         * @param {string} eventType 
         * @param {GetEventsEventsGetEnvironmentEnum} environment 
         * @param {string | null} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsEventsGet(startTimestamp: string, endTimestamp: string, eventType: string, environment: GetEventsEventsGetEnvironmentEnum, entityId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeserializedEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsEventsGet(startTimestamp, endTimestamp, eventType, environment, entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.getEventsEventsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve event by id
         * @param {EventsApiGetEventByIdEventsEventTypeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventByIdEventsEventTypeIdGet(requestParameters: EventsApiGetEventByIdEventsEventTypeIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeserializedEvent> {
            return localVarFp.getEventByIdEventsEventTypeIdGet(requestParameters.id, requestParameters.eventType, requestParameters.environment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve events
         * @param {EventsApiGetEventsEventsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsEventsGet(requestParameters: EventsApiGetEventsEventsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DeserializedEvent>> {
            return localVarFp.getEventsEventsGet(requestParameters.startTimestamp, requestParameters.endTimestamp, requestParameters.eventType, requestParameters.environment, requestParameters.entityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEventByIdEventsEventTypeIdGet operation in EventsApi.
 * @export
 * @interface EventsApiGetEventByIdEventsEventTypeIdGetRequest
 */
export interface EventsApiGetEventByIdEventsEventTypeIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsApiGetEventByIdEventsEventTypeIdGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof EventsApiGetEventByIdEventsEventTypeIdGet
     */
    readonly eventType: string

    /**
     * 
     * @type {'sandbox' | 'live'}
     * @memberof EventsApiGetEventByIdEventsEventTypeIdGet
     */
    readonly environment: GetEventByIdEventsEventTypeIdGetEnvironmentEnum
}

/**
 * Request parameters for getEventsEventsGet operation in EventsApi.
 * @export
 * @interface EventsApiGetEventsEventsGetRequest
 */
export interface EventsApiGetEventsEventsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsApiGetEventsEventsGet
     */
    readonly startTimestamp: string

    /**
     * 
     * @type {string}
     * @memberof EventsApiGetEventsEventsGet
     */
    readonly endTimestamp: string

    /**
     * 
     * @type {string}
     * @memberof EventsApiGetEventsEventsGet
     */
    readonly eventType: string

    /**
     * 
     * @type {'sandbox' | 'live'}
     * @memberof EventsApiGetEventsEventsGet
     */
    readonly environment: GetEventsEventsGetEnvironmentEnum

    /**
     * 
     * @type {string}
     * @memberof EventsApiGetEventsEventsGet
     */
    readonly entityId?: string | null
}

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve event by id
     * @param {EventsApiGetEventByIdEventsEventTypeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventByIdEventsEventTypeIdGet(requestParameters: EventsApiGetEventByIdEventsEventTypeIdGetRequest, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEventByIdEventsEventTypeIdGet(requestParameters.id, requestParameters.eventType, requestParameters.environment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve events
     * @param {EventsApiGetEventsEventsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventsEventsGet(requestParameters: EventsApiGetEventsEventsGetRequest, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEventsEventsGet(requestParameters.startTimestamp, requestParameters.endTimestamp, requestParameters.eventType, requestParameters.environment, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum GetEventByIdEventsEventTypeIdGetEnvironmentEnum {
    SANDBOX = 'sandbox',
    LIVE = 'live'
}
/**
  * @export
  * @enum {string}
  */
export enum GetEventsEventsGetEnvironmentEnum {
    SANDBOX = 'sandbox',
    LIVE = 'live'
}


/**
 * FeaturesApi - axios parameter creator
 * @export
 */
export const FeaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve features for entity. Use __global__ as entity_type to retrieve global events.
         * @param {string} entityType 
         * @param {string} entityId 
         * @param {string} environment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet: async (entityType: string, entityId: string, environment: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet', 'entityId', entityId)
            // verify required parameter 'environment' is not null or undefined
            assertParamExists('getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet', 'environment', environment)
            const localVarPath = `/features/{environment}/{entity_type}/{entity_id}`
                .replace(`{${"entity_type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"environment"}}`, encodeURIComponent(String(environment)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturesApi - functional programming interface
 * @export
 */
export const FeaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve features for entity. Use __global__ as entity_type to retrieve global events.
         * @param {string} entityType 
         * @param {string} entityId 
         * @param {string} environment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet(entityType: string, entityId: string, environment: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OnlineFeatureValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet(entityType, entityId, environment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeaturesApi - factory interface
 * @export
 */
export const FeaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeaturesApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve features for entity. Use __global__ as entity_type to retrieve global events.
         * @param {FeaturesApiGetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet(requestParameters: FeaturesApiGetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<OnlineFeatureValue>> {
            return localVarFp.getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet(requestParameters.entityType, requestParameters.entityId, requestParameters.environment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet operation in FeaturesApi.
 * @export
 * @interface FeaturesApiGetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGetRequest
 */
export interface FeaturesApiGetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiGetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet
     */
    readonly entityType: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiGetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiGetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet
     */
    readonly environment: string
}

/**
 * FeaturesApi - object-oriented interface
 * @export
 * @class FeaturesApi
 * @extends {BaseAPI}
 */
export class FeaturesApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve features for entity. Use __global__ as entity_type to retrieve global events.
     * @param {FeaturesApiGetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet(requestParameters: FeaturesApiGetFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGetRequest, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeatureByEntityFeaturesEnvironmentEntityTypeEntityIdGet(requestParameters.entityType, requestParameters.entityId, requestParameters.environment, options).then((request) => request(this.axios, this.basePath));
    }
}



