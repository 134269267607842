import { useState } from "react";

import RelationArrow from "src/assets/RelationArrow.svg?react";
import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import { Select } from "src/base-components/Select";
import {
  PropertyDefinitionOutput,
  PropertyDefinitionOutputTypeEnum,
} from "src/clients/features-control";
import { ManyToOneRelationIcon } from "src/eventsCatalogue/SchemaEditor/RelationIcons";
import { SchemaPropertyIcon } from "src/eventsCatalogue/SchemaEditor/utils";

type PropertyTypeSelectorProps = {
  value: PropertyDefinitionOutputTypeEnum;
  property: PropertyDefinitionOutput;
  pluralDisplayName: string;
};

export const PropertyTypeSelector: React.FC<PropertyTypeSelectorProps> = ({
  value,
  property,
  pluralDisplayName,
}) => {
  const [open, setOpen] = useState(false);
  if (value === "relation" && property) {
    return (
      <CustomPopover
        button={
          <Select
            options={[
              ...PROPERTY_TYPE_OPTIONS,
              {
                key: value,
                value: (
                  <div className="flex items-center gap-x-1.5">
                    <SchemaPropertyIcon property={property.type} />
                    <div className="text-gray-500 font-inter-normal-12px">
                      {property.relation_schema?.display_name}
                    </div>
                  </div>
                ),
              },
            ]}
            placeholder="Select type"
            value={value}
            disabled
            fullWidth
          />
        }
        className="w-70"
        isOpen={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <div className="px-4 py-2.5">
          <div className="mb-2 text-gray-800 font-inter-semibold-13px">
            Relationship
          </div>
          <div className="text-gray-600 font-inter-normal-12px">
            One {property.relation_schema?.display_name} can be associated to
            multiple {pluralDisplayName}.
          </div>
        </div>
        <div className="flex justify-between px-4">
          <Pill size="sm" variant="gray">
            <Pill.Text>{pluralDisplayName}</Pill.Text>
          </Pill>
          <ManyToOneRelationIcon />
          <Pill size="sm" variant="gray">
            <Pill.Text>{property.relation_schema?.display_name}</Pill.Text>
          </Pill>
        </div>
        <div className="px-4 py-2">
          <div className="rounded-lg border border-gray-100 p-3 text-center">
            <span className="text-gray-600 font-inter-medium-11px">
              {property.relation_schema?.display_name}
            </span>
            <RelationArrow className="m-auto my-2" />
            <div className="flex justify-between">
              <Pill size="sm" variant="gray">
                <Pill.Text>{pluralDisplayName}</Pill.Text>
              </Pill>

              <Pill size="sm" variant="gray">
                <Pill.Text>{property.relation_schema?.display_name}</Pill.Text>
              </Pill>
            </div>
          </div>
        </div>
      </CustomPopover>
    );
  }

  return (
    <Select
      options={PROPERTY_TYPE_OPTIONS}
      placeholder="Select type"
      value={value}
      disabled
      fullWidth
    />
  );
};

const PROPERTY_TYPE_OPTIONS = Object.values(PropertyDefinitionOutputTypeEnum)
  // Relation is specific type, we add it on the go in component
  .filter((type) => type !== PropertyDefinitionOutputTypeEnum.RELATION)
  .map((type) => ({
    key: type,
    value: (
      <div className="flex items-center gap-x-1.5">
        <SchemaPropertyIcon
          property={type as PropertyDefinitionOutputTypeEnum}
        />
        <div className="text-gray-500 font-inter-normal-12px">{type}</div>
      </div>
    ),
  }));
