import { JSONSchema7TypeName } from "json-schema";
import { isEqual } from "lodash";

import {
  ManifestJSONSchema,
  JSONSchemaPropertyDefinition,
} from "src/api/connectApi/manifestTypes";

export const isFieldOfNullableType = (
  field: ManifestJSONSchema,
  type: JSONSchema7TypeName,
): boolean => {
  return field.type === type || isEqual(field.type, [type, "null"]);
};

export const isFieldString = (field: ManifestJSONSchema): boolean => {
  return isFieldOfNullableType(field, "string");
};

export const isFieldNumber = (field: ManifestJSONSchema): boolean => {
  return isFieldOfNullableType(field, "number");
};

export const isFieldInteger = (field: ManifestJSONSchema): boolean => {
  return isFieldOfNullableType(field, "integer");
};

export const isFieldBoolean = (field: ManifestJSONSchema): boolean => {
  return isFieldOfNullableType(field, "boolean");
};

export const isFieldMultiSelect = (
  field: JSONSchemaPropertyDefinition,
): boolean => {
  return field?.widget === "multi_select";
};
