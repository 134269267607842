import { python } from "@codemirror/lang-python";
import { sql, SQLDialect } from "@codemirror/lang-sql";
import { syntaxHighlighting } from "@codemirror/language";
import { search } from "@codemirror/search";
import { StateField, StateEffect } from "@codemirror/state";
import { EditorView, Decoration } from "@codemirror/view";
import ReactCodeMirror, {
  Extension,
  KeyBinding,
  keymap,
  Prec,
  ReactCodeMirrorProps,
  ReactCodeMirrorRef,
} from "@uiw/react-codemirror";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { twJoin } from "tailwind-merge";

import { showUpdatedSuggestionsPlugin } from "src/base-components/CodeInput/Autocomplete/useAutocompleteExtension";
import { useFocusHandlers } from "src/base-components/CodeInput/CodeInputFocusHandlers";
import {
  CodeEditorTheme,
  CursorInvisibleTheme,
  SQLSyntaxHighlighting,
} from "src/base-components/CodeInput/Themes";
import "src/base-components/CodeInput/sqlSyntaxColors.css";
import { useDispatchCreateEvent } from "src/base-components/CodeInput/useOnCreateEvent";
import { useSetDataLoc } from "src/base-components/CodeInput/useSetDataLoc";

type Props = Pick<
  ReactCodeMirrorProps,
  "value" | "onChange" | "placeholder" | "autoFocus" | "onBlur" | "readOnly"
> & {
  disabled?: boolean;
  dataLoc?: string;
  language: "python" | "sql" | undefined;
  completionExtension?: Extension;
  errorHighlightedLine?: number;
  keyBindings?: KeyBinding[];
  highlightOnFocus?: boolean;
  lineWrapping?: boolean;
};

export const CodeEditorExtensionsPython = [python()];

export const CustomSQLDialect = SQLDialect.define({
  // Letting parser know that variables start with `$` symbol.
  // This is needed for syntax highlighting to work properly.
  specialVar: "$",
});
export const CodeEditorExtensionsSQL = [
  sql({ dialect: CustomSQLDialect }),
  syntaxHighlighting(SQLSyntaxHighlighting),
];

const addLineHighlight = StateEffect.define<number>();

const highlightDecorationField = StateField.define({
  create: () => {
    return Decoration.none;
  },
  update: (lineDecorations, transaction) => {
    lineDecorations = lineDecorations.map(transaction.changes);

    // remove highlight on changes
    if (!transaction.changes.empty) {
      lineDecorations = Decoration.none;
    }

    transaction.effects
      .filter((effect) => effect.is(addLineHighlight))
      .forEach((effect) => {
        lineDecorations = Decoration.none;
        lineDecorations = lineDecorations.update({
          add: [lineHighlight.range(effect.value)],
        });
      });
    return lineDecorations;
  },
  provide: (field) => EditorView.decorations.from(field),
});

const lineHighlight = Decoration.line({
  attributes: {
    class: "!bg-red-200 !bg-opacity-50", // We need some opacity here to make the line selection behind it visible
  },
});

const highlightLine = (view?: EditorView, lineNumber?: number) => {
  if (lineNumber === undefined || lineNumber <= 0 || view === undefined) return;

  // Check if the highlighted line
  // still exists as part of the doc
  const numberOfLines = view.state.doc.lines;
  if (lineNumber > numberOfLines) {
    return;
  }
  const docPosition = view.state.doc.line(lineNumber).from;
  view.dispatch({
    effects: addLineHighlight.of(docPosition),
  });
};
export const CodeEditor = forwardRef<ReactCodeMirrorRef, Props>(
  (
    {
      disabled = false,
      dataLoc,
      language,
      completionExtension,
      errorHighlightedLine,
      keyBindings,
      onBlur,
      highlightOnFocus = false,
      lineWrapping = false,
      ...codeMirroProps
    },
    editorRef,
  ) => {
    const editor = useRef<ReactCodeMirrorRef>(null);
    useImperativeHandle(editorRef, () => editor.current as ReactCodeMirrorRef);
    const { onCreateEditor } = useSetDataLoc(dataLoc, editor.current);
    const { dispatchCreateEvent } = useDispatchCreateEvent();

    const theme = useMemo(
      () =>
        EditorView.theme({
          ...CodeEditorTheme,
          ...(disabled && CursorInvisibleTheme),
        }),
      [disabled],
    );

    const extensions = useMemo(() => {
      // To add highlighting to the line numbers use gutterLineClass like demonstratet in https://github.com/codemirror/view/blob/5cd604e30a0127caeff783920d9c88c8b1d3369e/src/gutter.ts#L477
      const extensions = [highlightDecorationField.extension, search()];

      if (completionExtension) {
        extensions.push(completionExtension, showUpdatedSuggestionsPlugin);
      }

      if (language === "python") {
        extensions.push(CodeEditorExtensionsPython);
      } else {
        extensions.push(CodeEditorExtensionsSQL);
      }

      if (keyBindings) {
        extensions.push(Prec.highest(keymap.of(keyBindings)));
      }

      if (lineWrapping) {
        extensions.push(EditorView.lineWrapping);
      }

      return extensions;
    }, [completionExtension, language, keyBindings, lineWrapping]);

    useEffect(() => {
      highlightLine(editor.current?.view, errorHighlightedLine);
    }, [errorHighlightedLine]);

    const [focused, setFocused] = useState(false);

    const _handleFocus = useCallback(() => {
      highlightOnFocus && setFocused(true);
    }, [highlightOnFocus]);

    const _handleBlur = useCallback(
      (e: React.FocusEvent<HTMLDivElement>) => {
        onBlur?.(e);
        highlightOnFocus && setFocused(false);
      },
      [onBlur, highlightOnFocus],
    );

    const { handleFocus, handleBlur } = useFocusHandlers({
      editor,
      disabled,
      toggleWrappingOnFocus: false,
      onBlur: _handleBlur,
      onFocus: _handleFocus,
    });

    const onCreateEditorHandler = useCallback(
      (view: EditorView) => {
        onCreateEditor(view);
        highlightLine(view, errorHighlightedLine);
        dispatchCreateEvent();
      },
      [onCreateEditor, dispatchCreateEvent, errorHighlightedLine],
    );

    return (
      <ReactCodeMirror
        ref={editor}
        className={twJoin(
          "decideScrollbar h-full w-full overflow-auto rounded-lg border p-1 [&_.cm-scroller]:font-code-13",
          disabled && "cursor-not-allowed bg-gray-100",
          !disabled && "bg-gray-50",
          focused && "border-indigo-500",
        )}
        extensions={extensions}
        readOnly={disabled}
        theme={theme}
        onBlur={handleBlur}
        onCreateEditor={onCreateEditorHandler}
        onFocus={handleFocus}
        {...codeMirroProps}
      />
    );
  },
);
