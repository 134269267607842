import { useMutation, useQuery } from "@tanstack/react-query";

import {
  featureQueriesApi,
  featuresApi,
  workspacesApi,
} from "src/api/endpoints";
import { eventsApi } from "src/api/endpoints";
import { userOrganizationsQueryKey } from "src/api/queries";
import { rolesApi, usersApi } from "src/api/taktileApi";
import { organizationsApi } from "src/api/taktileApi";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { User, OrganizationInDB, RoleCreate } from "src/clients/taktile-api";
import { OrganizationCreate } from "src/clients/taktile-api";
import { queryClient } from "src/queryClient";

export const usePatchWorkspace = () => {
  return useMutation({
    mutationKey: ["patchWorkspace"],
    mutationFn: (workspace: WorkspaceDataplane) => {
      return workspacesApi.updateWorkspaceApiV1WorkspacesWorkspaceIdPatch(
        workspace.id,
        { config: workspace.config },
      );
    },
  });
};

export const useCreateOrganization = () => {
  return useMutation({
    mutationKey: ["createOrganization"],
    onSettled: () => {
      queryClient.invalidateQueries(userOrganizationsQueryKey);
    },
    mutationFn: ({
      name,
      principal,
    }: Pick<OrganizationCreate, "name" | "principal">) => {
      return organizationsApi.addOrganizationApiV1OrganizationsPost({
        name,
        principal,
        allows_signup: false,
        allows_decide_access: true,
        whitelabel: false,
      });
    },
  });
};

export const useUpdateUser = () => {
  return useMutation({
    mutationKey: ["updateUsers"],
    mutationFn: (user: User) => {
      return usersApi.updateUserApiV1UsersIdUserIdPut(user.id, user);
    },
  });
};

export const useCreateRole = () => {
  return useMutation({
    mutationKey: ["createRole"],
    onSettled: () => {
      queryClient.invalidateQueries(["allUsers"]);
    },
    mutationFn: (roleCreate: RoleCreate) =>
      rolesApi.createRoleApiV1RolesPost(roleCreate),
  });
};

export const useDeleteRole = () => {
  return useMutation({
    mutationKey: ["deleteRole"],
    onSettled: () => {
      queryClient.invalidateQueries(["allUsers"]);
    },
    mutationFn: (roleId: string) =>
      rolesApi.deleteRoleApiV1RolesRoleIdDelete(roleId),
  });
};

export const useAllUsers = () => {
  return useQuery<User[], Error>({
    queryKey: ["allUsers"],
    queryFn: async () => {
      let skip = 0;
      const limit = 100;
      let result: User[] = [];

      while (true) {
        const response = await usersApi.getUsersApiV1UsersGet(skip, limit);
        result = result.concat(response.data);
        if (response.data.length < limit) {
          break;
        }
        skip += limit;
      }

      return result;
    },
  });
};

export const useAllOrgs = () => {
  return useQuery<OrganizationInDB[], Error>({
    queryKey: ["allOrgs"],
    queryFn: async () => {
      let skip = 0;
      const limit = 30;
      let result: OrganizationInDB[] = [];

      while (true) {
        const response =
          await organizationsApi.getOrganizationsApiV1OrganizationsAllGet(
            skip,
            limit,
          );
        result = result.concat(response.data);
        if (response.data.length < limit) {
          break;
        }
        skip += limit;
      }
      return result;
    },
  });
};

export const useWorkspaceEventTypes = (
  workspace: WorkspaceDataplane | null,
) => {
  return useQuery({
    queryKey: ["workspaceEventTypes", workspace?.id],
    queryFn: async () => {
      const api = eventsApi(`${workspace?.base_url}`);
      const response = await api.getAllEventConfigsEventsGet();
      return response.data;
    },
    enabled: !!workspace,
  });
};

export const useWorkspaceFeatures = (workspace: WorkspaceDataplane | null) => {
  return useQuery({
    queryKey: ["workspaceFeatures", workspace?.id],
    queryFn: async () => {
      const api = featuresApi(`${workspace?.base_url}`);
      const response = await api.getFeaturesFeaturesGet();
      return response.data;
    },
    enabled: !!workspace,
  });
};

export const useWorkspaceFeatureQueries = (
  workspace: WorkspaceDataplane | null,
) => {
  return useQuery({
    queryKey: ["workspaceFeatureQueries", workspace?.id],
    queryFn: async () => {
      const api = featureQueriesApi(`${workspace?.base_url}`);
      const response = await api.getFeatureQueriesFeatureQueriesGet();
      return response.data;
    },
    enabled: !!workspace,
  });
};
