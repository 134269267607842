import {
  faBuildings,
  faCreditCard,
  faKey,
  faMoneySimpleFromBracket,
  faRightToBracket,
  faShop,
  faTimer,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";

import { EventConfigOutput } from "src/clients/features-control";

const EVENT_FALLBACK_ICON = faTimer;

const EVENT_ICONS = {
  user: faUser,
  buildings: faBuildings,
  shop: faShop,
  card: faCreditCard,
  transaction: faMoneySimpleFromBracket,
  login: faRightToBracket,
  key: faKey,
} as const;

export const getEventIcon = (eventType: EventConfigOutput) =>
  EVENT_ICONS[eventType.display_symbol as keyof typeof EVENT_ICONS] ??
  EVENT_FALLBACK_ICON;
