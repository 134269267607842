import Avatar from "boring-avatars";
import React, { useMemo } from "react";
import { twJoin } from "tailwind-merge";

import { OrganizationT } from "src/api/flowTypes";
import { useUserOrganizationLogo } from "src/api/queries";
import { logger } from "src/utils/logger";

const FALLBACK_COLORS = ["#5946E5", "#6366F1", "#A7F3D0", "#22D3EE", "#493FC1"];

type Props = {
  org: OrganizationT;
  size: "small" | "medium";
  noBorder?: boolean;
};

export const OrgLogo: React.FC<Props> = ({ org, size, noBorder = false }) => {
  const { data, isInitialLoading } = useUserOrganizationLogo(org.id);

  const imgSrc = useMemo(() => {
    try {
      // @ts-expect-error
      return data ? URL.createObjectURL(data) : null;
    } catch (error) {
      logger.error("Error creating object URL", { error });
      return null;
    }
  }, [data]);

  // Fallback for before we have the logo
  if (isInitialLoading) {
    return (
      <div
        className={twJoin(
          !noBorder && "rounded-md border border-gray-200",
          size === "medium" && "h-7 w-7",
          size === "small" && "h-4 w-4",
        )}
      />
    );
  }

  if (imgSrc) {
    return (
      <img
        alt="logo"
        className={twJoin(
          !noBorder && "rounded-md border border-gray-200 object-cover",
          size === "medium" && "h-7 w-7",
          size === "small" && "h-4 w-4",
        )}
        src={imgSrc}
      />
    );
  }

  return (
    <div className="flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
      <Avatar
        colors={FALLBACK_COLORS}
        name={org.name}
        size={size === "medium" ? 26 : 14}
        variant="marble"
        square
      />
    </div>
  );
};
