import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { AiNodeForm, BasicModelCard } from "src/aiNode/types";
import { FormItem } from "src/base-components/FormItem";
import { SimpleDropDown } from "src/base-components/SimpleDropDown";

type AdvancedConfigPropsT = {
  availableModels: BasicModelCard[];
};

export const AdvancedConfig: React.FC<AdvancedConfigPropsT> = ({
  availableModels,
}) => {
  const { setValue, watch, control } = useFormContext<AiNodeForm>();
  let currentModelId = watch("model.model_id");

  const renderableAvailableModels = availableModels.map((model) => {
    return {
      key: model.model_id,
      value: model.name,
    };
  });

  return (
    <>
      <FormItem label="Model" isRequired>
        <Controller
          control={control}
          name="model.model_id"
          render={(_) => (
            <SimpleDropDown
              buttonClassName="pl-3"
              buttonDataLoc="ai-node-model"
              className="h-8 w-full"
              elements={renderableAvailableModels}
              itemsClassNames="w-full"
              itemsWidth="w-full"
              placeholder="Select model"
              placement="bottomLeft"
              selectedKey={currentModelId}
              onSelect={(value) => {
                setValue("model.model_id", value);
              }}
            />
          )}
        />
      </FormItem>
    </>
  );
};
