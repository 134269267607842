import { InputMappingT } from "src/integrationNode/types";

export const InputMappingRowTooltipBody = ({
  hint,
  beType,
  rules,
  example,
}: InputMappingT) => {
  return (
    <div className="font-fira-code space-y-2">
      {hint && (
        <p className="font-inter-normal-12px">
          {hint.split("\n").map((line, i) => (
            <p key={i}>{line}</p>
          ))}
        </p>
      )}
      {beType && (
        <p className="font-bold">
          Type:{" "}
          <span className="font-normal">
            {Array.isArray(beType) ? beType.join(" | ") : beType}
          </span>
        </p>
      )}
      {rules?.minLength && (
        <p className="font-bold">
          Minimum length:{" "}
          <span className="font-normal">
            {rules?.minLength === 1
              ? "1 character"
              : `${rules?.minLength} characters`}
          </span>
        </p>
      )}
      {rules?.maxLength && (
        <p className="font-bold">
          Maximum length:{" "}
          <span className="font-normal">
            {rules?.maxLength === 1
              ? "1 character"
              : `${rules?.maxLength} characters`}
          </span>
        </p>
      )}
      {example && (
        <p className="font-bold">
          Example: <span className="font-normal">{example}</span>
        </p>
      )}
    </div>
  );
};
