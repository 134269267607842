import { Icon } from "src/base-components/Icon";
import {
  EnumValue,
  EventConfigOutput,
  PropertyDefinitionOutput,
  PropertyDefinitionOutputTypeEnum,
} from "src/clients/features-control/api";
import { ManyToOneRelationIcon } from "src/eventsCatalogue/SchemaEditor/RelationIcons";
import { TYPE_ICONS } from "src/utils/constants";

type FormEnumValue = EnumValue & {
  _isNew: boolean;
};

type FormProperty = Omit<PropertyDefinitionOutput, "enum_schema"> & {
  enum_schema:
    | {
        values: Array<FormEnumValue>;
      }
    | null
    | undefined;
};

type SchemaProperties = EventConfigOutput["properties"];

export type FormProperties = { [key: string]: FormProperty };

export const SchemaPropertyIcon: React.FC<{
  property: PropertyDefinitionOutputTypeEnum;
}> = ({ property }) => {
  if (property === PropertyDefinitionOutputTypeEnum.RELATION) {
    return <ManyToOneRelationIcon />;
  }

  return <Icon icon={PROPERTY_TYPES_TO_ICONS[property]} size="xs" />;
};

const PROPERTY_TYPES_TO_ICONS = {
  string: TYPE_ICONS.string,
  boolean: TYPE_ICONS.boolean,
  number: TYPE_ICONS.number,
  integer: TYPE_ICONS.integer,
  object: TYPE_ICONS.object,
  list: TYPE_ICONS.tag,
  datetime: TYPE_ICONS.datetime,
  date: TYPE_ICONS.date,
  enum: TYPE_ICONS.enum,
  tag: TYPE_ICONS.tag,
} as const;

export const schemaPropertiesToFormProperties = (
  schemaProperties: SchemaProperties,
): FormProperties => {
  return Object.entries(schemaProperties).reduce((acc, [property, config]) => {
    if (config.type === "enum" && config.enum_schema) {
      acc[property] = {
        ...config,
        enum_schema: {
          ...config.enum_schema,
          values: config.enum_schema.values.map((value) => ({
            ...value,
            value: JSON.stringify(value.value),
            _isNew: false,
          })),
        },
      };
    } else {
      acc[property] = config as FormProperty;
    }
    return acc;
  }, {} as FormProperties);
};

export const formPropertiesToSchemaProperties = (
  formProperties: FormProperties,
): SchemaProperties => {
  return Object.entries(formProperties).reduce((acc, [property, config]) => {
    if (config.type === "enum" && config.enum_schema) {
      acc[property] = {
        ...config,
        enum_schema: {
          ...config.enum_schema,
          values: config.enum_schema.values.map(({ _isNew, ...value }) => ({
            ...value,
            value: JSON.parse(value.value),
          })),
        },
      };
    } else {
      acc[property] = config;
    }

    return acc;
  }, {} as SchemaProperties);
};
