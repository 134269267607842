import {
  faBuildings,
  faCreditCard,
  faLink,
  faMoneyFromBracket,
  faStore,
  faTags,
  faShop,
  faUser,
  faQuestion,
} from "@fortawesome/pro-regular-svg-icons";
import { pick, uniq } from "lodash";
import { useSearchParams } from "react-router-dom";

import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import {
  EntityResource,
  EntityResourceDataplane,
  EntitySchemaResource,
  EntitySchemaResourceDataplane,
  EntitySchemaResourcePage,
  GroupedEntity,
  RelatedEntitiesDataPlane,
  TableRow,
  TableValue,
  TransformedRelatedEntities,
} from "src/entities/queries";

export const propertyTypeIcons = {
  ...SCHEMA_TYPE_ICONS,
  url: faLink,
  tag: faTags,
};

export const entityIcons = {
  user: faUser,
  credit_card: faCreditCard,
  buildings: faBuildings,
  store: faStore,
  money_from_bracket: faMoneyFromBracket,
  merchant: faShop,
} as const;

export const getOrderedPropertiesByDisplayName = (
  schema: EntitySchemaResource,
): string[] => {
  const properties = Object.entries(schema.properties ?? {})
    .toSorted((a, b) =>
      a[1]._display_name
        ?.toLocaleLowerCase()
        ?.localeCompare(b[1]._display_name?.toLocaleLowerCase()),
    )
    .map(([key]) => key);

  return uniq(
    [
      schema._primary_property,
      schema._secondary_property,
      ...properties,
    ].filter(Boolean),
  );
};

export const getOrderedPropertiesByKey = (
  schema: EntitySchemaResource,
): string[] => {
  const properties = Object.entries(schema.properties ?? {})
    .toSorted((a, b) =>
      a[0]?.toLocaleLowerCase()?.localeCompare(b[0].toLocaleLowerCase()),
    )
    .map(([key]) => key);

  return uniq(
    [
      schema._primary_property,
      schema._secondary_property,
      ...properties,
    ].filter(Boolean),
  );
};

export const getTableData = (
  entitySchema: EntitySchemaResource | undefined,
  entity: EntityResource | undefined,
) => {
  if (!entitySchema || !entity) {
    return [];
  }

  const tableData: TableRow[] = [];

  const orderedFields = getOrderedPropertiesByDisplayName(entitySchema);

  tableData.push({
    value: entity._id,
    _type: "string",
    _display_name: "ID",
    _indexed: true,
  });

  orderedFields.forEach((fieldName) => {
    const value = entity.properties[fieldName];

    tableData.push({
      value: value as TableValue,
      ...entitySchema.properties[fieldName],
    });
  });

  return tableData;
};

export const findSchema = (
  schema: string,
  data: EntitySchemaResourcePage | undefined,
): EntitySchemaResource | undefined => {
  const entitySchema = data?.entities.find((entity) => entity._id === schema);
  return entitySchema ? (entitySchema as EntitySchemaResource) : undefined;
};

const SidepaneTypeParam = "resource-type" as const;
const SidepaneIdParam = "resource-id" as const;
const EventTypeParam = "event-type" as const;

export type SidepaneType = "event";

type ToggleSidepaneParams<T extends SidepaneType> = {
  id: string;
  eventType?: T extends "event" ? string | null : null;
} | null;

type UseSidepaneReturn<T extends SidepaneType> = {
  resourceId: string | null;
  eventType: T extends "event" ? string | null : null;
  toggleSidepane: (params: ToggleSidepaneParams<T>) => void;
};

export const useSidepane = <T extends SidepaneType>(
  type: T,
): UseSidepaneReturn<T> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resourceType = searchParams.get(SidepaneTypeParam);
  const resourceId = searchParams.get(SidepaneIdParam);

  const toggleSidepane = (params: ToggleSidepaneParams<T>) => {
    setSearchParams((prev) => {
      if (params) {
        prev.set(SidepaneTypeParam, type);
        prev.set(SidepaneIdParam, params.id);
        if (params.eventType) {
          prev.set(EventTypeParam, params.eventType);
        }
      } else {
        prev.delete(SidepaneTypeParam);
        prev.delete(SidepaneIdParam);
        prev.delete(EventTypeParam);
      }
      return prev;
    });
  };

  return {
    resourceId: resourceType === type ? resourceId : null,
    eventType: (type === "event"
      ? searchParams.get(EventTypeParam)
      : null) as T extends "event" ? string | null : null,
    toggleSidepane,
  };
};

export const getEntityKeys = (
  entity: EntitySchemaResourceDataplane | EntityResourceDataplane,
) => {
  const [reservedKeys, userKeys] = [
    Object.keys(entity).filter((key) => key[0] === "_"),
    Object.keys(entity).filter((key) => key[0] !== "_"),
  ];

  return {
    ...pick(entity, reservedKeys),
    properties: pick(entity, userKeys),
  };
};

/**
 * This function processes the response, grouping entities by their schema.
 * It distinguishes between schema entities and resource entities, organizing them into
 * a list of `TransformedRelatedEntities`. Each group contains a schema and
 * its associated entities
 */

export const transformRelatedEntities = (data: RelatedEntitiesDataPlane) => {
  const groupedEntities: GroupedEntity[] = [];

  Object.keys(data.entities).forEach((key: string) => {
    const entity = data.entities[key];

    if (key.startsWith("schemas.")) {
      const schemaEntity = entity as EntitySchemaResourceDataplane;
      let group = groupedEntities.find(
        (g) => g.schema._id === schemaEntity._id,
      );
      if (!group) {
        group = {
          schema: schemaEntity,
          entities: [],
        };
        groupedEntities.push(group);
      } else {
        group.schema = schemaEntity;
      }
    } else {
      const schemaId = entity._schema;
      let group = groupedEntities.find((g) => g.schema._id === schemaId);

      if (!group) {
        // If the schema is not yet added, we create a placeholder schema object
        // which is replaced by the actual schema when it is encountered
        group = {
          schema: { _id: schemaId } as EntitySchemaResourceDataplane,
          entities: [],
        };
        groupedEntities.push(group);
      }

      group.entities.push(entity);
    }
  });

  return groupedEntities.map((group) => ({
    schema: getEntityKeys(group.schema),
    entities: group.entities.map((entity) => getEntityKeys(entity)),
  })) as TransformedRelatedEntities[];
};

export const getSchemaIcon = (schema: EntitySchemaResource) => {
  return (
    entityIcons[schema._display_symbol as keyof typeof entityIcons] ??
    faQuestion
  );
};
