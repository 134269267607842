import { useQueryClient, useIsFetching } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const useEventsRefresh = (environment: string, entityId?: string) => {
  const [isManualRefresh, setIsManualRefresh] = useState(false); // state to differentiate between manual refresh triggered by user and background refreshes/initial mount fetch
  const queryClient = useQueryClient();
  const isFetching = useIsFetching({
    queryKey: ["events", environment, entityId],
    exact: false,
  });

  useEffect(() => {
    if (!isFetching && isManualRefresh) {
      setIsManualRefresh(false);
    }
  }, [isFetching, isManualRefresh]);

  const refresh = () => {
    setIsManualRefresh(true);
    queryClient.invalidateQueries({
      queryKey: ["events", environment, entityId],
    });
  };

  return {
    isRefreshing: isManualRefresh,
    refresh,
  };
};
