import { faRefresh, faUserCircle } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Pill } from "src/base-components/Pill";
import { DecisionsTabContent } from "src/entities/entityView/DecisionsTabContent";
import { EventsTabContent } from "src/entities/entityView/EventsTabContent";
import { Features } from "src/entities/entityView/Features";
import { RelatedEntitiesWrapper } from "src/entities/entityView/RelatedEntities";
import { useEventsRefresh } from "src/entities/entityView/useEventsRefresh";
import { SubHeader } from "src/flow/SubHeader";
import { ExtendedHistoricalDecisionV3 } from "src/flow/decisionHistory/Columns";
import { useEnvironment } from "src/flow/decisionHistory/filterHooks";

type Props = {
  selectedDecision: ExtendedHistoricalDecisionV3 | null;
  onSelectDecision: (decision: ExtendedHistoricalDecisionV3 | null) => void;
};

export const EntityTabs: React.FC<Props> = ({
  selectedDecision,
  onSelectDecision,
}) => {
  const { id: entityId } = useParams();
  const [environment] = useEnvironment();
  const [activeTab, setActiveTab] = useState<string>("decisions-data-tab");
  const { refresh: refreshEvents, isRefreshing: isRefreshingEvents } =
    useEventsRefresh(environment, entityId);

  const handleRefresh = () => {
    if (activeTab === "events-data-tab") {
      refreshEvents();
    }
  };

  const numberOfDecisions = 6;
  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const tabs = [
    {
      label: (
        <div className="flex h-5 items-center gap-1">
          Decisions
          <Pill size="sm">
            <Pill.Icon icon={faUserCircle} />
            <Pill.Text>{numberOfDecisions}</Pill.Text>
          </Pill>
        </div>
      ),
      key: "decisions-data-tab",
      content: (
        <DecisionsTabContent
          selectedDecision={selectedDecision}
          onSelectDecision={onSelectDecision}
        />
      ),
    },
    {
      label: "Events",
      key: "events-data-tab",
      content: <EventsTabContent />,
    },
    {
      label: "Features",
      key: "features-data-tab",
      content: <Features />,
    },
    {
      label: "Related Entities",
      key: "related-entities-data-tab",
      content: <RelatedEntitiesWrapper />,
    },
  ];

  return (
    <div className="flex-1 overflow-x-hidden">
      <SubHeader.Tabs
        activeTab={activeTab}
        tabs={tabs}
        onChange={handleTabChange}
      >
        <SubHeader.Button
          icon={faRefresh}
          spin={isRefreshingEvents}
          tooltip="Refresh"
          onClick={handleRefresh}
        />
      </SubHeader.Tabs>
    </div>
  );
};
