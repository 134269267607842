import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle, faWarning } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";

export type CalloutType = "error" | "warning" | "info" | "success" | "neutral";

const getTypeStyles = (type: CalloutType) => {
  const styles = {
    error: "border-red-200 bg-red-50 text-red-600",
    warning: "border-orange-200 bg-orange-50 text-orange-600",
    info: "border-indigo-200 bg-indigo-50 text-indigo-600",
    success: "border-green-200 bg-green-50 text-green-600",
    neutral: "border-gray-200 bg-gray-50 text-gray-600",
  };
  return styles[type];
};

const getIconColor = (type: CalloutType) => {
  const colors = {
    error: "text-red-500",
    warning: "text-orange-500",
    info: "text-indigo-500",
    success: "text-green-500",
    neutral: "text-gray-500",
  };
  return colors[type];
};

type CalloutPropsT = {
  type: CalloutType;
  children: React.ReactNode;
  action?: {
    text: React.ReactNode;
    onClick: () => void;
  };
  fullWidth?: boolean;
  icon?: IconProp;
  dataLoc?: string;
  iconSpin?: boolean;
};

const ExpandableContent: React.FC<{
  children: React.ReactNode;
  showSeeMore: boolean;
  isExpanded: boolean;
  onToggle: () => void;
  contentRef: React.RefObject<HTMLDivElement>;
}> = ({ children, showSeeMore, isExpanded, onToggle, contentRef }) => (
  <div className="min-w-0 whitespace-pre-wrap break-words">
    <div
      ref={contentRef}
      className={twJoin(showSeeMore && !isExpanded && "line-clamp-3")}
    >
      {children}
    </div>
    {showSeeMore && (
      <button
        className={twJoin("underline font-inter-medium-12px")}
        type="button"
        onClick={onToggle}
      >
        {isExpanded ? "See less" : "See more"}
      </button>
    )}
  </div>
);

const ActionButton: React.FC<NonNullable<CalloutPropsT["action"]>> = ({
  text,
  onClick,
}) => (
  <button
    className="ml-auto flex-shrink-0 whitespace-nowrap underline font-inter-medium-12px"
    type="button"
    onClick={onClick}
  >
    {text}
  </button>
);

/**
 * Figma: https://www.figma.com/design/BO9DWO8PKNGNSI3HhpSobA/Design-System-v2?node-id=4924-42069&node-type=canvas&m=dev
 */

export const Callout: React.FC<CalloutPropsT> = ({
  type,
  children,
  action,
  fullWidth,
  icon,
  dataLoc,
  iconSpin,
}) => {
  const { isExpanded, showSeeMore, contentRef, toggleExpand } =
    useExpandable(children);

  return (
    <div
      className={twJoin(
        "flex items-start gap-x-1 rounded-lg border py-2 pl-3 pr-4 font-inter-normal-12px",
        getTypeStyles(type),
        fullWidth && "rounded-none border-x-0",
      )}
      data-loc={dataLoc ?? `info-pill-${type}`}
    >
      <Icon
        color={getIconColor(type)}
        icon={
          icon ??
          (type === "error" || type === "warning" ? faWarning : faInfoCircle)
        }
        size="xs"
        spin={iconSpin}
      />

      <ExpandableContent
        contentRef={contentRef}
        isExpanded={isExpanded}
        showSeeMore={showSeeMore}
        onToggle={toggleExpand}
      >
        {children}
      </ExpandableContent>

      {action && <ActionButton {...action} />}
    </div>
  );
};

const useExpandable = (children: React.ReactNode) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateHeight = () => {
      const content = contentRef.current;
      if (content) {
        const lineHeight = parseInt(
          window.getComputedStyle(content).lineHeight,
        );
        const height = content.scrollHeight;
        setShowSeeMore(height > lineHeight * 3);
      }
    };

    calculateHeight();
    window.addEventListener("resize", calculateHeight);
    return () => window.removeEventListener("resize", calculateHeight);
  }, [children]);

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  return { isExpanded, showSeeMore, contentRef, toggleExpand };
};
