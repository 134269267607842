import { faInfoCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FormProvider, useForm } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";
import { SchemaBaseEditor } from "src/entities/SchemaEditor/SchemaBaseEditor";
import { SchemaPropertiesEditor } from "src/entities/SchemaEditor/SchemaPropertiesEditor";
import {
  EntitySchemaResource,
  useUpdateEntitySchema,
} from "src/entities/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type Props = {
  schema: EntitySchemaResource;
  onClose: () => void;
};

type Form = EntitySchemaResource;

export const EntitySchemaEditorSidebar: React.FC<Props> = ({
  schema,
  onClose,
}) => {
  const { workspace } = useWorkspaceContext();
  const { mutateAsync: updateSchema } = useUpdateEntitySchema({
    baseUrl: workspace.base_url!,
  });
  const form = useForm<Form>({
    values: schema,
  });
  const isPending = form.formState.isSubmitting || form.formState.isLoading;
  const isDirty = Object.keys(form.formState.dirtyFields).length > 0;

  const handleSubmit = async (data: Form) => {
    await updateSchema(data);
    return data;
  };

  return (
    <form
      className="decideScrollbar flex h-full w-150 shrink-0 flex-col gap-y-4 border-l border-gray-100 bg-white pb-5"
      onSubmit={form.handleSubmit(handleSubmit)}
    >
      <FormProvider {...form}>
        <div className="sticky top-0 z-10 flex flex-col gap-y-4 bg-white px-4 pt-5">
          <div className="flex items-center gap-x-1.5">
            <div className="flex-1 text-gray-800 font-inter-semibold-13px">
              Edit Entity schema
            </div>
            <Button
              disabled={!isDirty || isPending}
              htmlType="submit"
              loading={isPending}
              size="sm"
            >
              Save changes
            </Button>
            <Tooltip
              body="For additional changes you want to make on Entity Schema, please contact Taktile team."
              placement="top-end"
            >
              <Icon color="text-gray-500" icon={faInfoCircle} size="xs" />
            </Tooltip>
            <Icon
              color="text-gray-500"
              icon={faTimes}
              size="xs"
              onClick={onClose}
            />
          </div>
          <Divider />
        </div>
        <div className="flex flex-col gap-y-4 px-4">
          <SchemaBaseEditor isLoading={false} />
          <SchemaPropertiesEditor isLoading={false} />
        </div>
      </FormProvider>
    </form>
  );
};
