import { useMutation, useQuery } from "@tanstack/react-query";
import { pick } from "lodash";
import { useMemo } from "react";

import { entitiesApi } from "src/api/endpoints";
import { JsonRefPath, SchemaTypesT, SubSchemaT } from "src/api/flowTypes";
import { DecisionEnvironment } from "src/api/types";
import {
  EntityResourcePage,
  GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum,
  GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum,
  GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum,
  ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum,
  ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum,
  PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum,
} from "src/clients/entities/api";
import {
  entityIcons,
  getEntityKeys,
  transformRelatedEntities,
} from "src/entities/entityView/utils";
import { queryClient } from "src/queryClient";
import { logger } from "src/utils/logger";

type UseEntitiesParams = {
  schema: string;
  env: DecisionEnvironment;
  baseUrl: string;
};

export type EntityFieldType =
  | Exclude<SchemaTypesT, JsonRefPath>
  | "tag"
  | "relation";

export type Cardinality = "1" | "n";
export type EntityEnumValue = { _internal_name: string };

export type EntitySchemaProperty = {
  _type: EntityFieldType;
  _display_name: string;
  _indexed: boolean;
  _rel_schema?: string;
  _cardinality?: Cardinality;
};

export type EntityEnumSchemaProperty = EntitySchemaProperty & {
  _type: "enum";
  _values: EntityEnumValue[];
};

export type TableValue = string | string[];

export type TableRow = EntitySchemaProperty & { value: TableValue };

export type EntitySchemaResourceDataplane = {
  _id: string;
  _schema: "Entity";
  _etag: number;
  _related: Record<string, unknown>;
  _updatedAt: string;
  _display_name_singular: string;
  _display_name_plural: string;
  _display_symbol: keyof typeof entityIcons;
  _primary_property: string;
  _secondary_property: string;
};

export type EntitySchemaResource = EntitySchemaResourceDataplane & {
  properties: {
    [key: string]: EntitySchemaProperty;
  };
};

export type EntitySchemaResourcePage = {
  entities: EntitySchemaResource[] | EntityResource[];
  next_page_token: string;
};

export type EntityResourceDataplane = {
  _id: string;
  _schema: string;
  _etag: number;
  _related: Record<string, unknown>;
  _updatedAt: string;
};

export type EntityResource = EntityResourceDataplane & {
  properties: {
    [key: string]: TableValue;
  };
};

type useEntityParams = UseEntitiesParams & {
  entityId: string;
};

type useRelatedEntitiesParams = useEntityParams;

export type TransformedRelatedEntities = {
  schema: EntitySchemaResource;
  entities: EntityResource[];
};

export type RelatedEntitiesDataPlane = {
  entities: Record<
    string,
    EntityResourceDataplane | EntitySchemaResourceDataplane
  >;
};

export type GroupedEntity = {
  schema: EntitySchemaResourceDataplane;
  entities: EntityResourceDataplane[];
};

const transformToFrontendEntity = <S extends string>(entity: object) => {
  const [reservedKeys, userKeys] = [
    Object.keys(entity).filter((key) => key[0] === "_"),
    Object.keys(entity).filter((key) => key[0] !== "_"),
  ];

  return {
    ...pick(entity, reservedKeys),
    properties: pick(entity, userKeys),
  } as S extends "schemas" ? EntitySchemaResource : EntityResource;
};

const transformToFrontendTypes = <S extends string>(
  data: EntityResourcePage,
) => {
  return {
    ...data,
    entities: data.entities.map((entity) =>
      getEntityKeys(
        entity as EntitySchemaResourceDataplane | EntityResourceDataplane,
      ),
    ),
  } as {
    entities: S extends "schemas" ? EntitySchemaResource[] : EntityResource[];
    next_page_token: string;
  };
};

const queryKeys = {
  entitySchemas: (baseUrl: string) => ["entitySchemas", baseUrl],
  entitySchemasJsonSchema: (baseUrl: string) => [
    "entitySchemasJsonSchema",
    baseUrl,
  ],
};

export const useEntitySchemas = ({
  baseUrl,
  options,
}: {
  baseUrl: string;
  options?: { enabled: boolean };
}) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useQuery({
    queryKey: queryKeys.entitySchemas(baseUrl),
    queryFn: async () => {
      try {
        const response =
          await apiClient.listSchemasEntitiesApiV1SchemasSchemaGet({
            schema: ListSchemasEntitiesApiV1SchemasSchemaGetSchemaEnum.ENTITY,
            limit: 100,
          });
        if (response.data) {
          return transformToFrontendTypes<"schemas">(response.data);
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    enabled: baseUrl !== "" && options?.enabled,
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

export const useEntities = ({ schema, env, baseUrl }: UseEntitiesParams) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useQuery({
    queryKey: ["entities", schema, env, baseUrl],
    queryFn: async () => {
      try {
        const response =
          await apiClient.listEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGet(
            {
              env: env as unknown as ListEntitiesBySchemaEntitiesApiV1EntitiesEnvSchemaGetEnvEnum,
              schema,
              limit: 100,
            },
          );
        if (response.data) {
          return transformToFrontendTypes<"entity">(response.data);
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
  });
};

export const useEntity = ({
  schema,
  env,
  baseUrl,
  entityId,
}: useEntityParams) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useQuery({
    queryKey: ["entity", schema, env, baseUrl],
    queryFn: async () => {
      try {
        const response =
          await apiClient.getEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGet(
            {
              env: env as unknown as GetEntityBySchemaEntitiesApiV1EntityEnvSchemaEntityIdGetEnvEnum,
              schema,
              entityId,
            },
          );
        if (response.data) {
          return getEntityKeys(
            response.data as EntityResourceDataplane,
          ) as EntityResource;
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
  });
};

export const useRelatedEntities = ({
  schema,
  env,
  baseUrl,
  entityId,
}: useRelatedEntitiesParams) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useQuery({
    queryKey: ["relatedEntities", schema, env, baseUrl],
    queryFn: async () => {
      try {
        const response =
          await apiClient.getEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGet(
            {
              schema,
              env: env as unknown as GetEnrichedEntityBySchemaEntitiesApiV1EntitiesEnvSchemaEntityIdRelatedGetEnvEnum,
              entityId,
            },
          );
        if (response.data) {
          return transformRelatedEntities(
            response.data as unknown as RelatedEntitiesDataPlane,
          );
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
  });
};

export const useEntitySchemasJsonSchema = ({
  baseUrl,
}: {
  baseUrl: string;
}) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);
  return useQuery({
    queryKey: queryKeys.entitySchemasJsonSchema(baseUrl),
    queryFn: async () => {
      try {
        const response =
          await apiClient.getSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGet(
            {
              schema:
                GetSchemaJsonSchemaEntitiesApiV1SchemasSchemaJsonSchemaGetSchemaEnum.ENTITY,
            },
          );
        if (response.data) {
          return response.data as Record<string, object>;
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

export const getCachedEntityJsonSchemas = (baseUrl: string) => {
  const data = queryClient.getQueryData<Record<string, SubSchemaT>>(
    queryKeys.entitySchemasJsonSchema(baseUrl),
  );

  return data ?? null;
};

export const useUpdateEntitySchema = ({ baseUrl }: { baseUrl: string }) => {
  const apiClient = useMemo(() => entitiesApi(baseUrl), [baseUrl]);

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["entitySchemas", baseUrl]);
    },
    mutationFn: async (schema: EntitySchemaResource) => {
      try {
        const { properties, ...baseFields } = schema;
        const response =
          await apiClient.putSchemasEntitiesApiV1SchemaSchemaEntityIdPut({
            schema:
              PutSchemasEntitiesApiV1SchemaSchemaEntityIdPutSchemaEnum.ENTITY,
            entityId: schema._id,
            body: {
              ...baseFields,
              ...properties,
              _etag: 1,
            },
          });
        if (response.data) {
          return transformToFrontendEntity<"schemas">(response.data);
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
  });
};
