import { useMemo } from "react";

import { useUserOrganizations, useWorkspaces } from "src/api/queries";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { OrgWithWorkspaces } from "src/layout/types";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const useWorkspaceAndOrg = () => {
  const { data: rawOrgs, isLoading: isOrgsLoading } = useUserOrganizations();
  const { data: workspaces, isLoading: isWorkspacesLoading } = useWorkspaces();
  const { wsId, orgId } = useParamsDecode<DashboardPageParamsT>();

  const workspace = useMemo(() => {
    if (workspaces) {
      return workspaces.find((ws) => ws.id === wsId) ?? null;
    }

    return null;
  }, [workspaces, wsId]);

  const orgs = useMemo(() => {
    if (!rawOrgs || !workspaces) {
      return [];
    }

    const workspacesByOrgId = workspaces.reduce(
      (acc, workspace) => {
        const orgWorkspaces: WorkspaceDataplane[] = acc[
          workspace.organization_id
        ]
          ? acc[workspace.organization_id]
          : [];
        return {
          ...acc,
          [workspace.organization_id]: [...orgWorkspaces, workspace],
        };
      },
      {} as Record<string, WorkspaceDataplane[]>,
    );

    return rawOrgs
      .map(
        (rawOrg) =>
          ({
            ...rawOrg,
            workspaces: (workspacesByOrgId[rawOrg.id] || []).sort((a, b) =>
              a.name.localeCompare(b.name),
            ),
          }) as OrgWithWorkspaces,
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [rawOrgs, workspaces]);

  const org = useMemo(() => {
    if (orgs) {
      return orgs.find((userOrg) => userOrg.id === orgId) || null;
    }

    return null;
  }, [orgs, orgId]);

  return {
    workspace,
    workspaces,
    org,
    orgs,
    isLoading: isOrgsLoading || isWorkspacesLoading,
  };
};
