import React from "react";

import { useConnection } from "src/api/connectApi/queries";
import { Webhook } from "src/clients/flow-api";
import { Tooltip } from "src/design-system/Tooltip";
import { useWorkspaceAndOrg } from "src/utils/useCurrentWorkspace";
import { cellClassNames } from "src/webhooks/WebhooksTable/tableConfig";

type Props = {
  webhook: Webhook;
};

export const EndpointDetails: React.FC<Props> = ({ webhook }) => {
  const { workspace } = useWorkspaceAndOrg();
  const connection = useConnection({
    baseUrl: workspace?.base_url,
    id: webhook.connection_id,
    // Don't refetch here because connections can get deleted on this site which can break in flight refetches
    // The connections are internal anyway and can thus not be directly edited by users
    neverRefetch: true,
  });
  const baseUrl = connection.data?.configuration.base_url;

  return (
    <div
      className={`${cellClassNames} max-w-66 truncate font-inter-normal-12px`}
    >
      <Tooltip disabled={!baseUrl} placement="top" title={baseUrl} asChild>
        <span>
          {connection.isLoading ? "..." : (baseUrl ?? "<unable to load>")}
        </span>
      </Tooltip>
    </div>
  );
};
