import {
  faCheck,
  faChevronDown,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Combobox as HeadlessComboboxWrapper,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui2/react";
import { groupBy, isString, omit } from "lodash";
import { forwardRef, Fragment, useState } from "react";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";
import { useFuseSearch } from "src/utils/useFuseSearch";

export type Section = { key: string; displayText: string };

type Option<Sections extends readonly Section[] | undefined> = {
  key: string;
  value: React.ReactNode;
  disabled?: boolean | string;
  searchText?: string;
} & (Sections extends readonly Section[]
  ? { sectionKey: Sections[number]["key"] }
  : {});

type sectionRenderer = (
  section: Section,
  index: number,
) => React.ReactNode | undefined;

type Props<Sections extends readonly Section[] | undefined> = {
  options: Option<Sections>[];
  value?: Nullable<string>;
  onChange?: (value: Nullable<string>) => void;
  placeholder?: string;
  disabled?: boolean;
  showResetButton?: boolean;
  monospaced?: boolean;
  dataLoc?: string;
  sections?: Sections;
  dropdownPlaceholder?: React.ReactNode;
  errored?: boolean;
  sectionRenderer?: sectionRenderer;
};

export const Combobox = <
  const Sections extends readonly Section[] | undefined = undefined,
>(
  props: Props<Sections>,
) => {
  const [query, setQuery] = useState("");

  const hasSearchText = props.options[0]?.searchText;

  const search = useFuseSearch(props.options, {
    threshold: 0.5,
    keys: hasSearchText ? ["searchText"] : ["key"],
  });

  const results = search(query);

  const resetButtonAvailable = props.showResetButton && props.value;

  const onReset = () => {
    props.onChange?.(null);
  };

  return (
    <HeadlessComboboxWrapper
      as="div"
      data-loc={props.dataLoc}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      onClose={() => setQuery("")}
    >
      <div className="relative">
        <ComboboxInput
          className={twJoin(
            "h-8 w-full rounded-lg border border-gray-200 py-1 pl-3 placeholder:text-gray-500 focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25 disabled:cursor-not-allowed",
            props.monospaced
              ? "font-mono text-xs text-indigo-600 placeholder:font-mono"
              : "text-gray-800 font-inter-normal-12px placeholder:font-inter-normal-12px",
            resetButtonAvailable ? "pr-16" : "pr-7",
            props.errored && "border-red-600 ring-2 ring-red-400/25",
          )}
          placeholder={props.placeholder}
          onChange={(event) => setQuery(event.target.value)}
        />
        <div className="absolute right-0 top-1/2 flex -translate-y-1/2 items-center justify-start pr-2">
          <ComboboxButton data-loc="expand-combobox-button">
            <Icon
              color="text-gray-500"
              disabled={props.disabled}
              icon={faChevronDown}
              size="xs"
            />
          </ComboboxButton>
          {resetButtonAvailable && (
            <div
              className="flex items-center justify-start"
              data-loc={props.dataLoc && `${props.dataLoc}-reset`}
            >
              <div className="mx-1.5 h-4 w-[1px] bg-gray-200" />
              <Icon
                color="text-gray-500"
                disabled={props.disabled}
                icon={faTimes}
                size="xs"
                onClick={onReset}
              />
            </div>
          )}
        </div>
      </div>
      <ComboboxOptions
        anchor={{
          to: "bottom",
          gap: 8,
        }}
        className={twJoin(
          "decideScrollbar z-50 max-h-64 overflow-auto rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none",
          "transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0",
          "w-[var(--input-width)]",
        )}
        data-loc={`${props.dataLoc}-dropdown`}
        transition
      >
        {props.options.length === 0 ? (
          props.dropdownPlaceholder
        ) : props.sections ? (
          <ResultsWithSections
            monospaced={props.monospaced}
            results={results}
            sectionRenderer={props.sectionRenderer}
            sections={props.sections}
          />
        ) : (
          <Results monospaced={props.monospaced} results={results} />
        )}
      </ComboboxOptions>
    </HeadlessComboboxWrapper>
  );
};

const Results = <Sections extends readonly Section[] | undefined>({
  results,
  monospaced,
}: {
  results: Option<Sections>[];
  monospaced?: boolean;
}) => {
  return results.map((result) =>
    isString(result.disabled) ? (
      <Tooltip
        key={result.key}
        body={result.disabled}
        placement="right"
        asChild
      >
        <ResultOption monospaced={monospaced} result={result} />
      </Tooltip>
    ) : (
      <ResultOption key={result.key} monospaced={monospaced} result={result} />
    ),
  );
};

const ResultsWithSections = <Sections extends readonly Section[] | undefined>({
  results,
  sections,
  monospaced,
  sectionRenderer,
}: {
  results: Option<Sections>[];
  sections: Sections;
  monospaced?: boolean;
  sectionRenderer?: sectionRenderer;
}) => {
  if (!sections) return;

  const resultsGroupedBySections = groupBy(results, (r) =>
    "sectionKey" in r ? r.sectionKey : "",
  );

  const sectionsWithResults = sections
    .filter((section) => section.key in resultsGroupedBySections)
    .map((section) => ({
      ...section,
      results: resultsGroupedBySections[section.key],
    }));

  return (
    <>
      {sectionsWithResults.map((section, i) => (
        <Fragment key={section.key}>
          {sectionRenderer?.(omit(section, "results"), i) ?? (
            <p
              className={twJoin(
                "pb-1.5 pl-4 text-gray-500 font-inter-normal-12px",
                i === 0 ? "pt-1.5" : "pt-3.5",
              )}
            >
              {section.displayText}
            </p>
          )}
          <Results monospaced={monospaced} results={section.results} />
        </Fragment>
      ))}
    </>
  );
};

const ResultOption = forwardRef<
  HTMLLIElement,
  {
    monospaced?: boolean;
    result: Option<readonly Section[] | undefined>;
  }
>(({ monospaced, result }, ref) => (
  <ComboboxOption
    ref={ref}
    className={twJoin(
      "flex items-center justify-between px-4 py-2.5 font-inter-normal-13px",
      result.disabled
        ? "cursor-default text-gray-500"
        : "cursor-pointer text-gray-800 hover:bg-gray-50 ui-active:bg-gray-50",
      monospaced && "text-indigo-600 font-code-13",
    )}
    disabled={!!result.disabled}
    value={result.key}
  >
    {({ selected }) => (
      <>
        <div className="min-w-0 flex-1">{result.value}</div>
        {selected && (
          <span>
            <div>
              <Icon color="text-indigo-500" icon={faCheck} size="sm" />
            </div>
          </span>
        )}
      </>
    )}
  </ComboboxOption>
));
