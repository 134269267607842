import { capitalize, get, isNull } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { JSONSchemaPropertyDefinition } from "src/api/connectApi/manifestTypes";
import { Select } from "src/base-components/Select";
import {
  FormPathT,
  ManifestFormType,
} from "src/connections/config/manifest/types";
import { isFieldMultiSelect } from "src/connections/config/manifest/utils";

type EnumFieldPropsT = {
  formPath: FormPathT;
  fieldKey: string;
  prefix: string;
  jsonSchemaDefinition: JSONSchemaPropertyDefinition;
  isRequired: boolean;
};

export const EnumField: React.FC<EnumFieldPropsT> = ({
  formPath,
  fieldKey,
  prefix,
  jsonSchemaDefinition,
  isRequired,
}) => {
  const { control, formState } = useFormContext<ManifestFormType>();

  // Satisfy lint
  if (typeof jsonSchemaDefinition === "boolean") {
    throw new Error("Boolean type is not supported for EnumField");
  }

  const name: `${FormPathT}.${string}` = `${formPath}.${fieldKey}`;
  const { enumLabels = {} } = jsonSchemaDefinition;
  const multiple = isFieldMultiSelect(jsonSchemaDefinition);

  const selectOptions =
    jsonSchemaDefinition.enum?.map((option, i) => {
      let value = option?.toString();

      if (typeof option === "string") {
        value = enumLabels[option] ?? option;
      }

      return {
        key: i.toString(),
        value,
      };
    }) ?? [];

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        const selectProps = {
          dataLoc: `${prefix}-form-select`,
          errored: !!get(formState.errors, fieldKey),
          options: selectOptions,
          placeholder: `Select ${jsonSchemaDefinition.title || "option"}`,
          value: value
            ? jsonSchemaDefinition.enum
                ?.indexOf(value as string | number)
                .toString()
            : null,
        };

        const multiSelectOnChange = (keys: string[]) => {
          return onChange(
            keys.map((key) => jsonSchemaDefinition.enum?.[parseInt(key)]),
          );
        };

        const singleSelectOnChange = (key: Nullable<string>) => {
          if (!isRequired) {
            return onChange(
              isNull(key) ? null : jsonSchemaDefinition.enum?.[parseInt(key)],
            );
          }

          return onChange(jsonSchemaDefinition.enum?.[parseInt(key as string)]);
        };

        if (multiple) {
          return (
            <Select
              {...selectProps}
              searchable={false}
              showResetButton={!isRequired}
              value={
                value
                  ? value.map((v: string | number) =>
                      jsonSchemaDefinition.enum?.indexOf(v).toString(),
                    )
                  : []
              }
              multiple
              onChange={multiSelectOnChange}
            />
          );
        }

        // split into separate cases, otherwise typescript will complain about the onChange function
        return !isRequired ? (
          <Select
            {...selectProps}
            showResetButton
            onChange={singleSelectOnChange}
          />
        ) : (
          <Select {...selectProps} onChange={singleSelectOnChange} />
        );
      }}
      rules={{
        required: isRequired && `${capitalize(fieldKey)} is required`,
      }}
    />
  );
};
