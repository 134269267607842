import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  InputSchemaTypes,
  JsonRefPath,
  OutputSchemaTypes,
} from "src/api/flowTypes";
import { FixedSimpleDropdown } from "src/base-components/FixedSimpleDropdown";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { EventConfigOutput } from "src/clients/features-control";
import { getSchemaIcon } from "src/entities/entityView/utils";
import { EntitySchemaResource, useEntitySchemas } from "src/entities/queries";
import { useEventTypes } from "src/eventsCatalogue/queries";
import { getEventIcon } from "src/eventsCatalogue/utils";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { SchemaTypeSelectorProps, SchemaUIT } from "src/schema/utils";

// TODO AUTH-6566: Test that this stays in sync
const entityNameToJsonRefPath = (entityName: string): JsonRefPath => {
  return `#/$defs/Entities@${entityName}`;
};

const eventNameToJsonRefPath = (eventName: string): JsonRefPath => {
  return `#/$defs/Events@${eventName}`;
};

const INPUT_SCHEMA_TYPES_OPTIONS_BASE = Object.entries(InputSchemaTypes).map(
  ([key, value]) => ({
    key: key,
    value: value,
    icon: SCHEMA_TYPE_ICONS[value],
  }),
);

const getEntitySchemaOptions = (entitySchemas: EntitySchemaResource[]) => {
  return entitySchemas?.map((entity) => ({
    key: entityNameToJsonRefPath(entity._id),
    value: entity._display_name_singular,
    icon: getSchemaIcon(entity),
  }));
};

const getEventSchemaOptions = (eventSchemas: EventConfigOutput[]) =>
  eventSchemas?.map((eventSchema) => ({
    key: eventNameToJsonRefPath(eventSchema.event_type),
    value: eventSchema.display_name_singular,
    icon: getEventIcon(eventSchema),
  }));

const OUTPUT_SCHEMA_TYPES_OPTIONS = Object.entries(OutputSchemaTypes).map(
  ([key, value]) => ({
    key: key,
    value: value,
    icon: SCHEMA_TYPE_ICONS[value],
  }),
);

export const SchemaTypeSelector: React.FC<SchemaTypeSelectorProps> = ({
  rowName,
  disabled,
  index,
  type,
}) => {
  const { workspace } = useWorkspaceContext();
  const { control, setValue } = useFormContext<SchemaUIT>();
  const { data: entitySchemas } = useEntitySchemas({
    baseUrl: workspace.base_url!,
  });
  const { data: eventSchemas } = useEventTypes();
  const isEnrichedSchema = isFeatureFlagEnabled(FEATURE_FLAGS.enrichedSchema);
  const entitySchemaOptions =
    isEnrichedSchema && entitySchemas?.entities
      ? getEntitySchemaOptions(entitySchemas?.entities)
      : [];
  const eventSchemaOptions =
    isEnrichedSchema && eventSchemas ? getEventSchemaOptions(eventSchemas) : [];

  return (
    <Controller
      control={control}
      name={`${rowName}.type.0`}
      render={(props) => (
        <div className="w-[140px]">
          <FixedSimpleDropdown
            buttonDataLoc="schema-field-type"
            disabled={disabled}
            elements={
              type === "input"
                ? [
                    ...INPUT_SCHEMA_TYPES_OPTIONS_BASE,
                    ...entitySchemaOptions,
                    ...eventSchemaOptions,
                  ]
                : OUTPUT_SCHEMA_TYPES_OPTIONS
            }
            placeholder="Select type"
            placement="bottomLeft"
            selectedKey={props.field.value}
            onSelect={(newType) => {
              if (newType === "enum") {
                if (props.field.value !== "enum") {
                  setValue(`properties.${index}.enum`, [
                    { value: '"value_1"' },
                  ]);
                }
              } else {
                setValue(`properties.${index}.enum`, undefined);
              }
              props.field.onChange(newType);
            }}
          />
        </div>
      )}
    />
  );
};
