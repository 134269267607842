import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const useMetricsRefresh = () => {
  const queryClient = useQueryClient();
  const fetchingMetrics = useIsFetching({
    queryKey: ["metrics"],
    exact: false,
  });
  const [query, setQuery] = useState<any>(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!fetchingMetrics) {
      setRefreshing(false);

      setQuery(
        queryClient.getQueryState(["metrics"], {
          exact: false,
          type: "active",
        }),
      );
    }
  }, [fetchingMetrics, queryClient]);

  const refresh = () => {
    setRefreshing(true);

    queryClient.invalidateQueries({
      queryKey: ["metrics"],
    });
  };

  return {
    refreshing,
    refresh,
    dataUpdatedAt: query?.dataUpdatedAt,
  };
};
