import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useEffect } from "react";

import { GenericObjectT } from "src/api/flowTypes";
import {
  organizationUsersApi,
  organizationWsUsersApi,
  organizationsApi,
} from "src/api/taktileApi";
import {
  OrganizationAuthSourceCreateNamed,
  OrganizationAuthSourceResponse,
  OrganizationUser,
} from "src/clients/taktile-api";

export const organizationUsersKey = {
  all: ["organizationUsers"],
  list: (orgId: string) => [...organizationUsersKey.all, "list", orgId],
};

export const organizationAuthSourceKey = {
  all: ["organizationAuthSource"],
  list: (orgId: string) => [...organizationAuthSourceKey.all, "list", orgId],
};

export const useOrganizationUser = (orgId: string, userId: string) => {
  return useQuery<OrganizationUser, Error>({
    queryKey: [userId],
    queryFn: async () =>
      (
        await organizationUsersApi.getUserApiV1OrganizationsOrgIdUsersUserIdGet(
          orgId,
          userId,
        )
      ).data,
  });
};

export const useOrganizationUsers = (
  orgId: string,
  includeDeactivated?: boolean,
) => {
  const PAGE_SIZE = 30;
  return useInfiniteQuery<OrganizationUser[], Error>({
    queryKey: organizationUsersKey.list(orgId),
    queryFn: async ({ pageParam = 0 }): Promise<OrganizationUser[]> =>
      (
        await organizationUsersApi.getUsersApiV1OrganizationsOrgIdUsersGet(
          orgId,
          includeDeactivated,
          pageParam,
          PAGE_SIZE,
        )
      ).data,
    getNextPageParam: (lastPage, pages) =>
      lastPage.length === PAGE_SIZE ? pages.length * PAGE_SIZE : undefined,
  });
};

export const useAllOrganizationUsers = (
  orgId: string,
  includeDeactivated?: boolean,
) => {
  const usersResult = useOrganizationUsers(orgId, includeDeactivated);

  // Auto fetch all results
  useEffect(() => {
    if (!usersResult.isFetching && usersResult.hasNextPage) {
      usersResult.fetchNextPage();
    }
    // We only care about the isFetching and hasNextPage states
    // usersResult is not a stable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersResult.isFetching, usersResult.hasNextPage]);

  return usersResult;
};

export const useOrganizationAuthSources = (orgId: string) => {
  return useQuery<OrganizationAuthSourceResponse[], Error>({
    queryKey: organizationAuthSourceKey.list(orgId),
    queryFn: async () => {
      const response =
        await organizationsApi.getOrganizationAuthSourcesApiV1OrganizationsOrganizationIdAuthSourcesGet(
          orgId,
        );

      return response.data;
    },
  });
};

export const useDeleteOrganizationAuthSource = (
  orgId: string,
  authSourceId: string,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      organizationsApi.deleteOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesAuthSourceIdDelete(
        orgId,
        authSourceId,
      ),
    onSettled: () => {
      queryClient.invalidateQueries(organizationAuthSourceKey.list(orgId));
    },
  });
};

export const useCreateOrganizationAuthSource = (orgId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (authSource: OrganizationAuthSourceCreateNamed) =>
      organizationsApi.postOrganizationAuthSourceApiV1OrganizationsOrganizationIdAuthSourcesPost(
        orgId,
        authSource,
      ),
    onSettled: () => {
      queryClient.invalidateQueries(organizationAuthSourceKey.list(orgId));
    },
  });
};

export const workspaceUsersKey = {
  all: ["workspaceUsers"],
  list: (wsId: string, params?: GenericObjectT) => [
    ...workspaceUsersKey.all,
    "list",
    wsId,
    JSON.stringify(params),
  ],
};

type WorkspaceUsersParams = {
  include_deactivated?: boolean;
  limit?: number;
};

export const useWorkspaceUsers = (
  orgId: string,
  wsId: string,
  params?: WorkspaceUsersParams,
) => {
  return useQuery<OrganizationUser[], Error>({
    queryKey: workspaceUsersKey.list(wsId, params),
    queryFn: async () =>
      (
        await organizationWsUsersApi.getUsersApiV1OrganizationsOrgIdWorkspacesWorkspaceIdUsersGet(
          orgId,
          wsId,
          params?.include_deactivated ?? true,
          undefined,
          undefined,
          params?.limit || 200,
        )
      ).data,
    enabled: !!orgId && !!wsId,
    staleTime: 30 * 1000,
    refetchOnMount: false,
  });
};
