import { useFormContext } from "react-hook-form";

import { ErrorHint } from "src/base-components/ErrorHint";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { getSchemaIcon } from "src/entities/entityView/utils";
import { EntitySchemaResource } from "src/entities/queries";

export const SchemaBaseEditor: React.FC<{
  isLoading: boolean;
}> = ({ isLoading }) => {
  const form = useFormContext<EntitySchemaResource>();
  const schemaIcon = getSchemaIcon(form.getValues());

  return (
    <div className="flex gap-x-2">
      <div className="flex h-15 w-15 shrink-0 items-center justify-center rounded-xl border border-gray-200">
        {isLoading ? (
          <SkeletonPlaceholder height="h-6" width="w-6" />
        ) : (
          <Icon color="text-gray-600" icon={schemaIcon} size="2xl" />
        )}
      </div>
      <div className="flex min-w-0 flex-1 flex-col gap-y-2">
        <div className="text-gray-500 font-inter-normal-12px">Name</div>
        {isLoading ? (
          <SkeletonPlaceholder
            height="h-8"
            rounded="rounded-lg"
            width="w-full"
          />
        ) : (
          <Input
            disabled
            fullWidth
            monospaced
            {...form.register("_id", { required: true })}
          />
        )}
      </div>
      <div className="flex min-w-0 flex-1 flex-col gap-y-2">
        <div className="text-gray-500 font-inter-normal-12px">
          Display Name Singular
        </div>
        {isLoading ? (
          <SkeletonPlaceholder
            height="h-8"
            rounded="rounded-lg"
            width="w-full"
          />
        ) : (
          <Input
            errored={!!form.formState.errors._display_name_singular}
            fullWidth
            {...form.register("_display_name_singular", {
              required: "This field is required",
            })}
          />
        )}
        {form.formState.errors._display_name_singular && (
          <ErrorHint>
            {form.formState.errors._display_name_singular?.message}
          </ErrorHint>
        )}
      </div>
      <div className="flex min-w-0 flex-1 flex-col gap-y-2">
        <div className="text-gray-500 font-inter-normal-12px">
          Display Name Plural
        </div>
        {isLoading ? (
          <SkeletonPlaceholder
            height="h-8"
            rounded="rounded-lg"
            width="w-full"
          />
        ) : (
          <Input
            errored={!!form.formState.errors._display_name_plural}
            fullWidth
            {...form.register("_display_name_plural", {
              required: "This field is required",
            })}
          />
        )}
        {form.formState.errors._display_name_plural && (
          <ErrorHint>
            {form.formState.errors._display_name_plural?.message}
          </ErrorHint>
        )}
      </div>
    </div>
  );
};
