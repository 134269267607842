import { faBan, faEye } from "@fortawesome/pro-regular-svg-icons";
import {
  ColumnDef,
  CellContext,
  Cell,
  createColumnHelper,
} from "@tanstack/react-table";
import { groupBy, isNil, isObject, isString } from "lodash";
import { twMerge } from "tailwind-merge";

import {
  DecisionsOutcomeFilter,
  DevisionHistoryV3GetRequest,
  EqualityOperator,
  MultipleOutcomeFilter,
  OutcomePresenceFilter,
  SingleOutcomeFilter,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import {
  FlowT,
  FlowVersionT,
  isJsonRefPath,
  SchemaTypesT,
} from "src/api/flowTypes";
import { DecisionEnvironment } from "src/api/types";
import { ColumnFilter } from "src/base-components/ColumnFilter";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { useChangeHistories } from "src/changeHistory/queries";
import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { RawCell } from "src/dataTable/RawCell";
import { ResultCell } from "src/dataTable/ResultCell";
import { outcomeFieldsPrefix } from "src/decisionsOverview/useViewOptions";
import {
  isValidEntityId,
  transformSchemaToOptions,
} from "src/decisionsOverview/utils";
import { OutcomeItem } from "src/decisionsOverview/utils";
import { Tooltip } from "src/design-system/Tooltip";
import {
  getParentFlowsWithVersions,
  singleFlowGetParentFlows,
  singleFlowGetPotentialParentVersions,
} from "src/flow/Model";
import { getSourceDetails } from "src/flow/decisionHistory/DecisionHistoryUtils";
import { OriginColumnFilter } from "src/flow/decisionHistory/OriginColumnFilter";
import {
  SharedDecisionHistoryStatus,
  POSSIBLE_DECISION_STATUS_CODES,
} from "src/flow/decisionHistory/SharedStatusColumn";
import { SourceDetails, SourcePill } from "src/flow/decisionHistory/SourcePill";
import { DateRangeFilter } from "src/flow/decisionHistory/filters/DateRangeFilter";
import { EnumFilter } from "src/flow/decisionHistory/filters/EnumFilter";
import { OutcomeFieldFilter } from "src/flow/decisionHistory/filters/OutcomeFieldFilter";
import { Job } from "src/jobs/types";
import { BOOLEAN_FILTERS, OUTCOME_KEY_FILTERS } from "src/outcomes/constants";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToDecisionsOverview } from "src/router/urls";
import { formatDate } from "src/utils/datetime";
import { formatDuration } from "src/utils/numbers";
import {
  getDecisionDisabledReason,
  getDisableReasonMessage,
} from "src/versionDecisionHistory/Table";

export type TracingHoverBehavior = "trace" | "expand";

type GetDecisionHistoryColumnsArgs = {
  orgId: string;
  wsId: string;
  flow: FlowT;
  jobs: Job[];
  filterFields: DevisionHistoryV3GetRequest;
  changeFilterFields: (fields: DevisionHistoryV3GetRequest) => void;
  availableFlowVersions: FlowVersionT[];
  env: DecisionEnvironment;
  outcomeOptions: OutcomeItem[];
  includeEntityLinks: boolean;
  includeInputOutput: boolean;
  tracingHoverBehavior?: TracingHoverBehavior;
};

export enum DecisionHistoryColumns {
  DecisionId = "decision.decision_id",
  EntityId = "decision.entity_id",
  Datetime = "decision.datetime",
  Status = "decision.status",
  Origin = "decision.origin",
  Duration = "decision.duration",
  Version = "decision.version",
}

export const OPTIONS_COLUMN_ID = "options-group";

export type ExtendedHistoricalDecisionV3 = HistoricalDecisionV3 & {
  outcome?: Record<string, unknown>;
};

const columnHelper = createColumnHelper<ExtendedHistoricalDecisionV3>();

const availableStatusCodes = POSSIBLE_DECISION_STATUS_CODES.map(
  (option) => option.key,
);

const HeaderCell: React.FC<{
  children?: React.ReactNode;
  className: string;
}> = ({ children, className }) => (
  <div
    className={twMerge(
      "whitespace-nowrap p-1.5 text-left text-gray-800 font-inter-medium-12px",
      className,
    )}
  >
    {children}
  </div>
);

const TypedFieldHeader: React.FC<{
  label: string;
  type: SchemaTypesT;
  children?: React.ReactNode;
}> = ({ label, type, children }) => (
  <HeaderCell className="flex flex-shrink-0 items-center gap-x-1">
    <Tooltip placement="bottom" title={type} asChild>
      <Icon
        color="text-gray-500"
        icon={
          isJsonRefPath(type)
            ? SCHEMA_TYPE_ICONS["entity"]
            : SCHEMA_TYPE_ICONS[type]
        }
        size="xs"
      />
    </Tooltip>
    <span className="inline-block">{label}</span>
    {children}
  </HeaderCell>
);

const GroupHeader: React.FC<{ label: string; children?: React.ReactNode }> = ({
  label,
  children,
}) => (
  <HeaderCell className="flex h-9 flex-shrink-0 items-center gap-x-1 bg-gray-50 py-2">
    <span className="inline-block">{label}</span>
    {children}
  </HeaderCell>
);

export const getDecisionHistoryColumns = ({
  orgId,
  wsId,
  flow,
  jobs,
  changeFilterFields,
  filterFields,
  availableFlowVersions,
  env,
  includeEntityLinks,
  includeInputOutput,
  outcomeOptions,
  tracingHoverBehavior,
}: GetDecisionHistoryColumnsArgs): ColumnDef<HistoricalDecisionV3, any>[] => {
  const { input: inputOptions, output: outputOptions } = includeInputOutput
    ? transformSchemaToOptions(flow.versions)
    : { input: [], output: [] };

  const resetFieldFilter = (key: keyof DevisionHistoryV3GetRequest) => {
    const { [key]: _, ...fieldsWithoutStatusCode } = filterFields;
    return changeFilterFields(fieldsWithoutStatusCode);
  };

  const setStatusCodeFilter = (
    newStatusCode: (typeof POSSIBLE_DECISION_STATUS_CODES)[number]["key"],
  ) => {
    // if selected field is the same than the previously selected
    // then we remove the filter.
    if (newStatusCode === filterFields.statusCodes) {
      resetFieldFilter("statusCodes");
    } else {
      const currentFields = filterFields;
      const newFields = { ...currentFields, statusCodes: newStatusCode };
      changeFilterFields(newFields);
    }
  };

  const setVersionIdFilter = (newVersionId: string) => {
    // if selected field is the same than the previously selected
    // then we remove the filter.
    if (newVersionId === filterFields.flowVersionIds) {
      resetFieldFilter("flowVersionIds");
    } else {
      const currentFields = filterFields;
      const newFields = { ...currentFields, flowVersionIds: newVersionId };
      changeFilterFields(newFields);
    }
  };

  const setOutcomeFilter = (
    outcomeTypeKey: string,
    value: DecisionsOutcomeFilter["filter"],
  ) => {
    const propertyKey = isObject(value) ? value.property : null;
    const sameKeyFilter = filterFields.outcomeFilters?.find(
      (f) =>
        f.outcome_type_key === outcomeTypeKey &&
        (propertyKey
          ? isObject(f.filter) && f.filter.property === propertyKey
          : isString(f.filter)),
    );

    // If no such filter or the value is different, we set the filter
    if (!sameKeyFilter || sameKeyFilter.filter !== value) {
      const newFilter: DecisionsOutcomeFilter = {
        outcome_type_key: outcomeTypeKey,
        filter: value,
      };

      changeFilterFields({
        ...filterFields,
        outcomeFilters: [
          ...(filterFields.outcomeFilters ?? []).filter(
            (fltr) => fltr !== sameKeyFilter,
          ),
          newFilter,
        ],
      });
    } else {
      // If the value is the same, we remove the filter
      changeFilterFields({
        ...filterFields,
        outcomeFilters: (filterFields.outcomeFilters ?? []).filter(
          (fltr) => fltr !== sameKeyFilter,
        ),
      });
    }
  };

  const setOutcomeDateFilter = (
    outcomeTypeKey: string,
    values: SingleOutcomeFilter["filter"][],
  ) => {
    const firstFilter = values[0];
    const propertyKey = firstFilter.property;
    const sameKeyFilters = filterFields.outcomeFilters?.filter(
      (f) =>
        f.outcome_type_key === outcomeTypeKey &&
        propertyKey &&
        isObject(f.filter) &&
        f.filter.property === propertyKey,
    );

    const newFilters = values.map((filter) => ({
      outcome_type_key: outcomeTypeKey,
      filter,
    }));

    changeFilterFields({
      ...filterFields,
      outcomeFilters: [
        ...(filterFields.outcomeFilters ?? []).filter(
          (fltr) =>
            !sameKeyFilters?.some((sameKeyFilter) => sameKeyFilter === fltr),
        ),
        ...newFilters,
      ],
    });
  };

  const resetOutcomeFilter = (
    outcomeTypeKey: string,
    fieldKey: string | null,
  ) => {
    changeFilterFields({
      ...filterFields,
      outcomeFilters: (filterFields.outcomeFilters ?? []).filter((fltr) => {
        if (fieldKey === null) {
          return !(
            fltr.outcome_type_key === outcomeTypeKey &&
            typeof fltr.filter === "string"
          );
        }

        return !(
          fltr.outcome_type_key === outcomeTypeKey &&
          isObject(fltr.filter) &&
          fltr.filter.property === fieldKey
        );
      }),
    });
  };

  const statusFilterOptions = POSSIBLE_DECISION_STATUS_CODES.map((option) => ({
    ...option,
    disabled: !availableStatusCodes.includes(option.key),
  }));

  const parentFlows = singleFlowGetParentFlows(flow);
  const potentialParentVersions = singleFlowGetPotentialParentVersions(flow);
  const parentFlowsWithVersions = getParentFlowsWithVersions(flow);

  const columns: ColumnDef<HistoricalDecisionV3, any>[] = [
    columnHelper.group({
      id: "metadata",
      header: () => <GroupHeader label="Metadata" />,
      columns: [
        {
          id: DecisionHistoryColumns.DecisionId,
          size: 50,
          header: () => (
            <HeaderCell className="flex items-center">
              <span className="inline-block">Decision ID</span>
            </HeaderCell>
          ),
          accessorFn: (row) => (
            <CopyTextIcon
              color="hover:text-indigo-500"
              dataLoc={`copy-id-${row["id"]}`}
              value={row["id"]}
            />
          ),
          cell: ({ cell }) => (
            <RawCell<HistoricalDecisionV3>
              cell={cell}
              classNameOverrides="max-w-none p-1.5"
            />
          ),
        },
        {
          id: DecisionHistoryColumns.EntityId,
          size: 50,
          header: () => (
            <HeaderCell className="flex items-center">
              <span className="inline-block">Entity ID</span>
            </HeaderCell>
          ),
          accessorFn: (row) =>
            row["entity_id"] ? (
              <CopyTextIcon
                color="hover:text-indigo-500"
                value={row["entity_id"]}
              />
            ) : (
              <div className="ml-1">—</div>
            ),
          cell: ({ cell }) => (
            <RawCell<HistoricalDecisionV3>
              cell={cell}
              classNameOverrides="max-w-none p-1.5 py-1.5"
            />
          ),
        },
        {
          id: DecisionHistoryColumns.Datetime,
          size: 70,
          header: () => <HeaderCell className="w-24">Time</HeaderCell>,
          accessorFn: (row) => formatDate(row.start_time, "dd MMM yy HH:mm:ss"),
          cell: ({ cell }) => (
            <RawCell<HistoricalDecisionV3>
              cell={cell}
              classNameOverrides="max-w-none w-36 p-1.5"
            />
          ),
        },
        {
          id: DecisionHistoryColumns.Status,
          size: 200,
          header: () => (
            <HeaderCell className="flex w-auto items-center">
              <span>Status</span>
              <span className="ml-1">
                <ColumnFilter
                  description="Filter by status"
                  elements={statusFilterOptions}
                  isFiltering={Boolean(filterFields.statusCodes)}
                  placement="bottomLeft"
                  selected={filterFields.statusCodes}
                  itemsPortal
                  onResetRequest={() => resetFieldFilter("statusCodes")}
                  onSelect={(value) =>
                    setStatusCodeFilter(
                      value as (typeof availableStatusCodes)[number],
                    )
                  }
                />
              </span>
            </HeaderCell>
          ),
          accessorFn: (row) => (
            <SharedDecisionHistoryStatus statusCode={row["status_code"]} />
          ),
          cell: ({ cell }: CellContext<HistoricalDecisionV3, any>) => {
            const message = (
              <span className="justify-start">{cell.getValue()}</span>
            );

            return (
              <ResultCell<HistoricalDecisionV3>
                cell={message}
                className="max-w-none overflow-auto p-1.5"
                objectDetailPosition="topRight"
              />
            );
          },
        },
        {
          id: DecisionHistoryColumns.Origin,
          size: 100,
          header: () => {
            return (
              <HeaderCell className="flex w-24 items-center">
                Origin{" "}
                <div>
                  <OriginColumnFilter
                    env={env}
                    flows={parentFlowsWithVersions}
                    jobs={jobs}
                    placement="bottom-start"
                    selected={filterFields}
                    onSelect={changeFilterFields}
                  />
                </div>
              </HeaderCell>
            );
          },
          accessorFn: (row) =>
            getSourceDetails(row, parentFlows, potentialParentVersions, jobs),
          cell: ({
            cell,
          }: CellContext<HistoricalDecisionV3, SourceDetails>) => {
            const sourceDetails = cell.getValue();
            const content = (
              <SourcePill
                decisionEnv={env}
                orgId={orgId}
                sourceDetails={sourceDetails}
                wsId={wsId}
              />
            );

            return (
              <RawCell<HistoricalDecisionV3>
                cell={content}
                classNameOverrides="max-w-[300px] w-auto p-1.5"
              />
            );
          },
        },
        {
          id: DecisionHistoryColumns.Duration,
          size: 100,
          header: () => (
            <HeaderCell className="flex items-center">
              <span className="inline-block">Duration</span>
            </HeaderCell>
          ),
          accessorFn: (row) => {
            const durationMs =
              new Date(row.end_time ?? new Date()).getTime() -
              new Date(row.start_time).getTime();
            return (
              <span className="w-full">
                <Tooltip
                  disabled={durationMs <= 1000}
                  placement="top"
                  title={`${durationMs}ms`}
                >
                  {formatDuration(durationMs, { onlyFirstUnit: true })}
                </Tooltip>
              </span>
            );
          },
          cell: ({ cell }) => (
            <RawCell<HistoricalDecisionV3>
              cell={cell}
              classNameOverrides="max-w-0 p-1.5"
            />
          ),
        },
        {
          id: DecisionHistoryColumns.Version,
          size: 100,
          header: () => (
            <HeaderCell className="flex items-center">
              <span className="inline-block">Version</span>
              <span className="ml-1">
                <ColumnFilter
                  description="Filter by version"
                  elements={availableFlowVersions.map((version) => ({
                    key: version.id,
                    value: version.name,
                  }))}
                  isFiltering={Boolean(filterFields.flowVersionIds)}
                  placement="bottomLeft"
                  selected={filterFields.flowVersionIds}
                  itemsPortal
                  onResetRequest={() => resetFieldFilter("flowVersionIds")}
                  onSelect={(key) => setVersionIdFilter(key)}
                />
              </span>
            </HeaderCell>
          ),
          accessorFn: (row) => {
            return <span className="w-full">{row?.flow?.version?.name}</span>;
          },
          cell: ({ cell }: CellContext<HistoricalDecisionV3, any>) => (
            <VersionCell cell={cell} />
          ),
        },
      ],
    }),
  ];

  if (inputOptions.length > 0) {
    columns.push(
      columnHelper.group({
        id: "input",
        header: () => <GroupHeader label="Inputs" />,
        columns: inputOptions.map(
          ({ label, value, type }) =>
            ({
              id: value,
              size: 100,
              header: () => <TypedFieldHeader label={label} type={type} />,
              accessorFn: (row) => (row.input as Record<string, any>)?.[label],
              cell: ({ cell }) => (
                <ResultCell<HistoricalDecisionV3>
                  cell={cell}
                  className="max-w-0 overflow-auto p-1.5"
                  objectDetailPosition="topRight"
                />
              ),
            }) as ColumnDef<HistoricalDecisionV3, any>,
        ),
      }),
    );
  }

  if (outputOptions.length > 0) {
    columns.push(
      columnHelper.group({
        id: "output",
        header: () => <GroupHeader label="Outputs" />,
        columns: outputOptions.map(
          ({ label, value, type }) =>
            ({
              id: value,
              size: 100,
              header: () => <TypedFieldHeader label={label} type={type} />,
              accessorFn: (row) => (row.output as Record<string, any>)?.[label],
              cell: ({ cell }) => (
                <ResultCell<HistoricalDecisionV3>
                  cell={cell}
                  className="max-w-0 overflow-auto p-1.5"
                  objectDetailPosition="topRight"
                />
              ),
            }) as ColumnDef<HistoricalDecisionV3, any>,
        ),
      }),
    );
  }

  if (outcomeOptions.length > 0) {
    columns.push(
      ...Object.entries(groupBy(outcomeOptions, "outcomeName")).map(
        ([outcomeName, outcomeItems]) => {
          const groupKey = outcomeItems[0].outcomeKey;
          const groupKeyWithoutPrefix = groupKey.replace(
            new RegExp(`^${outcomeFieldsPrefix}`),
            "",
          );

          return columnHelper.group({
            id: groupKey,
            header: () => {
              const outcomeFilter = filterFields.outcomeFilters?.find(
                (filter) =>
                  filter.outcome_type_key === groupKeyWithoutPrefix &&
                  isString(filter.filter),
              ) as OutcomePresenceFilter | null;

              return (
                <GroupHeader label={outcomeName}>
                  <span className="ml-1">
                    <ColumnFilter
                      dataLoc={`decision-history-outcome-filter-${groupKeyWithoutPrefix}`}
                      description="Filter by outcome"
                      elements={OUTCOME_KEY_FILTERS}
                      isFiltering={Boolean(outcomeFilter)}
                      placement="bottomLeft"
                      selected={outcomeFilter?.filter ?? undefined}
                      itemsPortal
                      onResetRequest={() =>
                        resetOutcomeFilter(groupKeyWithoutPrefix, null)
                      }
                      onSelect={(value) =>
                        setOutcomeFilter(
                          groupKeyWithoutPrefix,
                          value as DecisionsOutcomeFilter["filter"],
                        )
                      }
                    />
                  </span>
                </GroupHeader>
              );
            },
            columns: outcomeItems.map(
              ({ label, value, type, filterable, enumValues }) =>
                columnHelper.accessor(value as `outcome.${string}`, {
                  id: value,
                  header: () => (
                    <TypedFieldHeader label={label} type={type}>
                      {filterable && (
                        <OutcomeColumnFilter
                          enumValues={enumValues}
                          filters={filterFields.outcomeFilters}
                          propertyPath={value.replace(
                            new RegExp(`^${outcomeFieldsPrefix}`),
                            "",
                          )}
                          type={type}
                          onReset={resetOutcomeFilter}
                          onSelect={setOutcomeFilter}
                          onSelectDate={setOutcomeDateFilter}
                        />
                      )}
                    </TypedFieldHeader>
                  ),
                  size: 100,
                  cell: ({ cell, row }) => {
                    if (row.original.outcome === undefined) {
                      return <SkeletonPlaceholder height="h-4" width="w-4/5" />;
                    }
                    return (
                      <ResultCell<ExtendedHistoricalDecisionV3>
                        cell={cell}
                        className="max-w-0 overflow-auto p-1.5"
                        objectDetailPosition="topRight"
                      />
                    );
                  },
                }),
            ),
          });
        },
      ),
    );
  }

  if (tracingHoverBehavior || includeEntityLinks) {
    columns.push(
      columnHelper.group({
        id: OPTIONS_COLUMN_ID,
        header: () => <GroupHeader label="" />,
        columns:
          tracingHoverBehavior || includeEntityLinks
            ? [
                {
                  id: "options",
                  size: 50,
                  maxSize: 50,
                  header: () => " ",
                  accessorFn: () => "",
                  cell: ({ cell }) => (
                    <OptionsCell
                      availableFlowVersions={availableFlowVersions}
                      cell={cell}
                      includeEntityLink={includeEntityLinks}
                      tracingHoverBehavior={tracingHoverBehavior}
                    />
                  ),
                } as ColumnDef<HistoricalDecisionV3, any>,
              ]
            : [],
      }),
    );
  }

  return columns;
};

const OptionsCell: React.FC<{
  availableFlowVersions: FlowVersionT[];
  cell: Cell<HistoricalDecisionV3, any>;
  includeEntityLink: boolean;
  tracingHoverBehavior?: TracingHoverBehavior;
}> = ({
  cell,
  availableFlowVersions,
  includeEntityLink,
  tracingHoverBehavior,
}) => {
  const { orgId, workspace } = useWorkspaceContext();
  const decisionVersion = availableFlowVersions.find(
    (version) => version.id === cell.row.original?.flow?.version?.id,
  );
  const historyChanges = useChangeHistories(
    decisionVersion
      ? [
          {
            versionId: decisionVersion?.id,
            etag: decisionVersion?.etag ?? "00000000",
          },
        ]
      : [],
  );

  const disableReason =
    tracingHoverBehavior === "trace"
      ? getDecisionDisabledReason(
          cell.row.original,
          historyChanges?.[0]?.data?.etag,
          historyChanges?.[0]?.data?.created_at,
        )
      : null;

  const canDisplayEntityLink =
    includeEntityLink && isValidEntityId(cell.row.original.entity_id);

  const handleAction = () => {
    window.open(
      getBaseUrl() +
        getUrlToDecisionsOverview(orgId, workspace.id, {
          entityId: cell.row.original.entity_id,
        }),
      "_blank",
    );
  };

  const message = (
    <div className="invisible flex w-full items-center justify-end gap-x-2 group-hover/row:visible">
      {tracingHoverBehavior &&
        (disableReason ? (
          <Tooltip
            placement="top"
            title={getDisableReasonMessage(disableReason)}
          >
            <Icon
              color="text-gray-500"
              cursorType="default"
              icon={faBan}
              size="sm"
            />
          </Tooltip>
        ) : (
          <Icon color="text-gray-500" icon={faEye} size="sm" />
        ))}

      {canDisplayEntityLink && (
        <EllipsisOptionsDropdown
          elements={[
            {
              key: "nav",
              nav: "View related decisions",
              subtitle: (
                <>
                  Show all decisions with the same Entity ID{" "}
                  <Pill size="sm" variant="gray" maxWidth>
                    <Pill.Text fontType="code">
                      {cell.row.original.entity_id}
                    </Pill.Text>
                  </Pill>
                </>
              ),
              action: handleAction,
            },
          ]}
        />
      )}
    </div>
  );

  return (
    <RawCell<HistoricalDecisionV3>
      cell={message}
      classNameOverrides="max-w-full w-full p-1.5"
    />
  );
};

const VersionCell: React.FC<{
  cell: Cell<HistoricalDecisionV3, any>;
}> = ({ cell }) => {
  const message = (
    <Pill size="sm" variant="gray">
      <Pill.Text>{cell.getValue()}</Pill.Text>
    </Pill>
  );
  return (
    <RawCell<HistoricalDecisionV3>
      cell={message}
      classNameOverrides="max-w-full w-full p-1.5"
    />
  );
};

const OutcomeColumnFilter: React.FC<{
  filters: DecisionsOutcomeFilter[] | undefined;
  propertyPath: string;
  type: SchemaTypesT;
  enumValues: string[] | undefined;
  onReset: (outcomeTypeKey: string, fieldKey: string | null) => void;
  onSelect: (
    outcomeFilterKey: string,
    value: DecisionsOutcomeFilter["filter"],
  ) => void;
  onSelectDate: (
    outcomeTypeKey: string,
    value: SingleOutcomeFilter["filter"][],
  ) => void;
}> = ({
  filters,
  propertyPath,
  type,
  enumValues,
  onReset,
  onSelect,
  onSelectDate,
}) => {
  const [groupKey, property] = propertyPath.split(".");

  if (type === "boolean") {
    const filter = filters?.find(
      (filter) =>
        filter.outcome_type_key === groupKey &&
        isObject(filter.filter) &&
        filter.filter.property === property,
    ) as SingleOutcomeFilter | null;

    return (
      <span className="ml-1">
        <ColumnFilter
          dataLoc={`decision-history-outcome-filter-${groupKey}-${property}`}
          description="Filter by outcome"
          elements={BOOLEAN_FILTERS}
          isFiltering={Boolean(filter)}
          placement="bottomLeft"
          selected={filter?.filter.value.toString()}
          itemsPortal
          onResetRequest={() => onReset(groupKey, property)}
          onSelect={(value) =>
            onSelect(groupKey, {
              property,
              operator: "==",
              value: value === "true",
            })
          }
        />
      </span>
    );
  }

  if (type === "enum") {
    const filter = filters?.find(
      (filter) =>
        filter.outcome_type_key === groupKey &&
        isObject(filter.filter) &&
        filter.filter.property === property,
    ) as MultipleOutcomeFilter | null;

    return (
      <span className="ml-1">
        <EnumFilter
          dataLoc={`decision-history-outcome-filter-${groupKey}-${property}`}
          enumValues={enumValues ?? []}
          filter={filter}
          groupKey={groupKey}
          property={property}
          onApply={onSelect}
          onReset={onReset}
        />
      </span>
    );
  }

  if (type === "string" || type === "number" || type === "integer") {
    const filter = filters?.find(
      (filter) =>
        filter.outcome_type_key === groupKey &&
        isObject(filter.filter) &&
        filter.filter.property === property,
    ) as SingleOutcomeFilter | null;

    return (
      <OutcomeFieldFilter
        dataLoc={`decision-history-outcome-filter-${groupKey}-${property}`}
        filter={filter}
        operator={filter?.filter.operator}
        type={type}
        value={filter?.filter.value}
        onChange={(
          operator: EqualityOperator,
          value?: string | number | boolean,
        ) => {
          if (operator && !isNil(value)) {
            onSelect(groupKey, { property, operator, value });
            return;
          }

          if (filter) {
            onReset(groupKey, property);
          }
        }}
        onReset={() => onReset(groupKey, property)}
      />
    );
  }

  if (type === "date" || type === "datetime") {
    const dateFilters = (filters?.filter(
      (filter) =>
        filter.outcome_type_key === groupKey &&
        isObject(filter.filter) &&
        filter.filter.property === property,
    ) ?? []) as SingleOutcomeFilter[];

    return (
      <DateRangeFilter
        dataLoc={`decision-history-outcome-filter-${groupKey}-${property}`}
        filters={dateFilters}
        isFiltering={Boolean(dateFilters.length > 0)}
        property={property}
        onChange={(filters) => {
          if (filters.length > 0) {
            onSelectDate(groupKey, filters);
          }
        }}
        onReset={() => onReset(groupKey, property)}
      />
    );
  }

  return null;
};
