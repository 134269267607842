import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { endOfToday, startOfToday } from "date-fns";

import {
  eventsApi as eventsApiBase,
  featuresRetrieveApi,
} from "src/api/endpoints";
import {
  EventConfigGetResponse,
  EventConfigPatch,
} from "src/clients/features-control";
import {
  DeserializedEvent as DeserializedEventBase,
  GetEventByIdEventsEventTypeIdGetEnvironmentEnum,
  GetEventsEventsGetEnvironmentEnum,
} from "src/clients/features-retrieve";
import { queryClient } from "src/queryClient";
import { useWorkspaceContext } from "src/router/routerContextHooks";

const PREFIX_CACHE_KEY = "event_types";
const PREFIX_CACHE_KEY_LIST = "events";
const ONE_HOUR = 1000 * 60 * 60;

export type DeserializedEvent = DeserializedEventBase & {
  payload: {
    id: string;
    timestamp: string;
    [key: string]: any;
  };
};

export const useEventTypes = () => {
  const { workspace } = useWorkspaceContext();
  const eventsApi = eventsApiBase(`${workspace.base_url}`);

  return useQuery({
    queryKey: [PREFIX_CACHE_KEY, workspace.id],
    queryFn: async () => {
      const response = await eventsApi.getAllEventConfigsEventsGet();
      return response.data;
    },
    staleTime: ONE_HOUR,
  });
};

export const useEventType = (eventType: string) => {
  const { workspace } = useWorkspaceContext();
  const eventsApi = eventsApiBase(`${workspace.base_url}`);

  return useQuery({
    queryKey: [PREFIX_CACHE_KEY, workspace.id, eventType],
    queryFn: async () => {
      const response = await eventsApi.getEventConfigEventsEventTypeGet({
        eventType,
      });
      return response.data;
    },
    enabled: !!eventType,
    staleTime: ONE_HOUR,
  });
};

export const useUpdateEventType = () => {
  const queryClient = useQueryClient();
  const { workspace } = useWorkspaceContext();
  const eventsApi = eventsApiBase(workspace.base_url!);

  return useMutation({
    mutationFn: async ({
      etag,
      eventType,
      ...eventConfig
    }: EventConfigPatch & { etag: string; eventType: string }) => {
      const response = await eventsApi.updateEventConfigEventsEventTypePatch({
        eventType,
        eventConfigPatch: eventConfig,
        ifMatch: etag,
      });
      return response.data;
    },
    onSuccess: (_, { eventType }) => {
      queryClient.invalidateQueries({
        queryKey: [PREFIX_CACHE_KEY, workspace.id, eventType],
      });
    },
  });
};

type Environment = "sandbox" | "live";

type UseEventsParams = {
  eventType: string;
  environment: Environment;
  timeWindow?: { from?: string | null; to?: string | null };
  entityId?: string;
  filters?: Record<string, string | null>;
};

export const useEvents = (params: UseEventsParams) => {
  const { workspace } = useWorkspaceContext();
  const eventsRetrieveApi = featuresRetrieveApi(`${workspace.base_url}`);

  return useQuery<DeserializedEvent[], AxiosError>({
    queryKey: [
      PREFIX_CACHE_KEY_LIST,
      params.environment,
      params.entityId,
      params.timeWindow?.from,
      params.timeWindow?.to,
      params.eventType,
      params.filters,
    ],
    queryFn: async () => {
      const response = await eventsRetrieveApi.getEventsEventsGet(
        {
          eventType: params.eventType,
          startTimestamp:
            params.timeWindow?.from || startOfToday().toISOString(),
          endTimestamp: params.timeWindow?.to || endOfToday().toISOString(),
          environment: params.environment as GetEventsEventsGetEnvironmentEnum,
          entityId: params.entityId,
        },
        {
          params: params.filters,
        },
      );
      return response.data as DeserializedEvent[];
    },
    enabled: !!params.eventType,
  });
};

type UseEventParams = {
  eventType: string;
  eventId: string;
  environment: Environment;
};

export const useEvent = (
  params: UseEventParams,
  options?: UseQueryOptions<DeserializedEvent, AxiosError>,
) => {
  const { workspace } = useWorkspaceContext();
  const eventsRetrieveApi = featuresRetrieveApi(`${workspace.base_url}`);

  return useQuery<DeserializedEvent, AxiosError>({
    queryKey: [
      PREFIX_CACHE_KEY_LIST,
      params.eventType,
      params.eventId,
      params.environment,
    ],
    queryFn: async () => {
      const response = await eventsRetrieveApi.getEventByIdEventsEventTypeIdGet(
        {
          eventType: params.eventType,
          id: params.eventId,
          environment:
            params.environment as GetEventByIdEventsEventTypeIdGetEnvironmentEnum,
        },
      );
      return response.data as DeserializedEvent;
    },
    ...options,
    enabled:
      !!params.eventType &&
      !!params.eventId &&
      (options?.enabled !== undefined ? options?.enabled : true),
  });
};

export const getCachedEventSchema = (
  workspaceId: string,
  eventType: string,
) => {
  const queryKey = [PREFIX_CACHE_KEY, workspaceId, eventType];
  return queryClient.getQueryData<EventConfigGetResponse>(queryKey);
};
