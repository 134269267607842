import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { AiNodeForm } from "src/aiNode/types";
import { FieldErrorsT } from "src/api/types";
import { Card } from "src/base-components/Card";
import { AutocompleteCodeInput } from "src/base-components/CodeInput/EditorCodeInput";

type ResponseMappingPropsT = {
  runFieldErrors: FieldErrorsT | undefined;
  immutable: boolean;
};

type ResponseMappingInputPropsT = ResponseMappingPropsT & {
  placeholder?: string;
};

const InputCard: React.FC<ResponseMappingInputPropsT> = ({
  placeholder,
  runFieldErrors,
  immutable,
}) => {
  const formProps = useFormContext<AiNodeForm>();

  const fieldPath = `response_config.output_field` as const;

  const error = runFieldErrors?.[formProps.getValues(`${fieldPath}.id`)];

  return (
    <Card className="mt-3 flex w-full flex-row items-center gap-x-2">
      <div className="flex text-gray-700 font-inter-normal-12px">
        Output location
      </div>
      <Controller
        control={formProps.control}
        name={`${fieldPath}.mapped_to`}
        render={({ field: controlledField }) => (
          <AutocompleteCodeInput
            dataLoc={`dbc-node-editor-${fieldPath}.mappedTo`}
            disabled={immutable}
            error={error}
            placeholder={placeholder}
            prefix="data."
            value={controlledField.value}
            onChange={controlledField.onChange}
          />
        )}
      />
    </Card>
  );
};

export const ResponseMapping: React.FC<ResponseMappingPropsT> = ({
  runFieldErrors,
  immutable,
}) => {
  return (
    <>
      <div>
        <div className="flex w-full flex-row text-gray-500 font-inter-normal-12px"></div>
        <InputCard
          immutable={immutable}
          placeholder="ai_response"
          runFieldErrors={runFieldErrors}
        />
      </div>
    </>
  );
};
