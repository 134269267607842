import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { chunk } from "lodash";

import { outcomeTypesApi } from "src/api/endpoints";
import { decisionHistoryV3ApiRoute, outcomesApi } from "src/api/exporterApi";
import { OutcomeReport } from "src/api/types";
import { OutcomeTypeCreate, OutcomeTypePatch } from "src/clients/flow-api";
import { OutcomeType } from "src/outcomes/types";
import { useWorkspaceContext } from "src/router/routerContextHooks";

const PREFIX_CACHE_KEY = "outcome-types";

export const useOutcomeTypes = ({
  flowId,
  shouldPoll,
  enabled = true,
}: {
  flowId: string;
  shouldPoll?: (data: OutcomeType[]) => boolean;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [PREFIX_CACHE_KEY, flowId],
    queryFn: async () =>
      (await outcomeTypesApi.getOutcomeTypesForFlowApiV1OutcomeTypesGet(flowId))
        .data as OutcomeType[],
    enabled: !!flowId && enabled,
    refetchInterval: shouldPoll
      ? (data) => (shouldPoll(data ?? []) ? 3000 : false)
      : false,
  });
};

export const useCreateOutcomeType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (outcomeType: OutcomeTypeCreate) =>
      await outcomeTypesApi.createOutcomeTypeApiV1OutcomeTypesPost(outcomeType),
    onSuccess: (_, outcomeType) => {
      queryClient.invalidateQueries({
        queryKey: [PREFIX_CACHE_KEY, outcomeType.flow_id],
      });
    },
  });
};

type OutcomeTypeDelete = {
  flowId: string;
  id: string;
};

export const useDeleteOutcomeType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }: OutcomeTypeDelete) =>
      await outcomeTypesApi.startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete(
        id,
      ),
    onSuccess: (_, outcomeType) => {
      queryClient.invalidateQueries({
        queryKey: [PREFIX_CACHE_KEY, outcomeType.flowId],
      });
    },
  });
};

export const useUpdateOutcomeType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      flowId: _flowId,
      ...outcomeType
    }: OutcomeTypePatch & { id: string; flowId: string }) =>
      await outcomeTypesApi.patchOutcomeTypeApiV1OutcomeTypesIdPatch(
        id,
        outcomeType,
      ),
    onSuccess: (_, outcomeType) => {
      queryClient.invalidateQueries({
        queryKey: [PREFIX_CACHE_KEY, outcomeType.flowId],
      });
    },
  });
};

export const useClearOutcomeReports = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: Pick<OutcomeType, "id" | "flow_id">) =>
      await outcomeTypesApi.clearOutcomeTypeReportsApiV1OutcomeTypesIdClearReportsDelete(
        id,
      ),
    onSuccess: (_, outcomeType) => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [PREFIX_CACHE_KEY, outcomeType.flow_id],
        });
      }, 5000);
    },
  });
};

const GROUP_SIZE = 50;

type UseLatestOutcomesOptions = {
  enabled?: boolean;
};

export const useLatestOutcomes = (
  decisionIds: string[],
  { enabled = true }: UseLatestOutcomesOptions = {},
) => {
  const { workspace } = useWorkspaceContext();

  const pages = chunk(decisionIds, GROUP_SIZE);

  return useQueries({
    queries: pages.map((pageDecisionIds) => ({
      queryKey: [PREFIX_CACHE_KEY, "latest", ...pageDecisionIds],
      queryFn: async () => {
        const responseData = (
          await outcomesApi.get("/latest_outcomes", {
            baseURL: workspace.base_url,
            params: {
              decision_ids: pageDecisionIds.join(","),
            },
          })
        ).data;

        return pageDecisionIds.reduce<Record<string, unknown>>(
          (acc, decisionId) => {
            acc[decisionId] = responseData[decisionId] ?? {};
            return acc;
          },
          {},
        );
      },
      enabled: enabled && decisionIds.length > 0,
    })),
  });
};

export const useOutcomeReportsForAllTypes = (
  decisionId: string,
  outcomeTypes: OutcomeType[] | undefined = [],
) => {
  const { workspace } = useWorkspaceContext();

  const results = useQueries({
    queries: outcomeTypes.map((type) => ({
      queryKey: [PREFIX_CACHE_KEY, decisionId, type.key],
      queryFn: async () => {
        const response = await decisionHistoryV3ApiRoute.get<{
          outcomes: OutcomeReport[];
          next_page_token: string;
        }>(`/decisions/${decisionId}/outcome_reports`, {
          baseURL: workspace.base_url,
          params: {
            decision_id: decisionId,
            keys: type.key,
          },
        });

        if (response.data.outcomes.length)
          return { outcomes: response.data.outcomes, typeName: type.name };

        return null;
      },
      enabled: !!outcomeTypes || !!decisionId,
      staleTime: 5000,
    })),
  });

  return {
    data: results
      .filter(
        (
          result,
        ): result is UseQueryResult<
          {
            typeName: string;
            outcomes: OutcomeReport[];
          },
          unknown
        > & { data: { typeName: string; outcomes: OutcomeReport[] } } =>
          result.isSuccess && result.data !== null,
      )
      .map(
        (
          result,
        ): {
          outcomeTypeName: string;
          outcomes: OutcomeReport[];
        } => ({
          outcomeTypeName: result.data.typeName,
          outcomes: result.data.outcomes,
        }),
      ),
    isLoading: results.some((result) => result.isLoading),
  };
};
