import React from "react";
import {
  Controller,
  FieldPath,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { Checkbox } from "src/base-components/Checkbox";
import { ErrorHint } from "src/base-components/ErrorHint";
import { EventType } from "src/clients/flow-api";
import {
  OutgoingWebhookConnectionForm,
  TriggerSelection,
} from "src/webhooks/editModal/EditOutgoingWebhookModal";
import { webhookTriggerNameMap } from "src/webhooks/queries";

type CheckBoxState = "allTrue" | "partial" | "allFalse";

const checkAllValuesAre = (shouldBe: boolean, value: RecursiveBoleanObject) => {
  if (typeof value === "boolean") return value === shouldBe;
  else {
    let checkCurrentlyTrue = true;
    for (const key in value) {
      if (!checkAllValuesAre(shouldBe, value[key])) checkCurrentlyTrue = false;
      if (!checkCurrentlyTrue) break;
    }
    return checkCurrentlyTrue;
  }
};

const getCheckboxState = (value: RecursiveBoleanObject): CheckBoxState => {
  if (checkAllValuesAre(true, value)) return "allTrue";
  else if (checkAllValuesAre(false, value)) return "allFalse";
  else return "partial";
};

const copyAndSetAllValues = <T extends RecursiveBoleanObject>(
  setTo: boolean,
  exampleValue: T,
): T => {
  if (typeof exampleValue === "boolean") return setTo as T;

  const recursiveResult: Partial<TopLevelObjectRecursiveBoolean> = {};
  for (const key in exampleValue) {
    recursiveResult[key] = copyAndSetAllValues(
      setTo,
      (exampleValue as TopLevelObjectRecursiveBoolean)[key],
    );
  }
  return recursiveResult as T;
};

type RecursiveBoleanObject = TopLevelObjectRecursiveBoolean | boolean;
type TopLevelObjectRecursiveBoolean = {
  [key: string]: RecursiveBoleanObject;
};

type SelectedRowDisplayProps = {
  dataLoc?: string;
  marginLeft?: string;
  label: string;
  labelBold?: boolean;
};
type SelectRowProps<T extends RecursiveBoleanObject> =
  SelectedRowDisplayProps & {
    value: T;
    onChange: (newValue: T) => void;
  };

const SelectRow = <T extends RecursiveBoleanObject>({
  dataLoc,
  label,
  labelBold = false,
  value,
  onChange,
  marginLeft,
}: SelectRowProps<T>) => {
  const checkBoxState = getCheckboxState(value);

  return (
    <div
      className={twJoin(
        "flex items-center justify-between border-gray-100 not-last:border-b not-last:pb-1.5 not-first:pt-1.5",
        marginLeft,
      )}
    >
      <label
        className={twJoin(
          "inline-block text-gray-800",
          labelBold ? "font-inter-semibold-13px" : "font-inter-medium-13px",
        )}
      >
        {label}
      </label>
      <Checkbox
        checked={checkBoxState === "allTrue"}
        data-loc={dataLoc}
        indeterminate={checkBoxState === "partial"}
        name="sandbox"
        onChange={() => {
          if (checkBoxState === "allFalse") {
            onChange(copyAndSetAllValues(true, value));
          } else {
            onChange(copyAndSetAllValues(false, value));
          }
        }}
      />
    </div>
  );
};

type SelectRowWrapperProps = SelectedRowDisplayProps & {
  fieldName: FieldPath<TriggerSelection>;
};

const SelectRowWrapper: React.FC<SelectRowWrapperProps> = ({
  label,
  fieldName,
  marginLeft,
}) => {
  const { control, trigger } = useFormContext<OutgoingWebhookConnectionForm>();
  const fieldValue = useWatch({
    control: control,
    name: fieldName,
  });
  return (
    <Controller
      control={control}
      name={fieldName}
      render={(props) => {
        return (
          <SelectRow
            dataLoc={fieldName}
            label={label}
            marginLeft={marginLeft}
            // For unknow reasons using props.field.value gives wrong results here... I wasn't able to figure out why.
            // useWatch works fine
            value={fieldValue}
            onChange={(newVal) => {
              props.field.onChange(newVal);
              // trigger the validation of the top-level trigger field because it is dependet on its children
              trigger("trigger");
            }}
          />
        );
      }}
    />
  );
};

export const TriggerSelector: React.FC = () => {
  const { register, formState } =
    useFormContext<OutgoingWebhookConnectionForm>();
  register("trigger", {
    validate: (value) =>
      !checkAllValuesAre(false, value) || "A trigger is required",
  });
  return (
    <>
      {Boolean(formState.errors.trigger) && (
        <ErrorHint margin="none">
          {formState.errors?.trigger?.message}
        </ErrorHint>
      )}
      <SelectRowWrapper
        fieldName="trigger.decisionOutcome"
        label={webhookTriggerNameMap[EventType.DECISION_OUTCOME]}
        labelBold
      />
      <SelectRowWrapper
        fieldName="trigger.decisionOutcome.live"
        label="Live decisions"
        marginLeft="pl-7"
      />
      <SelectRowWrapper
        fieldName="trigger.decisionOutcome.live.successful"
        label="Decision completed successfully."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.decisionOutcome.live.errored"
        label="Decision resulted in error."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.decisionOutcome.sandbox"
        label="Sandbox decisions"
        marginLeft="pl-7"
      />
      <SelectRowWrapper
        fieldName="trigger.decisionOutcome.sandbox.successful"
        label="Decision completed successfully."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.decisionOutcome.sandbox.errored"
        label="Decision resulted in error."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.jobRunExecution"
        label={webhookTriggerNameMap[EventType.JOB_RUN_EXECUTION]}
        labelBold
      />
      <SelectRowWrapper
        fieldName="trigger.jobRunExecution.live"
        label="Live Job runs"
        marginLeft="pl-7"
      />
      <SelectRowWrapper
        fieldName="trigger.jobRunExecution.live.completed"
        label="Live Job run completed successfully."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.jobRunExecution.live.failed"
        label="Live Job run ended in failed status."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.jobRunExecution.live.history_complete"
        label="Live Job run history complete."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.jobRunExecution.sandbox"
        label="Sandbox Job runs"
        marginLeft="pl-7"
      />
      <SelectRowWrapper
        fieldName="trigger.jobRunExecution.sandbox.completed"
        label="Sandbox Job run completed successfully."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.jobRunExecution.sandbox.failed"
        label="Sandbox Job run ended in failed status."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.jobRunExecution.sandbox.history_complete"
        label="Sandbox Job run history complete."
        marginLeft="pl-12"
      />
      <SelectRowWrapper
        fieldName="trigger.manualReviewCaseCreation"
        label={webhookTriggerNameMap[EventType.MANUAL_REVIEW_CASE_CREATION]}
        labelBold
      />
      <SelectRowWrapper
        fieldName="trigger.manualReviewCaseCreation.live"
        label="Live decisions"
        marginLeft="pl-7"
      />
      <SelectRowWrapper
        fieldName="trigger.manualReviewCaseCreation.sandbox"
        label="Sandbox decisions"
        marginLeft="pl-7"
      />
    </>
  );
};
