import { useSearchParams } from "react-router-dom";

export const ENVIRONMENT_PARAM_KEY = "env";
const ENVIRONMENTS = ["sandbox", "live"] as const;

export type Environment = (typeof ENVIRONMENTS)[number];

/**
 * @returns the environment from the URL params or "live" if not found
 */
export const useEnvironment = (): [Environment, (env: Environment) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const env = searchParams.get(ENVIRONMENT_PARAM_KEY) as Environment | null;

  return [
    env && ENVIRONMENTS.includes(env) ? env : "live",
    (env: Environment | null) => {
      setSearchParams((prev) => {
        if (!env || env === "live") {
          prev.delete(ENVIRONMENT_PARAM_KEY);
        } else {
          prev.set(ENVIRONMENT_PARAM_KEY, env);
        }
        return prev;
      });
    },
  ];
};
