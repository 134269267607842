import {
  SimpleRoleType,
  WorkspaceSimpleRoleType,
} from "src/clients/taktile-api";
import { CapabilitiesT } from "src/hooks/useCapabilities/types";
import { useIsTaktileOrgUser } from "src/hooks/useIsTaktileOrgUser";
import { useSimpleRole } from "src/store/SimpleRoleProvider";
import { assertUnreachable } from "src/utils/typeUtils";

const isRoleAtLeast = (
  requiredRole: SimpleRoleType,
  userRole: Nullable<SimpleRoleType>,
): boolean => {
  if (!userRole) {
    return false;
  }

  switch (requiredRole) {
    case SimpleRoleType.ADMIN:
      return [SimpleRoleType.ADMIN].includes(userRole);

    case SimpleRoleType.MEMBER:
      return [SimpleRoleType.ADMIN, SimpleRoleType.MEMBER].includes(userRole);

    default:
      assertUnreachable(requiredRole);
      return false;
  }
};

const hasAtLeastRequiredRole = (
  requiredRole: WorkspaceSimpleRoleType,
  userRole: Nullable<WorkspaceSimpleRoleType>,
): boolean => {
  if (!userRole) {
    return false;
  }
  switch (requiredRole) {
    case WorkspaceSimpleRoleType.ADMIN:
      return [WorkspaceSimpleRoleType.ADMIN].includes(userRole);

    case WorkspaceSimpleRoleType.EDITOR:
      return [
        WorkspaceSimpleRoleType.ADMIN,
        WorkspaceSimpleRoleType.EDITOR,
      ].includes(userRole);

    case WorkspaceSimpleRoleType.REVIEWER:
      return [
        WorkspaceSimpleRoleType.REVIEWER,
        WorkspaceSimpleRoleType.EDITOR,
        WorkspaceSimpleRoleType.ADMIN,
      ].includes(userRole);

    case WorkspaceSimpleRoleType.VIEWER:
      return [
        WorkspaceSimpleRoleType.ADMIN,
        WorkspaceSimpleRoleType.EDITOR,
        WorkspaceSimpleRoleType.VIEWER,
      ].includes(userRole);

    default:
      assertUnreachable(requiredRole);
      return false;
  }
};

export const isWorkspaceRoleAtLeast = (
  requiredRole: WorkspaceSimpleRoleType | WorkspaceSimpleRoleType[],
  userRole: Nullable<WorkspaceSimpleRoleType>,
): boolean => {
  const requiredRoles = Array.isArray(requiredRole)
    ? requiredRole
    : [requiredRole];

  return requiredRoles.some((role) => hasAtLeastRequiredRole(role, userRole));
};

export const useCapabilities = (): CapabilitiesT => {
  const isTaktileOrgUser = useIsTaktileOrgUser();
  const { role, workspaceRole, isLoading } = useSimpleRole();

  return {
    role,
    workspaceRole,
    isLoading,
    flowVersions: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.VIEWER,
        workspaceRole,
      ),
      canPublish: (flow) => {
        const isReviewRequired = !!flow.review_configuration?.requires_review;
        return isWorkspaceRoleAtLeast(
          isReviewRequired
            ? WorkspaceSimpleRoleType.EDITOR
            : WorkspaceSimpleRoleType.ADMIN,
          workspaceRole,
        );
      },
      canArchive: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canSetSandboxDefault: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canSetDefault: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canCreate: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canEdit: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canDeleteDraft: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
    },
    datasets: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canDelete: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
    },
    decisionHistory: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
    },
    flows: {
      canAccess: isWorkspaceRoleAtLeast(
        [WorkspaceSimpleRoleType.VIEWER, WorkspaceSimpleRoleType.REVIEWER],
        workspaceRole,
      ),
      canCreate: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canEdit: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canDeleteUnpublished: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canDeletePublished: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
    },
    apiKeys: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
    },
    connections: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
    },
    usersPermissions: {
      canAccess: isRoleAtLeast(SimpleRoleType.ADMIN, role),
    },
    workspaces: {
      canCreate: isRoleAtLeast(SimpleRoleType.ADMIN, role),
      canEdit: isRoleAtLeast(SimpleRoleType.ADMIN, role),
    },
    outgoingWebhooks: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
    },
    manualReview: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.REVIEWER,
        workspaceRole,
      ),
    },
    flowCharts: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canCreate: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canEdit: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canDelete: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
    },
    jobs: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canCreate: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canEdit: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canDelete: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canDeleteSource: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canDeleteDestination: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canToggleStatus: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canRunSandbox: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canRunLive: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canChangeSchedule: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
    },
    flowFolders: {
      canCreate: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canEdit: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canDelete: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
    },
    auditLogs: {
      canAccess: isRoleAtLeast(SimpleRoleType.ADMIN, role),
    },
    outcomes: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canCreate: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canEdit: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canDelete: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
      canClear: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.ADMIN,
        workspaceRole,
      ),
    },
    featureCatalogue: {
      canAccess: isWorkspaceRoleAtLeast(
        WorkspaceSimpleRoleType.EDITOR,
        workspaceRole,
      ),
      canCreate:
        isTaktileOrgUser &&
        isWorkspaceRoleAtLeast(WorkspaceSimpleRoleType.EDITOR, workspaceRole),
      canEdit:
        isTaktileOrgUser &&
        isWorkspaceRoleAtLeast(WorkspaceSimpleRoleType.EDITOR, workspaceRole),
      canDuplicate:
        isTaktileOrgUser &&
        isWorkspaceRoleAtLeast(WorkspaceSimpleRoleType.EDITOR, workspaceRole),
    },
    featureQueriesCatalogue: {
      canAccess: isTaktileOrgUser,
      canCreate:
        isTaktileOrgUser &&
        isWorkspaceRoleAtLeast(WorkspaceSimpleRoleType.EDITOR, workspaceRole),
      canEdit:
        isTaktileOrgUser &&
        isWorkspaceRoleAtLeast(WorkspaceSimpleRoleType.EDITOR, workspaceRole),
      canDuplicate:
        isTaktileOrgUser &&
        isWorkspaceRoleAtLeast(WorkspaceSimpleRoleType.EDITOR, workspaceRole),
      canActivate:
        isTaktileOrgUser &&
        isWorkspaceRoleAtLeast(WorkspaceSimpleRoleType.EDITOR, workspaceRole),
    },
    organization: {
      canEditLogo: isRoleAtLeast(SimpleRoleType.ADMIN, role),
    },
  };
};
